import React from 'react';
import { Avatar } from '@mui/material';
import { Info, Warning, Error, Notifications, CheckCircle } from '@mui/icons-material';

type NotificationType = 'info' | 'warning' | 'error' | 'success' | 'default';

const getIcon = (type: NotificationType) => {
  switch (type) {
    case 'info':
      return <Info />;
    case 'warning':
      return <Warning />;
    case 'error':
      return <Error />;
    case 'success':
      return <CheckCircle />;
    default:
      return <Notifications />;
  }
};

const getIconColor = (type: NotificationType) => {
  switch (type) {
    case 'info':
      return 'primary.main';
    case 'warning':
      return 'warning.main';
    case 'error':
      return 'error.main';
    case 'success':
      return 'success.main';
    default:
      return 'grey.500';
  }
};

interface NotificationIconProps {
  type: NotificationType;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({ type }) => (
  <Avatar sx={{ bgcolor: getIconColor(type), mr: 0.5 }}>
    {getIcon(type)}
  </Avatar>
);

export default NotificationIcon;