import React from 'react';
import { Typography } from '@mui/material';

interface CourseTitleProps {
  title: string;
}

const CourseTitle: React.FC<CourseTitleProps> = ({ title }) => (
  <Typography 
    variant="h6" 
    component="div"
    sx={{
      height: '2.5em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      mb: 1.5,
      lineHeight: 1.2,
      fontWeight: 'bold'
    }}
  >
    {title}
  </Typography>
);

export default CourseTitle;