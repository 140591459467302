import api from '../api/api';
import { CatalogCourse, Course, CourseDetails, MyCourse } from '../types/course';
import { PlatformInfo } from '../types/platform';

export const fetchCourseDetailsLearning = async (courseId: string): Promise<Course> => {
  try {
    const response = await api.get(`/api/v1/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar detalhes do curso para aprendizagem:', error);
    throw error;
  }
};

export const fetchCatalogCourses = async (): Promise<CatalogCourse[]> => {
  try {
    const response = await api.get('/api/v1/courses');
    return response.data.courses;
  } catch (error) {
    console.error('Erro ao buscar cursos do catálogo:', error);
    throw error;
  }
};

export const fetchRecommendedCourses = async (): Promise<CatalogCourse[]> => {
  try {
    const response = await api.get(`/api/v1/courses`);
    return response.data.courses;
  } catch (error) {
    console.error('Erro ao buscar cursos recomendados:', error);
    throw error;
  }
};

const getMyCourses = async (): Promise<MyCourse[]> => {
  try {
    const response = await api.get('/api/v1/users-courses/my-courses');

    if(!response.data.myCourses)
      return [];

    return response.data.myCourses;
  } catch (error) {
    console.error('Erro ao verificar propriedade do curso:', error);
    throw error;
  }
};

export const fetchMyCourses = async (): Promise<MyCourse[]> => {
  try {
    const [myCoursesResponse, catalogCoursesResponse] = await Promise.all([
      getMyCourses(),
      fetchCatalogCourses()
    ]);

    const myCourses = myCoursesResponse;
    const catalogCourses = catalogCoursesResponse;

    // Combinar os dados de my-courses com os detalhes dos cursos do catálogo
    const myCoursesWithDetails = myCourses.map(myCourse => {
      const courseDetails = catalogCourses.find(catalogCourse => catalogCourse.id === myCourse.courseId);
      return {
        ...myCourse,
        course: courseDetails || null
      };
    });

    return myCoursesWithDetails;
  } catch (error) {
    console.error('Erro ao buscar meus cursos:', error);
    throw error;
  }
};

export const fetchPlatformInfo = async (): Promise<PlatformInfo> => {
  try {
    const response = await api.get('/api/v1/platform/me');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar informações da plataforma:', error);
    throw error;
  }
};

export const getCertificateInfo = async (courseId: string): Promise<any> => {
  try {
    const [catalogCourses, myCourses, platformInfo] = await Promise.all([
      fetchCatalogCourses(),
      fetchMyCourses(),
      fetchPlatformInfo()
    ]);

    const catalogCourse = catalogCourses.find(course => course.id === courseId);
    const myCourse = myCourses.find(course => course.courseId === courseId);

    if (!catalogCourse || !myCourse) {
      return { error: 'Curso não encontrado.' };
    }

    if (myCourse.status !== 'Completed') {
      return { error: 'O certificado só está disponível para cursos concluídos.' };
    }

    return {
      courseName: catalogCourse.title,
      courseImage: catalogCourse.imageUrl,
      instructor: catalogCourse.instructor,
      issuer: platformInfo.name,
      issuerLogo: platformInfo.logo,
      issuerWebsite: platformInfo.website,
      validationCode: myCourse.certificateValidationCode,
      completionDate: myCourse.completionDate
    };
  } catch (error) {
    console.error('Erro ao obter informações do certificado:', error);
    return { error: 'Ocorreu um erro ao buscar as informações do certificado.' };
  }
};

export const checkCourseOwnership = async (courseId: string): Promise<boolean> => {
  try {
    const response = await api.get('/api/v1/users-courses/my-courses');

    if(!response.data.myCourses)
      return false;

    const myCourses: MyCourse[] = response.data.myCourses;
    return myCourses.some(course => course.courseId === courseId);
  } catch (error) {
    console.error('Erro ao verificar propriedade do curso:', error);
    throw error;
  }
};

export const fetchCourseDetails = async (courseId: string): Promise<CourseDetails> => {
  try {
    const response = await api.get(`/api/v1/course-purchase/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar detalhes do curso:', error);
    throw error;
  }
};