import React, { useState } from 'react';
import { Button, Typography, Box, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAccountModal from './DeleteAccountModal';

const DeleteAccount: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Paper elevation={0} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: 'error.light', color: 'error.contrastText' }}>
        <Typography variant="subtitle1">Excluir Minha Conta</Typography>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={handleOpenModal}
        >
          Excluir Conta
        </Button>
      </Paper>
      <DeleteAccountModal open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default DeleteAccount;