import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Button, Paper, useTheme, useMediaQuery } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Order } from '../../types/payment';

/**
 * Componente OrderPending
 * 
 * Este componente exibe informações sobre um pedido pendente.
 * 
 * @param {Object} props - Propriedades do componente
 * @param {OrderDetails} props.orderDetails - Detalhes do pedido pendente
 */
const OrderPending: React.FC<{ orderDetails: Order }> = ({ orderDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mt: 4 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={isMobile ? 2 : 4}>
        <HourglassEmptyIcon color="warning" sx={{ fontSize: isMobile ? 40 : 60, mb: 2 }} />
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom align="center">
          Pedido Pendente
        </Typography>
        <Typography variant="subtitle1" align="center">
          Número do Pedido: {orderDetails.id}
        </Typography>
      </Box>

      <Typography variant="body1" paragraph align={isMobile ? "left" : "center"}>
        Seu pagamento está sendo processado. Isso pode levar alguns minutos.
      </Typography>

      <Typography variant="body1" paragraph align={isMobile ? "left" : "center"}>
        Assim que o pagamento for confirmado, você receberá um e-mail com os detalhes do seu pedido.
      </Typography>

      <Box mt={isMobile ? 2 : 4} display="flex" justifyContent="center">
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          fullWidth={isMobile}
        >
          Voltar para o Inicio
        </Button>
      </Box>
    </Paper>
  );
};

export default OrderPending;