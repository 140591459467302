import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import {
  Box,
  Paper,
  IconButton,
  Slider,
  Typography,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
  SkipNext,
  Fullscreen,
  FullscreenExit,
  Speed,
  ClosedCaption,
  HighQuality
} from '@mui/icons-material';

interface VideoPlayerProps {
  videoUrl: string;
  onNextLesson?: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, onNextLesson }) => {
  const [playing, setPlaying] = useState<boolean>(true);
  const [volume, setVolume] = useState<number>(1);
  const [muted, setMuted] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [speedMenuAnchor, setSpeedMenuAnchor] = useState<null | HTMLElement>(null);
  const [speed, setSpeed] = useState<number>(1);
  const [qualityMenuAnchor, setQualityMenuAnchor] = useState<null | HTMLElement>(null);
  const [quality, setQuality] = useState<string>('auto');

  const playerRef = useRef<ReactPlayer>(null);
  const playerContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPlaying(true);
  }, [videoUrl]);

  const handlePlayPause = () => setPlaying(!playing);
  const handleVolumeChange = (event: Event, newValue: number | number[]) => setVolume(newValue as number);
  const handleToggleMute = () => setMuted(!muted);
  const handleProgress = (state: { played: number }) => {
    if (!seeking) {
      setPlayed(state.played);
    }
  };
  const handleSeekChange = (event: Event, newValue: number | number[]) => setPlayed(newValue as number);
  const handleSeekMouseDown = () => setSeeking(true);
  const handleSeekMouseUp = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, newValue: number | number[]) => {
    setSeeking(false);
    playerRef.current?.seekTo(newValue as number);
  };
  const handleNextLesson = () => {
    if (onNextLesson) onNextLesson();
  };
  const handleToggleFullscreen = () => {
    if (!fullscreen) {
      playerContainerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setFullscreen(!fullscreen);
  };
  const handleSpeedMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setSpeedMenuAnchor(event.currentTarget);
  const handleSpeedMenuClose = () => setSpeedMenuAnchor(null);
  const handleSpeedChange = (newSpeed: number) => {
    setSpeed(newSpeed);
    handleSpeedMenuClose();
  };
  const handleQualityMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setQualityMenuAnchor(event.currentTarget);
  const handleQualityMenuClose = () => setQualityMenuAnchor(null);
  const handleQualityChange = (newQuality: string) => {
    setQuality(newQuality);
    handleQualityMenuClose();
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box 
        ref={playerContainerRef} 
        sx={{ 
          position: 'relative',
          flexGrow: 1,
          minHeight: '300px',
          bgcolor: 'black'
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          width="100%"
          height="100%"
          playing={playing}
          volume={volume}
          muted={muted}
          playbackRate={speed}
          onProgress={handleProgress}
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
        <Box 
          sx={{ 
            position: 'absolute', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            px: 2, 
            py: 1, 
            bgcolor: 'rgba(0,0,0,0.7)',
            transition: 'opacity 0.3s',
            '&:hover': { opacity: 1 },
            opacity: 0.7
          }}
        >
          <Slider
            value={played}
            onChange={handleSeekChange}
            onMouseDown={handleSeekMouseDown}
            onChangeCommitted={handleSeekMouseUp}
            min={0}
            max={1}
            step={0.01}
            sx={{ mb: 1, color: 'primary.main' }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handlePlayPause} color="primary">
                {playing ? <Pause /> : <PlayArrow />}
              </IconButton>
              <IconButton onClick={handleToggleMute} color="primary">
                {muted ? <VolumeOff /> : <VolumeUp />}
              </IconButton>
              <Slider
                value={volume}
                onChange={handleVolumeChange}
                min={0}
                max={1}
                step={0.1}
                sx={{ width: 100, ml: 1, color: 'primary.main' }}
              />
              <IconButton onClick={handleNextLesson} color="primary">
                <SkipNext />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={handleSpeedMenuOpen} startIcon={<Speed />} color="primary" sx={{ color: 'white' }}>
                {speed}x
              </Button>
              <Menu
                anchorEl={speedMenuAnchor}
                open={Boolean(speedMenuAnchor)}
                onClose={handleSpeedMenuClose}
              >
                {[0.5, 1, 1.5, 2].map((rate) => (
                  <MenuItem key={rate} onClick={() => handleSpeedChange(rate)}>
                    {rate}x
                  </MenuItem>
                ))}
              </Menu>
              <IconButton color="primary">
                <ClosedCaption />
              </IconButton>
              <Button onClick={handleQualityMenuOpen} startIcon={<HighQuality />} color="primary" sx={{ color: 'white' }}>
                {quality}
              </Button>
              <Menu
                anchorEl={qualityMenuAnchor}
                open={Boolean(qualityMenuAnchor)}
                onClose={handleQualityMenuClose}
              >
                {['auto', '1080p', '720p', '480p', '360p'].map((q) => (
                  <MenuItem key={q} onClick={() => handleQualityChange(q)}>
                    {q}
                  </MenuItem>
                ))}
              </Menu>
              <IconButton onClick={handleToggleFullscreen} color="primary">
                {fullscreen ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default VideoPlayer;