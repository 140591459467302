import React, { useState, useEffect } from 'react';
import { 
  Accordion, 
  AccordionDetails,
  List,
  Divider,
  styled,
  Box
} from '@mui/material';
import ModuleTitle from './ModuleTitle';
import ModuleItem from './ModuleItem';
import { Module } from '../../../types/course';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.divider,
  opacity: 0.5,
}));


interface CourseLessonProps {
  modules: Module[];
  onLessonSelect: (lesson: any) => void;
  currentLessonId: string | null;
}

const CourseLesson: React.FC<CourseLessonProps> = ({ modules, onLessonSelect, currentLessonId }) => {
  const [expandedModules, setExpandedModules] = useState<string[]>([]);

  useEffect(() => {
    if (currentLessonId) {
      const moduleIndex = modules.findIndex(module => 
        module.items.some(item => item.id.toString() === currentLessonId)
      );
      if (moduleIndex !== -1) {
        setExpandedModules(prev => {
          const newPanel = `panel${modules[moduleIndex].id}`;
          if (!prev.includes(newPanel)) {
            return [...prev, newPanel];
          }
          return prev;
        });
      }
    }
  }, [currentLessonId, modules]);

  const handleModuleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedModules(prev => {
      if (isExpanded) {
        return [...prev, panel];
      } else {
        return prev.filter(p => p !== panel);
      }
    });
  };

  return (
    <Box sx={{ 
      overflowY: 'auto', 
      flexGrow: 1,
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
    }}>
      {modules.map((module, index) => (
        <React.Fragment key={module.id}>
          {index > 0 && <StyledDivider />}
          <StyledAccordion 
            expanded={expandedModules.includes(`panel${module.id}`)}
            onChange={handleModuleChange(`panel${module.id}`)}
          >
            <ModuleTitle title={module.title} totalTime={module.totalTime} />
            <AccordionDetails sx={{ backgroundColor: 'background.paper', p: 0 }}>
              <List disablePadding>
                {module.items.map((item, itemIndex) => (
                  <React.Fragment key={item.id}>
                    {itemIndex > 0 && (
                      <StyledDivider>
                        <Divider component="li" />
                      </StyledDivider>
                    )}
                    <ModuleItem 
                      title={item.title}
                      duration={item.duration}
                      status={item.status}
                      onClick={() => onLessonSelect(item)}
                      isSelected={item.id.toString() === currentLessonId}
                    />
                  </React.Fragment>
                ))}
              </List>
            </AccordionDetails>
          </StyledAccordion>
        </React.Fragment>
      ))}
    </Box>
  );
}

export default CourseLesson;