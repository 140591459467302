import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import Logo from '../Logo/Logo';
import AuthenticatedNav from './AuthenticatedNav/AuthenticatedNav';
import UnauthenticatedNav from './UnauthenticatedNav/UnauthenticatedNav';


const Navbar: React.FC = () => {
  const { keycloak } = useKeycloak();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: 'background.navbar',
        backdropFilter: 'blur(8px)',
        color: 'common.white',
      }}
    >
      <Toolbar>
        <Box component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}>
          <Logo />
        </Box>
        {keycloak.authenticated ? <AuthenticatedNav /> : <UnauthenticatedNav />}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;