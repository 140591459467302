import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { NotificationNav, NotificationAction } from '../../types/notification';
import { ConnectionState, NotificationEventService } from '../../services/NotificationEventService';
import NotificationService from '../../services/NotificationService';
import { RealtimeMessage } from '../../types/realtime/realtime';

interface NotificationState {
    // Estado
    notifications: NotificationNav[];
    isConnected: boolean;
    connectionState: ConnectionState;
    eventService: NotificationEventService | null;
    lastError: string | null;
    isLoading: boolean;

    // Ações de Inicialização e Conexão
    initialize: (token: string) => Promise<void>;
    disconnect: () => Promise<void>;
    updateToken: (token: string) => Promise<void>;
    reconnect: () => Promise<void>;

    // Ações de Notificação
    addNotification: (notification: RealtimeMessage) => void;
    markAsRead: (notificationId: string) => Promise<void>;
    markAllAsRead: () => Promise<void>;
    performAction: (notification: NotificationNav, action: NotificationAction) => Promise<void>;
    removeNotification: (notificationId: string) => void;
    clearAll: () => void;

    // Getters
    getUnreadCount: () => number;
    getConnectionState: () => string;
    getNotificationsByType: (type: string) => NotificationNav[];
    hasUnreadNotifications: () => boolean;
}

export const useNotificationStore = create<NotificationState>()(
    persist(
        (set, get) => ({
            // Estado inicial
            notifications: [],
            isConnected: false,
            connectionState: ConnectionState.Disconnected,
            eventService: null,
            lastError: null,
            isLoading: false,

            // Inicialização e Conexão
            initialize: async (token: string) => {
                try {
                    set({ isLoading: true, lastError: null });

                    // Busca notificações existentes
                    const notifs = await NotificationService.getNotifications();
                    const typedNotifs: NotificationNav[] = notifs
                        .map((n: any) => ({
                            id: n.id,
                            type: n.type || 'default',
                            title: n.title,
                            message: n.message,
                            actions: n.actions || [],
                            read: n.read
                        }))
                        .filter(n => !n.read);

                    // Inicializa o serviço de eventos
                    const eventService = new NotificationEventService(
                        process.env.REACT_APP_API_URL,
                        token
                    );

                    // Configura handler de mensagens
                    eventService.onMessage((notification) => {
                        get().addNotification(notification);
                    });

                    // Conecta ao serviço
                    await eventService.connect();

                    set({
                        notifications: typedNotifs,
                        eventService,
                        isConnected: true,
                        connectionState: eventService.getConnectionState(),
                        isLoading: false
                    });
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'Erro desconhecido';
                    set({ 
                        lastError: errorMessage,
                        isLoading: false,
                        connectionState: ConnectionState.Disconnected
                    });
                    throw error;
                }
            },

            disconnect: async () => {
                const { eventService, isConnected } = get();
                if (eventService && isConnected) {
                    await eventService.disconnect();
                    set({ 
                        eventService: null,
                        isConnected: false,
                        connectionState: ConnectionState.Disconnected
                    });
                }
            },

            updateToken: async (newToken: string) => {
                const { eventService, isConnected } = get();
                if (eventService && isConnected) {
                    try {
                        await eventService.updateToken(newToken);
                        set({ connectionState: eventService.getConnectionState() });
                    } catch (error) {
                        const errorMessage = error instanceof Error ? error.message : 'Erro ao atualizar token';
                        set({ lastError: errorMessage });
                        throw error;
                    }
                }
            },

            reconnect: async () => {
                const { eventService, isConnected } = get();
                if (eventService && !isConnected) {
                    try {
                        set({
                            connectionState: ConnectionState.Disconnected
                        });
                        await eventService.connect();
                        set({ 
                            isConnected: true,
                            connectionState: eventService.getConnectionState(),
                            lastError: null
                        });
                    } catch (error) {
                        const errorMessage = error instanceof Error ? error.message : 'Erro ao reconectar';
                        set({ 
                            lastError: errorMessage,
                            connectionState: ConnectionState.Disconnected
                        });
                        throw error;
                    }
                }
            },

            // Ações de Notificação
            addNotification: (notification) => {
                set(state => ({
                    notifications: [JSON.parse(notification.content) as NotificationNav, ...state.notifications]
                }));
            },

            markAsRead: async (notificationId: string) => {
                try {
                    await NotificationService.markAsRead(notificationId);
                    set(state => ({
                        notifications: state.notifications.filter(n => n.id !== notificationId)
                    }));
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'Erro ao marcar como lida';
                    set({ lastError: errorMessage });
                    throw error;
                }
            },

            markAllAsRead: async () => {
                try {
                    const { notifications } = get();
                    await Promise.all(
                        notifications.map(n => NotificationService.markAsRead(n.id))
                    );
                    set({ notifications: [] });
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'Erro ao marcar todas como lidas';
                    set({ lastError: errorMessage });
                    throw error;
                }
            },

            performAction: async (notification: NotificationNav, action: NotificationAction) => {
                try {
                    if (action.action) {
                        await NotificationService.performAction(notification.id, action.action);
                    }
                    
                    if (action.url) {
                        await get().markAsRead(notification.id);
                    } else {
                        set(state => ({
                            notifications: state.notifications.filter(n => n.id !== notification.id)
                        }));
                    }
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'Erro ao executar ação';
                    set({ lastError: errorMessage });
                    throw error;
                }
            },

            removeNotification: (notificationId: string) => {
                set(state => ({
                    notifications: state.notifications.filter(n => n.id !== notificationId)
                }));
            },

            clearAll: () => {
                set({ notifications: [] });
            },

            // Getters
            getUnreadCount: () => {
                return get().notifications.length;
            },

            getConnectionState: () => {
                const { eventService } = get();
                return eventService?.getConnectionState() || 'Disconnected';
            },

            getNotificationsByType: (type: string) => {
                return get().notifications.filter(n => n.type === type);
            },

            hasUnreadNotifications: () => {
                return get().notifications.length > 0;
            }
        }),
        {
            name: 'notification-storage',
            partialize: (state) => ({
                notifications: state.notifications
            })
        }
    )
);

// Hooks
export const useNotificationCount = () => useNotificationStore(state => state.getUnreadCount());
export const useNotificationStatus = () => useNotificationStore(state => state.connectionState);
export const useNotifications = () => useNotificationStore(state => state.notifications);