import React from 'react';
import { useAuthStore } from '../../stores/user/useAuthStore';
import { 
  Container, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Paper,
  Grid,
  Alert,
  useTheme,
  useMediaQuery,
  Snackbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('Nome é obrigatório')
    .min(2, 'Nome deve ter pelo menos 2 caracteres')
    .max(50, 'Nome deve ter no máximo 50 caracteres'),
  lastName: Yup.string()
    .required('Sobrenome é obrigatório')
    .min(2, 'Sobrenome deve ter pelo menos 2 caracteres')
    .max(50, 'Sobrenome deve ter no máximo 50 caracteres'),
  email: Yup.string()
    .required('Email é obrigatório')
    .email('Email inválido'),
  password: Yup.string()
    .required('Senha é obrigatória')
    .min(8, 'Senha deve ter pelo menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Senha deve conter pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial'
    ),
  confirmPassword: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .oneOf([Yup.ref('password')], 'As senhas não coincidem')
});

export const Register: React.FC = () => {
  const { register, isLoading, error, fieldErrors, clearError } = useAuthStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showSuccess, setShowSuccess] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      clearError();
      const success = await register(values.email, values.firstName, values.lastName, values.password);
      if (success) {
        setShowSuccess(true);
        // Aguarda 2 segundos antes de redirecionar
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    }
  });

  // Quando houver erros do backend, marca os campos como touched
  React.useEffect(() => {
    if (fieldErrors && Object.keys(fieldErrors).length > 0) {
      Object.keys(fieldErrors).forEach(field => {
        formik.setFieldTouched(field, true, false);
      });
    }
  }, [fieldErrors]);

  const getFieldError = (fieldName: string) => {
    // Para debug
    console.log(`Getting error for ${fieldName}:`, { 
      fieldErrors, 
      formikError: formik.errors[fieldName],
      touched: formik.touched[fieldName]
    });

    // Primeiro verifica se há erro do backend
    if (fieldErrors && fieldErrors[fieldName]) {
      return fieldErrors[fieldName];
    }
    // Se não, retorna erro de validação do Formik
    return formik.touched[fieldName] && formik.errors[fieldName];
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          py: 4
        }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
            Criar Conta
          </Typography>

          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              width: '100%',
              maxWidth: 600,
            }}
          >
            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}

            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="firstName"
                    label="Nome"
                    required
                    fullWidth
                    value={formik.values.firstName}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (fieldErrors?.firstName) clearError();
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(getFieldError('firstName'))}
                    helperText={getFieldError('firstName')}
                    autoComplete="off"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lastName"
                    label="Sobrenome"
                    required
                    fullWidth
                    value={formik.values.lastName}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (fieldErrors?.lastName) clearError();
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(getFieldError('lastName'))}
                    helperText={getFieldError('lastName')}
                    autoComplete="off"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="email"
                    label="Email"
                    required
                    fullWidth
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (fieldErrors?.email) clearError();
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(getFieldError('email'))}
                    helperText={getFieldError('email')}
                    autoComplete="off"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    type="password"
                    label="Senha"
                    required
                    fullWidth
                    value={formik.values.password}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (fieldErrors?.password) clearError();
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(getFieldError('password'))}
                    helperText={getFieldError('password')}
                    autoComplete="new-password"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="confirmPassword"
                    type="password"
                    label="Confirmar Senha"
                    required
                    fullWidth
                    value={formik.values.confirmPassword}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (fieldErrors?.confirmPassword) clearError();
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(getFieldError('confirmPassword'))}
                    helperText={getFieldError('confirmPassword')}
                    autoComplete="new-password"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isLoading}
                    sx={{
                      py: 1.5,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: '1.1rem',
                    }}
                  >
                    {isLoading ? 'Registrando...' : 'Criar Conta'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Container>

      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        message="Conta criada com sucesso! Redirecionando para o login..."
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
};
