import { useLocation } from 'react-router-dom';
import { keycloak } from '../config/keycloak';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  if (!keycloak.authenticated) {
    window.history.replaceState(null, '', location.pathname + location.search);

    keycloak.login({
      redirectUri: `${window.location.origin}${location.pathname}${location.search}`,
    });
    return null; // Enquanto o login acontece, não renderiza nada
  }
  return <>{children}</>;
};

export default ProtectedRoute;