import React, { useState, useEffect } from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import UserAvatar from '../Navbar/UserProfile/Components/UserAvatar';
import ProfileService from '../../services/ProfileService';
import { UserProfileItem } from '../../types/user';

interface UserInfoProps {
  user: UserProfileItem;
}

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const { keycloak } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserProfileItem | null>(null);
  const username = keycloak.tokenParsed?.name || 'Usuário';
  const email = keycloak.tokenParsed?.email || '';

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileData = await ProfileService.getUserProfile();
        setUserProfile(profileData);
      } catch (error) {
        console.error('Erro ao buscar perfil do usuário:', error);
      }
    };
    fetchUserProfile();
  }, []);

  if (!userProfile) return null;

  return (
    <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <UserAvatar user={user} username={username} size={120} />
      </Box>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {username}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {email}
      </Typography>
      <Button variant="outlined" sx={{ mt: 2 }}>
        Editar Perfil
      </Button>
    </Paper>
  );
};

export default UserInfo;