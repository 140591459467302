import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import PaymentSummary from '../../components/Payment/PaymentSummary';
import GatewayCard from '../../components/CreditCard/GatewayCard';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import withPaymentProcessing from '../../hocs/withPaymentProcessing';
import { CourseDetails } from '../../types/course';
import { Coupon, EPaymentMethod, PaymentResponse, PaymentStatus } from '../../types/payment/index';
import { useCreditCardStore } from '../../stores/creditCard/useCreditCardStore';
import { usePaymentStore } from '../../stores/payment/usePaymentStore';
import { useGatewayStore } from '../../stores/payment/useGatewayStore';
import { StripePaymentHandler } from '../../components/CreditCard/gateways/StripeGateway';
import { useNavigate } from 'react-router-dom';

interface CreditCardPaymentProps {
  courseDetails: CourseDetails;
  appliedCoupon: Coupon | null;
  handleSubmit: (paymentMethod: string, paymentDetails: any) => Promise<PaymentResponse>;
  handleGoBack: () => void;
}

const CreditCardPayment: React.FC<CreditCardPaymentProps> = ({
  courseDetails,
  appliedCoupon,
  handleSubmit,
  handleGoBack
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();

  const { selectedInstallment } = useCreditCardStore();
  const { loading: paymentLoading, error: paymentError } = usePaymentStore();
  const { gateway, loading: gatewayLoading, error: gatewayError, selectGateway } = useGatewayStore();
  
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [generateToken, setGenerateToken] = useState<(() => Promise<string | null>) | null>(null);
  const [paymentHandler, setPaymentHandler] = useState<StripePaymentHandler | null>(null);

  useEffect(() => {
    const initializeGateway = async () => {
      if (courseDetails?.price) {
        await selectGateway(EPaymentMethod.CreditCard, courseDetails.price);
      }
    };
    initializeGateway();
  }, [courseDetails?.price, selectGateway]);

  const handleCreditCardSubmit = async (total: number) => {
    if (!generateToken) {
      throw new Error('Token generator not initialized');
    }

    const token = await generateToken();
    if (!token) {
      throw new Error('Failed to generate card token');
    }

    const response = await handleSubmit(EPaymentMethod.CreditCard, {
      token,
      gateway: gateway?.gateway,
      desiredInstallments: selectedInstallment,
      total,
      couponCode: appliedCoupon?.code
    });

    if (response.status === PaymentStatus.WaitingConfirmation && paymentHandler) {
      await paymentHandler.handlePaymentAction(response);
      navigate(`/order-status/${response.orderId}`, { replace: true });
    }
  };

  const handleFormComplete = (isComplete: boolean) => {
    setIsFormComplete(isComplete);
  };

  const handleTokenGenerated = useCallback((
    tokenGenerator: () => Promise<string | null>,
    handler?: StripePaymentHandler
  ) => {
    setGenerateToken(() => tokenGenerator);
    if (handler) {
      setPaymentHandler(handler);
    }
  }, []);

  if (paymentLoading || gatewayLoading) return <LoadingSpinner />;
  if (paymentError || gatewayError) return <ErrorDisplay error={new Error(paymentError || gatewayError || '')} />;
  if (!courseDetails) return <EmptyContent message="Nenhum detalhe do curso disponível para pagamento." />;
  if (!gateway && !gatewayLoading) return <EmptyContent message="Nenhum gateway de pagamento disponível no momento." />;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Pagamento com Cartão de Crédito</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box sx={{
            bgcolor: 'background.paper',
            p: { xs: 2, sm: 3 },
            borderRadius: 2,
            boxShadow: 1,
            mb: { xs: 2, md: 0 }
          }}>
            {gateway && (
              <GatewayCard
                isMobile={isMobile}
                isTablet={isTablet}
                gateway={gateway.gateway}
                pubkey={gateway.pubkey}
                onFormComplete={handleFormComplete}
                onTokenGenerated={handleTokenGenerated}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: { md: 'sticky' }, top: 20 }}>
            <PaymentSummary
              courseDetails={courseDetails}
              appliedCoupon={appliedCoupon}
              handleSubmit={handleCreditCardSubmit}
              handleGoBack={handleGoBack}
              submitButtonText="Finalizar Pagamento"
              isSubmitDisabled={!isFormComplete || !generateToken}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withPaymentProcessing(CreditCardPayment);