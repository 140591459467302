import React from 'react';
import { Typography } from '@mui/material';

interface CourseInstructorProps {
  instructor: string;
}

const CourseInstructor: React.FC<CourseInstructorProps> = ({ instructor }) => (
  <Typography 
    variant="body2" 
    color="text.secondary"
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '70%'
    }}
  >
    {instructor}
  </Typography>
);

export default CourseInstructor;