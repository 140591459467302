import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface LearningObjectivesProps {
  objectives: string[];
}

const LearningObjectives: React.FC<LearningObjectivesProps> = ({ objectives }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>O que você vai aprender</Typography>
      <List dense={isMobile}>
        {(objectives || []).map((objective, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" fontSize={isMobile ? "small" : "medium"} />
            </ListItemIcon>
            <ListItemText primary={objective} primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default LearningObjectives;