import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypeBackground {
    navbar: string;
  }

  interface PaletteOptions {
    background?: Partial<TypeBackground>;
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#00bcd4',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
      navbar: 'rgba(5, 5, 5, 0.8)',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0b0e0',
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  // ... outras configurações do tema
};

const theme = createTheme(themeOptions);

export default theme;