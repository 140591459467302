import React from 'react';
import { Box, Typography, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

/**
 * CoursePrice Component
 * 
 * Este componente exibe o preço do curso, incluindo o preço original,
 * preço com desconto e a porcentagem de desconto aplicada.
 * 
 * Funcionalidades:
 * - Exibição do preço final do curso
 * - Exibição do preço original (riscado) quando há desconto
 * - Exibição da porcentagem de desconto
 * - Botão para adicionar/remover o curso da lista de desejos
 * 
 * @param {Object} props - As propriedades do componente
 * @param {number} props.originalPrice - O preço original do curso
 * @param {number} props.finalPrice - O preço final do curso após descontos
 * @param {number} props.discount - A porcentagem de desconto aplicada
 * @param {boolean} props.isWishlisted - Indica se o curso está na lista de desejos
 * @param {Function} props.onWishlistToggle - Função para adicionar/remover da lista de desejos
 * @returns {React.ReactElement} O componente CoursePrice renderizado
 */

interface CoursePriceProps {
  originalPrice: number;
  finalPrice: number;
  discount: number;
  isWishlisted: boolean;
  onWishlistToggle: () => void;
}

const CoursePrice: React.FC<CoursePriceProps> = ({ originalPrice, finalPrice, discount, isWishlisted, onWishlistToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatPrice = (value: number): string => {
    return value && value > 0 ? `R$ ${value?.toFixed(2)}` : 'N/A';
  };

  console.log(`original price: ${originalPrice}, final price: ${finalPrice}, discount: ${discount}`);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Box>
          <Typography variant={isMobile ? "h6" : "h5"} component="span" sx={{ mr: 1 }}>
            {formatPrice(finalPrice)}
          </Typography>
          {discount > 0 && (
            <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              {formatPrice(originalPrice)}
            </Typography>
          )}
        </Box>
        <Tooltip title={isWishlisted ? "Remover da lista de desejos" : "Adicionar à lista de desejos"} placement="top">
          <IconButton onClick={onWishlistToggle} size={isMobile ? "small" : "medium"}>
            {isWishlisted ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      {discount > 0 && (
        <Typography variant="body2" color="error" mb={2}>
          {discount?.toFixed(0)}% de desconto
        </Typography>
      )}
    </>
  );
};

export default CoursePrice;