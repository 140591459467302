import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #1e1e1e; // Cor de fundo do papel no tema
  }

  ::-webkit-scrollbar-thumb {
    background-color: #1e1e1e; // Cor primária do tema
    border-radius: 4px;
    border: 2px solid #1e1e1e; // Borda com a cor de fundo
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #1e1e1e; // Versão mais escura da cor primária para hover
  }

  ::-webkit-scrollbar-corner {
    background: #1e1e1e;
  }

  *::-webkit-scrollbar-button {
    display: none; /* Remove os botões de controle */
  }

  /* Para Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #9f9f9f #1e1e1e;
  }

  /* Estilo global para garantir que o texto seja legível em fundos escuros */
  body {
    background-color: #121212;
    color: #ffffff;
  }
`;

export default GlobalStyle;