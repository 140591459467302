import api from '../api/api';
import { Coupon } from '../types/payment';

export const verifyCoupon = async (couponCode: string): Promise<Coupon | null> => {
  try {
    const response = await api.get(`/api/v1/orders/coupons/verify-coupon?couponCode=${couponCode}`);
    
    return response.data;

  } catch (error) {
    console.error('Erro ao verificar cupom:', error);
    return null;
  }
};