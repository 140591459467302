import React from 'react';
import { Typography } from '@mui/material';

interface NotificationMessageProps {
  message: string;
}

const NotificationMessage: React.FC<NotificationMessageProps> = ({ message }) => (
  <Typography 
    variant="body2" 
    sx={{ 
        mt: 1,
        mb: 1,
        wordBreak: 'break-word',
        lineHeight: 1.5,
        whiteSpace: 'normal',
        maxHeight: '5.4em',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3
    }}
  >
    {message}
  </Typography>
);

export default NotificationMessage;