import { Paper, Typography, LinearProgress, Box } from '@mui/material';
import { UserProgressItem } from '../../types/user';

interface UserProgressProps {
  progress: UserProgressItem;
}

const UserProgress: React.FC<UserProgressProps> = ({ progress }) => {

  if (!progress) return null;

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Seu Progresso
      </Typography>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={progress.overallProgress} />
      </Box>
      <Typography variant="body2" color="text.secondary">
        {`${progress.overallProgress}% Completo (${progress.completedCourses}/${progress.totalCourses} cursos)`}
      </Typography>
    </Paper>
  );
};

export default UserProgress;