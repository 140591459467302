import React from 'react';
import { Typography } from '@mui/material';
import CenteredLayout from '../CenteredLayout/CenteredLayout';

interface EmptyContentProps {
  message?: string;
}

const EmptyContent: React.FC<EmptyContentProps> = ({ message }) => (
  <CenteredLayout>
    <Typography variant="h6" color="textSecondary">
      {message || 'Nenhum conteúdo disponível no momento.'}
    </Typography>
  </CenteredLayout>
);

export default EmptyContent;