import api from '../api/api';
import { SelectedGatewayResponse } from '../types/payment/index';
import { PaymentOption, InstallmentPlan, ProcessPaymentRequest, PaymentResponse, PaymentGateway, PaymentErrorResponse } from '../types/payment/index';

class PaymentError extends Error {
  public readonly status: number;
  public readonly type: string;
  public readonly errors?: string[];

  constructor(error: PaymentErrorResponse) {
    super(error.detail || error.title);
    this.name = 'PaymentError';
    this.status = error.status;
    this.type = error.type;
    this.errors = error.errors;
  }
}

/**
 * Serviço para gerenciar operações relacionadas a pagamentos
 */
export const paymentService = {

  /**
   * Obtém as opções de pagamento disponíveis
   * @returns {Promise<PaymentMethod[]>} Lista de opções de pagamento
   */
  getPaymentOptions: async (): Promise<PaymentOption[]> => {
    try {
      const response = await api.get<{ availablePayments: PaymentOption[] }>('/api/v1/payments/payment-options');
      return response.data.availablePayments;
    } catch (error) {
      console.error('Erro ao obter opções de pagamento:', error);
      throw error;
    }
  },

  /**
   * Processa o pagamento de um curso
   * @param {string} courseId - ID do curso
   * @param {string} paymentMethod - Método de pagamento escolhido
   * @param {PaymentDetails} paymentDetails - Detalhes adicionais do pagamento
   * @returns {Promise<PaymentResponse>} Resultado do processamento do pagamento
   */
  processPayment: async (paymentDetails: ProcessPaymentRequest): Promise<PaymentResponse> => {
    try {
      // create order
      const order = await api.post('/api/v1/orders', { courseId: paymentDetails.courseId, couponCode: paymentDetails.couponCode })
      const orderId = order.data.orderId;

      const response = await api.post('/api/v1/orders/process-payment', 
        { 
          idempotencyKey: orderId,
          orderId: orderId, 
          paymentMethod: paymentDetails.paymentMethod, 
          paymentDetails: { ...paymentDetails.paymentDetails } 
        });

      return response.data;
    } catch (error: any) {
      if (error.response?.data) {
        const errorData = error.response.data as PaymentErrorResponse;
        throw new PaymentError(errorData);
      }
      console.error('Erro ao processar pagamento:', error);
      if (error.response) {
        console.error('Resposta de erro do servidor:', error.response.data);
      }
      throw error;
    }
  },

  /**
   * Seleciona o melhor gateway de pagamento baseado no valor e método de pagamento
   * @param {string} paymentMethod - Método de pagamento (ex: credit_card)
   * @param {number} possiblePrice - Valor da transação
   * @param {string[]} ignoredGateways - Lista de gateways a serem ignorados
   * @returns {Promise<SelectedGatewayResponse>} Gateway selecionado e sua chave pública
   */
  selectGateway: async (paymentMethod: string, possiblePrice: number, ignoredGateways?: string[]): Promise<SelectedGatewayResponse> => {
    try {
      const response = await api.post<SelectedGatewayResponse>('/api/v1/payments/select-gateway', {
        paymentMethod,
        possiblePrice,
        ignoredGateways,
      });
      return response.data;
    } catch (error: any) {
      if (error.response?.data) {
        const errorData = error.response.data as PaymentErrorResponse;
        throw new PaymentError(errorData);
      }
      console.error('Erro ao selecionar gateway:', error);
      throw error;
    }
  },

  /**
   * Obtém as opções de parcelamento disponíveis
   * @param {number} courseId - ID do curso
   * @param {string} cardBin - Bin do cartão
   * @returns {Promise<InstallmentOption[] | null>} Lista de opções de parcelamento
   */
  getInstallmentOptions: async (courseId: string, cardBin: string): Promise<InstallmentPlan | null> => {
    try {
      const response = await api.get<InstallmentPlan>('/api/v1/payments/installment-options'
        , { params: { CourseId: courseId, CardBrand: cardBin } }
      );
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar opções de parcelamento:', error);
      throw error;
    }
  }

};