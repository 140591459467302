import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import MarketingCarousel from '../../components/MarketingCarousel/MarketingCarousel';
import WelcomeSection from '../../components/WelcomeSection/WelcomeSection';
import CourseCatalogSection from '../../components/CoursesCatalog/CourseCatalogSection/CourseCatalogSection';
import RecommendedCoursesSection from '../../components/RecommendedCourses/RecommendedCoursesSection/RecommendedCoursesSection';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { fetchHomePageData } from '../../services/homeService';
import { CarouselData, WelcomeData } from '../../types/platform';

interface PageData {
  items: CarouselData[];
  welcome: WelcomeData;
}

const Home: React.FC = () => {
  const [pageData, setPageData] = useState<PageData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const { keycloak } = useKeycloak();

  const isAdmin = keycloak.hasRealmRole('admin');
  const userName = keycloak.tokenParsed?.name as string | undefined;

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await fetchHomePageData();
      setPageData(data);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An unknown error occurred'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorDisplay error={error} isAdmin={isAdmin} onRetry={fetchData} />;
  }

  if (!pageData) {
    return <EmptyContent message="Conteúdo da página inicial não disponível." />;
  }

  return (
    <Container maxWidth="lg">
      <MarketingCarousel data={pageData.items} />
      <WelcomeSection userName={userName} welcomeData={pageData.welcome} />
      <CourseCatalogSection welcomeData={pageData.welcome}/>
      <RecommendedCoursesSection />
    </Container>
  );
};

export default Home;