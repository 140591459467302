import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, useTheme, useMediaQuery } from '@mui/material';
import { QrCode, Smartphone, Timer, Security } from '@mui/icons-material';
import { QRCodeSVG } from 'qrcode.react';
import PaymentSummary from '../../components/Payment/PaymentSummary';
import ActionButton from '../../components/Buttons/ActionButton';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import withPaymentProcessing from '../../hocs/withPaymentProcessing';
import { ProcessPaymentRequest, WithPaymentProcessingProps } from '../../types/payment/index';

interface PixData {
  pixCode: string;
}

/**
 * Componente PixPayment
 * 
 * Este componente renderiza a página de pagamento via PIX.
 * Ele exibe informações sobre o método de pagamento, gera um QR code para pagamento,
 * mostra um resumo do pedido e lida com o processo de confirmação do pagamento.
 * 
 * @component
 */
const PixPayment: React.FC<WithPaymentProcessingProps> = ({ courseDetails, appliedCoupon, handleSubmit, handleGoBack }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [pixData, setPixData] = useState<PixData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleGeneratePix = async (total: number) => {
    try {
      setLoading(true);
      let paymentDetails = {
        courseId: courseDetails.id,
        paymentMethod: 'pix',
        couponCode: appliedCoupon?.code,
        paymentDetails: {
          pixCode: pixData?.pixCode ?? "teste"
        }
      } as ProcessPaymentRequest;
      const result = await handleSubmit('pix', paymentDetails);
      // setPixData(new PixData(result.pixCode));
    } catch (err) {
      setError('Erro ao gerar o código PIX. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorDisplay error={new Error(error)} />;
  if (!courseDetails) return <EmptyContent message="Nenhum detalhe do curso disponível para pagamento." />;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Pagamento via PIX</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>Informações sobre o Pagamento via PIX</Typography>
            <List>
              <ListItem>
                <ListItemIcon><QrCode /></ListItemIcon>
                <ListItemText primary="Escaneie o QR Code com o aplicativo do seu banco" />
              </ListItem>
              <ListItem>
                <ListItemIcon><Smartphone /></ListItemIcon>
                <ListItemText primary="Você também pode copiar o código PIX e colar no seu aplicativo bancário" />
              </ListItem>
              <ListItem>
                <ListItemIcon><Timer /></ListItemIcon>
                <ListItemText primary="O pagamento é processado instantaneamente" />
              </ListItem>
              <ListItem>
                <ListItemIcon><Security /></ListItemIcon>
                <ListItemText primary="O PIX é um método de pagamento seguro e regulado pelo Banco Central" />
              </ListItem>
            </List>
            {pixData && (
              <Box mt={3} display="flex" flexDirection="column" alignItems="center">
                <QRCodeSVG value={pixData.pixCode} size={isMobile ? 200 : isTablet ? 220 : 256} />
                <Typography variant="body1" mt={2}>
                  Escaneie o QR Code acima com o aplicativo do seu banco para realizar o pagamento.
                </Typography>
                <Typography variant="body2" mt={1}>
                  Código PIX: {pixData.pixCode}
                </Typography>
                <ActionButton
                  text="Copiar Código PIX"
                  onClick={() => navigator.clipboard.writeText(pixData.pixCode)}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: { md: 'sticky' }, top: 20 }}>
            <PaymentSummary 
              courseDetails={courseDetails} 
              appliedCoupon={appliedCoupon}
              handleSubmit={handleGeneratePix}
              handleGoBack={handleGoBack}
              submitButtonText="Gerar Código PIX"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withPaymentProcessing(PixPayment);