import React, { useState } from 'react';
import { Card, CardContent, Box, LinearProgress, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import CourseImage from './CourseImage';
import CourseTitle from './CourseTitle';
import CourseCategory from './CourseCategory';
import CourseDuration from './CourseDuration';
import CourseInstructor from './CourseInstructor';
import CourseRating from './CourseRating';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { checkCourseOwnership } from '../../services/courseService';
import { CourseCardItem } from '../../types/course';

interface CourseCardProps {
  course: CourseCardItem;
  showProgress?: boolean;
  onIssueCertificate?: (courseId: string) => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, showProgress = false, onIssueCertificate }) => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!course) {
    return null;
  }

  const handleCardClick = async () => {
    if (course?.id) {
      if (keycloak.authenticated) {
        setIsLoading(true);
        try {
          const owned = await checkCourseOwnership(course.id);
          if (owned) {
            navigate(`/course/${course.id}`);
          } else {
            navigate(`/course-purchase/${course.id}`);
          }
        } catch (error) {
          console.error('Erro ao verificar propriedade do curso:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        navigate(`/course-purchase/${course.id}`);
      }
    } else {
      console.error('Course ID is missing');
    }
  };

  const handleCertificateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onIssueCertificate && course.id) {
      onIssueCertificate(course.id.toString());
    } else if (course.id) {
      navigate(`/issue-certificate/${course.id}`);
    }
  };

  const imageUrl = course?.imageUrl ? course.imageUrl : '';

  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 6,
        },
        position: 'relative',
      }}
      onClick={handleCardClick}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            zIndex: 1,
          }}
        >
          <LoadingSpinner />
        </Box>
      )}
      <CourseImage imageUrl={imageUrl} title={course?.title || ''} />
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', py: 2 }}>
        <CourseTitle title={course?.title || ''} />
        
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
          <CourseCategory category={course?.category || ''} color={course?.color || '#000'} />
          {course?.duration && <CourseDuration duration={course.duration} />}
        </Box>
        
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CourseInstructor instructor={course?.instructor || ''} />
          <CourseRating rating={course?.rating} />
        </Box>

        {keycloak.authenticated && course.status && (
          <Box sx={{ mt: 2 }}>
            {course.status === 'InProgress' && showProgress ? (
              <>
                <LinearProgress variant="determinate" value={course.progress || 0} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Progresso: {course.progress}%
                  </Typography>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/course/${course.id}`);
                    }}
                  >
                    Continuar
                  </Button>
                </Box>
              </>
            ) : course.status === 'Completed' && (
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth
                onClick={handleCertificateClick}
              >
                Emitir Certificado
              </Button>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CourseCard;