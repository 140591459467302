import React, { useEffect, useCallback } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import PaymentSummary from '../../components/Payment/PaymentSummary';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import GatewayApplePay from '../../components/ApplePay/GatewayApplePay';
import withPaymentProcessing from '../../hocs/withPaymentProcessing';
import { CourseDetails } from '../../types/course';
import { Coupon, EPaymentMethod, PaymentResponse } from '../../types/payment/index';
import { usePaymentStore } from '../../stores/payment/usePaymentStore';
import { useGatewayStore } from '../../stores/payment/useGatewayStore';
import { useApplePayStore } from '../../stores/payment/useApplePayStore';
import { useNavigate } from 'react-router-dom';

interface ApplePayPaymentProps {
  courseDetails: CourseDetails;
  appliedCoupon: Coupon | null;
  handleSubmit: (paymentMethod: string, paymentDetails: any) => Promise<PaymentResponse>;
  handleGoBack: () => void;
}

const ApplePayPayment: React.FC<ApplePayPaymentProps> = ({
  courseDetails,
  appliedCoupon,
  handleSubmit,
  handleGoBack
}) => {
  const navigate = useNavigate();
  const { loading: paymentLoading, error: paymentError } = usePaymentStore();
  const { gateway, loading: gatewayLoading, error: gatewayError, selectGateway } = useGatewayStore();
  const { 
    error: applePayError,
    handlePayment,
    setIsProcessing,
    setError,
    reset: resetApplePay
  } = useApplePayStore();

  const [isFormComplete, setIsFormComplete] = React.useState(false);
  const finalPrice = appliedCoupon?.discountedPrice ?? courseDetails.price;

  useEffect(() => {
    const initializeGateway = async () => {
      if (courseDetails?.price) {
        await selectGateway(
          EPaymentMethod.ApplePay,
          courseDetails.price,
          []
        );
      }
    };
    initializeGateway();
    return () => {
      resetApplePay();
    };
  }, [courseDetails?.price, courseDetails?.id, selectGateway, resetApplePay]);

  const handleApplePaySubmit = async () => {
    if (!isFormComplete) {
      return;
    }

    setIsProcessing(true);
    try {
      // Cria a ordem e obtém o clientSecret
      const response = await handleSubmit(EPaymentMethod.ApplePay, {
        gateway: gateway?.gateway,
        total: finalPrice,
        couponCode: appliedCoupon?.code
      });

      if (!response.clientSecret || !response.orderId) {
        throw new Error('Resposta inválida do servidor');
      }
      
      // Chama a função do Apple Pay da store
      if (handlePayment) {
        await handlePayment(response.clientSecret, response.orderId);
      } else {
        throw new Error('Apple Pay não está disponível');
      }
    } catch (error) {
      console.error('Error processing Apple Pay payment:', error);
      setError(error instanceof Error ? error.message : 'Erro ao processar pagamento');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePaymentSuccess = useCallback((orderId: string) => {
    navigate(`/order-status/${orderId}`, { 
      replace: true
    });
  }, [navigate]);

  const handleFormComplete = useCallback((isComplete: boolean) => {
    setIsFormComplete(isComplete);
  }, []);

  if (paymentLoading || gatewayLoading) return <LoadingSpinner />;
  if (paymentError || gatewayError || applePayError) {
    return <ErrorDisplay error={new Error(paymentError || gatewayError || applePayError || '')} />;
  }
  if (!courseDetails) return <EmptyContent message="Nenhum detalhe do curso disponível para pagamento." />;
  if (!gateway && !gatewayLoading) return <EmptyContent message="Nenhum gateway de pagamento disponível no momento." />;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Pagamento com Apple Pay</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box sx={{
            bgcolor: 'background.paper',
            p: { xs: 2, sm: 3 },
            borderRadius: 2,
            boxShadow: 1,
            mb: { xs: 2, md: 0 }
          }}>
            {gateway && (
              <GatewayApplePay
                gateway={gateway.gateway}
                pubkey={gateway.pubkey}
                amount={finalPrice}
                onFormComplete={handleFormComplete}
                onPaymentSuccess={handlePaymentSuccess}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: { md: 'sticky' }, top: 20 }}>
            <PaymentSummary
              courseDetails={courseDetails}
              appliedCoupon={appliedCoupon}
              handleSubmit={handleApplePaySubmit}
              handleGoBack={handleGoBack}
              submitButtonText="Finalizar Pagamento"
              isSubmitDisabled={!isFormComplete}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withPaymentProcessing(ApplePayPayment);
