import React from 'react';
import { Box } from '@mui/material';
import CourseLesson from './CourseLesson';
import { Module } from '../../../types/course';

interface CourseLessonsSectionProps {
  modules: Module[];
  onLessonSelect: (lesson: any) => void;
  currentLessonId: string | null;
}

const CourseLessonsSection: React.FC<CourseLessonsSectionProps> = ({ modules, onLessonSelect, currentLessonId }) => {
  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <CourseLesson 
        modules={modules}
        onLessonSelect={onLessonSelect}
        currentLessonId={currentLessonId}
      />
    </Box>
  );
};

export default CourseLessonsSection;