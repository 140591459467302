import React, { useState, useCallback, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, Chip, useMediaQuery, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useCheckoutStore } from '../../stores/checkout/checkoutStore';
import { useLocation } from 'react-router-dom';

/**
 * CouponInput Component
 * 
 * Este componente permite ao usuário inserir e aplicar um código de cupom.
 * Ele também exibe o cupom aplicado e permite sua remoção.
 * 
 * Funcionalidades:
 * - Campo de entrada para o código do cupom
 * - Botão para aplicar o cupom
 * - Exibição de erros relacionados ao cupom
 * - Exibição do cupom aplicado com opção de remoção
 * - Aplicação automática de cupom da URL ao montar o componente
 * 
 * @returns {React.ReactElement} O componente CouponInput renderizado
 */

const CouponInput: React.FC = () => {
  const [couponCode, setCouponCode] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { appliedCoupon, isLoading, error, applyCoupon, removeCoupon } = useCheckoutStore();

  const handleCouponChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  }, []);

  const handleCouponApply = useCallback(async () => {
    if (couponCode) {
      await applyCoupon(couponCode.trim().toUpperCase());
    }
  }, [couponCode, applyCoupon]);

  useEffect(() => {
    if (appliedCoupon) {
      setCouponCode('');
    }
  }, [appliedCoupon]);

  const handleRemoveCoupon = useCallback(() => {
    removeCoupon();
    setCouponCode('');
  }, [removeCoupon]);

  // Aplicar cupom da URL apenas uma vez ao montar o componente
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const couponFromUrl = searchParams.get('coupon');
    if (couponFromUrl && !appliedCoupon) {
      applyCoupon(couponFromUrl.toUpperCase());
    }
  }, [appliedCoupon, location, applyCoupon]);

  return (
    <Box>
      <Box display="flex" mb={1}>
        <TextField
          fullWidth
          size={isMobile ? "small" : "medium"}
          label="Código do cupom"
          variant="outlined"
          value={couponCode}
          onChange={handleCouponChange}
          error={!!error}
          disabled={isLoading}
        />
        <Button
          variant="outlined"
          onClick={handleCouponApply}
          sx={{ ml: 1, whiteSpace: 'nowrap', minWidth: '100px' }}
          size={isMobile ? "small" : "medium"}
          disabled={isLoading || !couponCode}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Aplicar'}
        </Button>
      </Box>
      {error && (
        <Typography variant="body2" color="error" mb={1}>
          {error}
        </Typography>
      )}
      {appliedCoupon && (
        <Paper
          variant="outlined"
          sx={{
            p: isMobile ? 1 : 2,
            mt: 2,
            border: '1px dashed',
            borderColor: 'primary.main',
            mb: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Chip
              label={appliedCoupon.code}
              onDelete={handleRemoveCoupon}
              deleteIcon={<CloseIcon />}
              color="primary"
              size={isMobile ? "small" : "medium"}
            />
            <Typography variant="body2" color="success.main">
              {appliedCoupon.type === 'percentage'
                ? `${appliedCoupon.discount}% de desconto`
                : `R$ ${appliedCoupon.discount?.toFixed(2)} de desconto`}
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default React.memo(CouponInput);