import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface CoursePurchaseBreadcrumbProps {
  courseTitle: string;
}

const CoursePurchaseBreadcrumb: React.FC<CoursePurchaseBreadcrumbProps> = ({ courseTitle }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ my: 2 }}>
      <Link component={RouterLink} to="/" color="inherit">
        Home
      </Link>
      <Link component={RouterLink} to="/courses" color="inherit">
        Cursos
      </Link>
      <Typography color="text.primary">{courseTitle}</Typography>
    </Breadcrumbs>
  );
};

export default CoursePurchaseBreadcrumb;