import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import UserAvatar from './UserAvatar';
import { UserProfileItem } from '../../../../types/user';

interface UserProfileHeaderProps {
  user: UserProfileItem;
  username: string;
}

const UserProfileHeader: React.FC<UserProfileHeaderProps> = ({ user, username }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const getAvatarSize = () => {
    if (isMobile) return 120;
    if (isTablet) return 160;
    return 200;
  };

  const getFontSize = () => {
    if (isMobile) return '1.2rem';
    if (isTablet) return '1.4rem';
    return '1.6rem';
  };

  const avatarSize = getAvatarSize();
  const fontSize = getFontSize();

  return (
    <Box sx={{ 
      p: isMobile ? 2 : 3, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      width: '100%',
    }}>
      <UserAvatar user={user} username={username} size={avatarSize} />
      <Typography 
        variant="h5" 
        sx={{ 
          mt: 2,
          fontSize: fontSize,
          fontWeight: 'bold',
          textAlign: 'center',
          wordBreak: 'break-word',
          maxWidth: '100%',
        }}
      >
        {username}
      </Typography>
    </Box>
  );
};

export default UserProfileHeader;