import { create } from 'zustand';
import { paymentService } from '../../services/paymentService';
import { InstallmentOption } from '../../types/payment';

interface PaymentState {
  loading: boolean;
  error: string | null;
  installmentOptions: InstallmentOption[];
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  fetchInstallmentOptions: (courseId: string, cardNumber: string) => Promise<void>;
}

export const usePaymentStore = create<PaymentState>((set) => ({
  loading: false,
  error: null,
  installmentOptions: [],
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  fetchInstallmentOptions: async (courseId, cardNumber) => {
    try {
      set({ loading: true, error: null });
      const options = await paymentService.getInstallmentOptions(courseId, cardNumber.substring(0, 6));
      set({ installmentOptions: options.options, loading: false });
    } catch (err) {
      set({ error: 'Erro ao buscar opções de parcelamento. Por favor, tente novamente.', loading: false });
    }
  },
}));