import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { paymentService } from '../services/paymentService';
import { fetchCourseDetails } from '../services/courseService';
import ErrorDisplay from '../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../components/EmptyContent/EmptyContent';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { CourseDetails } from '../types/course';
import { ECouponType, EPaymentMethod, PaymentResponse, PaymentStatus, ProcessPaymentRequest, WithPaymentProcessingProps } from '../types/payment/index';

interface Coupon {
  code: string;
  type: ECouponType;
  discount: number;
}

interface LocationState {
  courseDetails?: CourseDetails;
  appliedCoupon?: Coupon;
}

/**
 * Higher-Order Component (HOC) para processar pagamentos
 * 
 * Este HOC encapsula a lógica comum de processamento de pagamento,
 * incluindo o carregamento de detalhes do curso, manipulação de erros,
 * e navegação após o processamento do pagamento.
 * 
 * Funcionalidades:
 * - Carrega detalhes do curso e cupom aplicado (se houver)
 * - Gerencia estados de carregamento e erro
 * - Processa o pagamento usando o paymentService
 * - Lida com a navegação após o processamento do pagamento
 * - Exibe um LoadingSpinner durante o carregamento
 * 
 * @param {React.ComponentType<WithPaymentProcessingProps>} WrappedComponent - O componente a ser envolvido
 * @returns {React.ComponentType} Um novo componente com funcionalidades de processamento de pagamento
 */
const withPaymentProcessing = <P extends WithPaymentProcessingProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: Omit<P, keyof WithPaymentProcessingProps>) => {
    const { courseId } = useParams<{ courseId: string }>();
    const navigate = useNavigate();
    const location = useLocation();

    const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);
    const [appliedCoupon, setAppliedCoupon] = useState<Coupon | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          let details: CourseDetails;
          const state = location.state as LocationState;
          if (state?.courseDetails) {
            details = state.courseDetails;
            setAppliedCoupon(state.appliedCoupon || null);
          } else {
            const courseInfo = await fetchCourseDetails(courseId!);
            details = {
              ...courseInfo,
              price: courseInfo.price || 0,
              discount: courseInfo.discount || 0
            };
          }
          setCourseDetails(details);
        } catch (err) {
          console.error('Erro ao carregar detalhes do curso:', err);
          setError('Erro ao carregar detalhes do curso. Por favor, tente novamente.');
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [courseId, location.state]);

    /**
     * Processa o pagamento e lida com a navegação baseada no resultado
     * @param {string} paymentMethod - O método de pagamento escolhido
     * @param {Object} paymentDetails - Detalhes específicos do pagamento
     * @returns {Promise<void>}
     */
    const handleSubmit = async (paymentMethod: string, paymentDetails: ProcessPaymentRequest): Promise<PaymentResponse> => {
      try {
        setLoading(true);

        const result = await paymentService.processPayment(
          {
            courseId: courseId,
            paymentMethod: paymentMethod,
            couponCode: paymentDetails.couponCode,
            paymentDetails: paymentDetails
          }
        ) as PaymentResponse;

        if (result?.status === PaymentStatus.WaitingConfirmation)
          return result;

        navigate(`/order-status/${result.orderId}`, { replace: true });
      } catch (err) {
        if (err instanceof Error) {
          const error = err as any;
          let errorMessage = error.message;

          // Se for um PaymentError, formata a mensagem de erro
          if (error.name === 'PaymentError' && error.errors?.length) {
            errorMessage = error.errors.map(item => item.message).join("\n");
          }

          setError(errorMessage);
        } else {
          setError('Ocorreu um erro inesperado ao processar o pagamento.');
        }
      } finally {
        setLoading(false);
      }
    };

    /**
     * Navega para a página anterior
     */
    const handleGoBack = () => {
      navigate(-1);
    };

    if (loading) {
      return <LoadingSpinner />;
    }
    if (error) return <ErrorDisplay error={new Error(error)} />;
    if (!courseDetails) return <EmptyContent message="Nenhum detalhe do curso disponível para pagamento." />;

    return (
      <WrappedComponent
        courseDetails={courseDetails}
        appliedCoupon={appliedCoupon}
        handleSubmit={handleSubmit}
        handleGoBack={handleGoBack}
        {...(props as P)}
      />
    );
  };
};

export default withPaymentProcessing;