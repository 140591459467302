import React from 'react';
import { Box } from '@mui/material';
import Notifications from '../Notifications/Notifications';
import UserProfile from './UserProfile/UserProfile';

const LoggedInMenu: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Notifications />
      <UserProfile />
    </Box>
  );
};

export default LoggedInMenu;