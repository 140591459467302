import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { paymentService } from '../../services/paymentService';
import { SelectedGatewayResponse } from '../../types/payment/index';

interface GatewayState {
  gateway: SelectedGatewayResponse | null;
  loading: boolean;
  error: string | null;
  selectGateway: (paymentMethod: string, possiblePrice: number, ignoredGateways?: string[]) => Promise<void>;
  reset: () => void;
}

export const useGatewayStore = create<GatewayState>()(
  devtools(
    (set) => ({
      gateway: null,
      loading: false,
      error: null,

      selectGateway: async (paymentMethod: string, possiblePrice: number, ignoredGateways?: string[]) => {
        try {
          set({ loading: true, error: null });
          const data = await paymentService.selectGateway(paymentMethod, possiblePrice, ignoredGateways);
          set({ gateway: data });
        } catch (error) {
          set({ error: error instanceof Error ? error.message : 'Unknown error occurred' });
        } finally {
          set({ loading: false });
        }
      },

      reset: () => {
        set({
          gateway: null,
          loading: false,
          error: null,
        });
      },
    }),
    { name: 'gateway-store' }
  )
);
