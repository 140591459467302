import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';
import CourseCard from '../CourseCard/CourseCard';
import { fetchRecommendedCourses } from '../../services/courseService';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import EmptyContent from '../EmptyContent/EmptyContent';
import { CatalogCourse, CourseCardItem } from '../../types/course';


const RecommendedCourses: React.FC = () => {
  const [recommendedCourses, setRecommendedCourses] = useState<CatalogCourse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadRecommendedCourses = async () => {
      try {
        setLoading(true);
        const courses = await fetchRecommendedCourses();
        setRecommendedCourses(courses);
        setLoading(false);
      } catch (err) {
        console.error('Erro ao carregar cursos recomendados:', err);
        setError('Não foi possível carregar os cursos recomendados. Por favor, tente novamente mais tarde.');
        setLoading(false);
      }
    };

    loadRecommendedCourses();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  if (recommendedCourses.length === 0) {
    return <EmptyContent message="Nenhum curso disponível no momento." />;
  }

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Cursos Recomendados
      </Typography>
      <Grid container spacing={3}>
        {recommendedCourses.map((course) => (
          <Grid item key={course.id} xs={12} sm={6} md={4} lg={3}>
            <CourseCard course={course as CourseCardItem} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RecommendedCourses;