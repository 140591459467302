import React from 'react';
import { Box, Button } from '@mui/material';
import { NotificationAction } from '../../types/notification';

interface NotificationActionsProps {
  actions: NotificationAction[];
  onAction: (action: NotificationAction) => void;
}

const NotificationActions: React.FC<NotificationActionsProps> = ({ actions, onAction }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
    {actions.map((action, index) => (
      <Button
        key={index}
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation();
          onAction(action);
        }}
        sx={{ ml: index > 0 ? 1 : 0 }}
      >
        {action.label}
      </Button>
    ))}
  </Box>
);

export default NotificationActions;