import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Button, Box, Divider } from '@mui/material';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { getCertificateInfo } from '../../services/courseService';
import CertificateModal from '../../components/IssueCertificate/CertificateModal';

interface CertificateInfo {
  courseName: string;
  instructor: string;
  issuer: string;
  validationCode: string;
  completionDate: string;
  issuerWebsite: string;
  courseImage: string;
}

const IssueCertificate: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const [certificateInfo, setCertificateInfo] = useState<CertificateInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const loadCertificateInfo = async () => {
      try {
        setIsLoading(true);
        if (!courseId) throw new Error('Course ID is undefined');
        const info = await getCertificateInfo(courseId);
        if ('error' in info) {
          setError(info.error);
        } else {
          setCertificateInfo(info);
        }
        setIsLoading(false);
      } catch (err) {
        console.error('Erro ao carregar informações do certificado:', err);
        setError('Não foi possível carregar as informações do certificado. Por favor, tente novamente mais tarde.');
        setIsLoading(false);
      }
    };

    loadCertificateInfo();
  }, [courseId]);

  if (isLoading) {
    return <EmptyContent message="Carregando informações do certificado..." />;
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  if (!certificateInfo) {
    return <EmptyContent message="Nenhuma informação de certificado disponível." />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Emitir Certificado
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>{certificateInfo.courseName}</Typography>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ py: 1 }}>
            <Typography variant="body1">Instrutor: {certificateInfo.instructor}</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ py: 1 }}>
            <Typography variant="body1">Organização Emissora: {certificateInfo.issuer}</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ py: 1 }}>
            <Typography variant="body1">Código Validador: {certificateInfo.validationCode}</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ py: 1 }}>
            <Typography variant="body1">Data de Conclusão: {certificateInfo.completionDate}</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => setIsModalOpen(true)}
              sx={{ py: 1, px: 4 }}
            >
              Emitir Certificado
            </Button>
          </Box>
        </CardContent>
      </Card>
      <CertificateModal
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        certificateInfo={certificateInfo}
      />
    </Container>
  );
};

export default IssueCertificate;