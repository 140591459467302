import React, { useState } from 'react';
import { Skeleton } from '@mui/material';

interface ImageWithFallbackProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  fallbackSrc: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, fallbackSrc, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setImgSrc(fallbackSrc);
    setLoading(false); // Parar o skeleton mesmo se falhar o carregamento
  };

  return (
    <>
      {loading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}
      <img
        src={imgSrc}
        alt={alt}
        onLoad={onLoad}
        onError={onError}
        style={{ display: loading ? 'none' : 'block' }} // Esconder a imagem enquanto carrega
        {...props}
      />
    </>
  );
};

export default ImageWithFallback;