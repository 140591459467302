import React from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Container,
  Divider,
  Link,
  Alert,
  Tooltip,
  IconButton,
  Snackbar
} from '@mui/material';
import { Order } from '../../types/payment';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DescriptionIcon from '@mui/icons-material/Description';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface OrderBoletoPendingProps {
  orderDetails: Order;
}

const OrderBoletoPending: React.FC<OrderBoletoPendingProps> = ({ orderDetails }) => {
  const { boletoInfo } = orderDetails;
  const [copySuccess, setCopySuccess] = React.useState(false);

  if (!boletoInfo) return null;

  const handleCopyNumber = async () => {
    try {
      await navigator.clipboard.writeText(boletoInfo.number);
      setCopySuccess(true);
    } catch (err) {
      console.error('Erro ao copiar:', err);
    }
  };

  const formatExpirationDate = (date: string) => {
    return format(new Date(date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Paper 
          elevation={0} 
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.paper'
          }}
        >
          {/* Cabeçalho */}
          <Box 
            sx={{ 
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              p: 3,
              textAlign: 'center'
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 48, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Pedido Realizado com Sucesso!
            </Typography>
            <Typography variant="subtitle1">
              Seu pedido foi registrado e está aguardando o pagamento
            </Typography>
          </Box>

          {/* Conteúdo */}
          <Box sx={{ p: 4 }}>
            <Alert 
              severity="info" 
              icon={<AccessTimeIcon />}
              sx={{ mb: 4 }}
            >
              Após o pagamento, pode levar até 3 dias úteis para a confirmação.
            </Alert>

            {/* Informações do Boleto */}
            <Paper 
              variant="outlined" 
              sx={{ 
                p: 3,
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1
              }}
            >
              <Typography variant="h6" gutterBottom color="primary">
                Informações do Boleto
              </Typography>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Número do Boleto
                </Typography>
                <Box 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    p: 2,
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ 
                      fontFamily: 'monospace',
                      fontSize: '1.1rem',
                      flexGrow: 1
                    }}
                  >
                    {boletoInfo.number}
                  </Typography>
                  <Tooltip title="Copiar número">
                    <IconButton 
                      onClick={handleCopyNumber}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Data de Vencimento
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: 'text.primary',
                    fontWeight: 500
                  }}
                >
                  {formatExpirationDate(boletoInfo.expirationDate)}
                </Typography>
              </Box>

              <Divider sx={{ my: 3 }} />

              <Box 
                sx={{ 
                  display: 'flex',
                  gap: 2,
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<DescriptionIcon />}
                  href={boletoInfo.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth
                  sx={{ 
                    py: 1.5,
                    textTransform: 'none'
                  }}
                >
                  Baixar Boleto em PDF
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                  href={boletoInfo.hostedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth
                  sx={{ 
                    py: 1.5,
                    textTransform: 'none'
                  }}
                >
                  Visualizar Boleto Online
                </Button>
              </Box>
            </Paper>

            {/* Link para pedidos */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Link 
                href="/orders" 
                underline="hover"
                sx={{ 
                  color: 'primary.main',
                  fontWeight: 500,
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 0.5
                }}
              >
                Ver Meus Pedidos
                <OpenInNewIcon sx={{ fontSize: 16 }} />
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>

      {/* Snackbar de feedback */}
      <Snackbar
        open={copySuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Número do boleto copiado!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Container>
  );
};

export default OrderBoletoPending;
