import React from 'react';
import { Typography } from '@mui/material';

interface NotificationTitleProps {
  title: string;
}

const NotificationTitle: React.FC<NotificationTitleProps> = ({ title }) => (
  <Typography 
    variant="subtitle1" 
    sx={{ 
      fontWeight: 'bold',
      mb: 1,
      wordBreak: 'break-word',
      lineHeight: 1.2,
    }}
  >
    {title}
  </Typography>
);

export default NotificationTitle;