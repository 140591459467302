import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid, Box, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import PaymentOptions from '../../components/Checkout/PaymentOptions';
import OrderSummary from '../../components/Checkout/OrderSummary';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import ActionButton from '../../components/Buttons/ActionButton';
import { useCheckoutStore } from '../../stores/checkout/checkoutStore';
import LoadScreen from '../../components/LoadScreen/LoadScreen';

/**
 * Componente Checkout
 * 
 * Este componente lida com o processo de seleção do método de pagamento para a compra de um curso.
 * 
 * Funcionalidades:
 * - Carrega os detalhes do curso
 * - Permite a seleção do método de pagamento
 * - Aplica cupons de desconto
 * - Redireciona para a página de pagamento específica
 * 
 * Cupom via URL:
 * Um cupom pode ser aplicado automaticamente passando-o como parâmetro de consulta na URL.
 * Exemplo: /checkout/123?coupon=DESCONTO10
 * 
 * O cupom será verificado e aplicado se for válido.
 */
const Checkout: React.FC = () => {
    const { courseId } = useParams<{ courseId: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { keycloak } = useKeycloak();
    const { 
      course, 
      isLoading, 
      error, 
      selectedPaymentMethod, 
      appliedCoupon, 
      loadCourseDetails, 
      applyCoupon, 
      setSelectedPaymentMethod, 
      setError 
    } = useCheckoutStore();

    useEffect(() => {
        const initCheckout = async () => {
            if (!courseId) {
                setError('Course ID is undefined');
                return;
            }
            await loadCourseDetails(courseId);

            const urlParams = new URLSearchParams(location.search);
            const couponCode = urlParams.get('coupon');
            if (couponCode) {
                await applyCoupon(couponCode);
            }
        };

        if (keycloak.authenticated) {
            initCheckout();
        } else {
            navigate('/login', { state: { from: `/checkout/${courseId}${location.search}` } });
        }
    }, [courseId, keycloak.authenticated, navigate, location, loadCourseDetails, applyCoupon, setError]);

    const handlePaymentMethodSelect = (method: string) => {
        setSelectedPaymentMethod(method);
    };

    const handleProceedToPayment = () => {
        if (!selectedPaymentMethod) {
            setError('Por favor, selecione um método de pagamento.');
            return;
        }

        if (!courseId) {
            setError('ID do curso não disponível.');
            return;
        }

        navigate(`/payment/${selectedPaymentMethod}/${courseId}`, {
            state: {
                courseDetails: course,
                appliedCoupon
            }
        });
    };

    const handleGoBack = () => {
        navigate(`/course-purchase/${courseId}`	);
    };

    if (isLoading) return <LoadScreen />;
    if (error) return <ErrorDisplay error={new Error(error)} />;
    if (!course) return <EmptyContent message="Nenhum detalhe do curso disponível para checkout." />;

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>Checkout</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 1 }}>
                        <PaymentOptions
                            onSelect={handlePaymentMethodSelect}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ position: 'sticky', top: 20 }}>
                        <OrderSummary />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                            <ActionButton
                                text="Prosseguir para o Pagamento"
                                onClick={handleProceedToPayment}
                                disabled={!selectedPaymentMethod}
                                variant="contained"
                                color="primary"
                                fullWidth
                            />
                            <ActionButton
                                text="Voltar"
                                onClick={handleGoBack}
                                variant="outlined"
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Checkout;