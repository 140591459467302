import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginButton from '../LoginButton/LoginButton';
import RegisterButton from '../RegisterButton/RegisterButton';
import MobileDrawer from '../Drawer/MobileDrawer';

const UnauthenticatedNav: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
        <LoginButton />
        <RegisterButton />
      </Box>
      <IconButton
        color="inherit"
        aria-label="abrir menu"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ display: { md: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <MobileDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    </>
  );
};

export default UnauthenticatedNav;