import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useGooglePayStore } from '../../../stores/payment/useGooglePayStore';

interface StripeGooglePayProps {
  pubkey: string;
  amount: number;
  onFormComplete: (isComplete: boolean) => void;
  onPaymentSuccess: (orderId: string) => void;
}

export const StripeGooglePay: React.FC<StripeGooglePayProps> = ({
  pubkey,
  amount,
  onFormComplete,
  onPaymentSuccess
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [canMakePayment, setCanMakePayment] = useState<boolean | null>(null);

  const { 
    paymentRequest: storedPaymentRequest,
    setPaymentRequest,
    setError,
    setHandlePayment,
    isProcessing,
    setIsProcessing
  } = useGooglePayStore();

  // Cria a função handlePayment uma única vez
  const handlePayment = useCallback(async (clientSecret: string, orderId: string) => {
    if (!stripe || !storedPaymentRequest || !clientSecret || !orderId) {
      setError('Erro ao iniciar pagamento');
      return;
    }

    try {
      // Remove qualquer listener anterior
      storedPaymentRequest.off('paymentmethod');

      // Adiciona o handler do evento paymentmethod
      storedPaymentRequest.on('paymentmethod', async (e) => {
        try {
          // Confirmar o PaymentIntent sem lidar com possíveis próximas ações ainda
          const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: e.paymentMethod.id },
            { handleActions: false }
          );

          if (confirmError) {
            // Reporta ao navegador que o pagamento falhou
            e.complete('fail');
            setError(confirmError.message || 'Erro ao processar pagamento');
          } else {
            // Reporta ao navegador que a confirmação foi bem-sucedida
            e.complete('success');

            // Verifica se o PaymentIntent requer ações adicionais
            if (paymentIntent.status === "requires_action") {
              // Deixa o Stripe.js lidar com o resto do fluxo de pagamento
              const { error } = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                setError('Falha na autenticação do pagamento');
              } else {
                // O pagamento foi concluído com sucesso após a ação adicional
                onPaymentSuccess(orderId);
              }
            } else if (paymentIntent.status === "succeeded") {
              // O pagamento foi concluído com sucesso sem necessidade de ação adicional
              onPaymentSuccess(orderId);
            }
          }
        } catch (error) {
          e.complete('fail');
          setError('Erro ao processar pagamento');
          console.error('Error confirming payment:', error);
        }
      });

      // Inicia o fluxo do Google Pay
      await storedPaymentRequest.show();
    } catch (error) {
      setError('Erro ao iniciar Google Pay');
    }
  }, [stripe, storedPaymentRequest, onPaymentSuccess, setError]);

  // Expõe a função handlePayment através da store
  useEffect(() => {
    if (stripe && storedPaymentRequest) {
      setHandlePayment(handlePayment);
      return () => setHandlePayment(null);
    }
  }, [stripe, storedPaymentRequest, handlePayment, setHandlePayment]);

  // Configura o PaymentRequest inicial
  useEffect(() => {
    if (!stripe) return;

    const pr = stripe.paymentRequest({
      country: 'BR',
      currency: 'brl',
      total: {
        label: 'Total',
        amount: Math.round(amount * 100), // Stripe espera o valor em centavos
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: false,
      disableWallets: ['link', 'browserCard', 'applePay']
    });

    pr.canMakePayment().then(result => {
      setCanMakePayment(!!result);
      if (result) {
        setPaymentRequest(pr);
        onFormComplete(true);
      } else {
        onFormComplete(false);
      }
    });

    return () => {
      if (pr) {
        pr.off('paymentmethod');
        setPaymentRequest(null);
      }
    };
  }, [stripe, amount, onFormComplete, setPaymentRequest, setError]);

  return (
    <Box sx={{ mt: 2 }}>
      {canMakePayment === false && (
        <Stack spacing={2} alignItems="center">
          <Typography color="error" variant="body1">
            Google Pay não está disponível neste dispositivo
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Para usar o Google Pay, você precisa:
          </Typography>
          <ul>
            <Typography component="li" variant="body2" color="text.secondary">
              Usar o navegador Chrome
            </Typography>
            <Typography component="li" variant="body2" color="text.secondary">
              Ter uma conta Google configurada
            </Typography>
            <Typography component="li" variant="body2" color="text.secondary">
              Ter um cartão cadastrado no Google Pay
            </Typography>
          </ul>
        </Stack>
      )}
      {canMakePayment === true && (
        <Stack spacing={2} alignItems="center">
          <Box
            component="img"
            src="/assets/stacks/google-pay-mark_800.svg"
            alt="Google Pay"
            sx={{ height: 100, width: 'auto' }}
          />
          <Typography variant="body1" align="center">
            Você poderá pagar usando Google Pay após clicar em "Finalizar Pagamento"
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

interface StripeGooglePayWrapperProps extends Omit<StripeGooglePayProps, 'pubkey'> {
  pubkey: string;
}

export const StripeGooglePayWrapper: React.FC<StripeGooglePayWrapperProps> = (props) => {
  const [stripePromise] = useState(() => loadStripe(props.pubkey));

  return (
    <Elements stripe={stripePromise}>
      <StripeGooglePay {...props} />
    </Elements>
  );
};
