import React from 'react';
import { AccordionSummary, Typography, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.contrastText,
  },
}));

interface ModuleTitleProps {
  title: string;
  totalTime: string;
}

const ModuleTitle: React.FC<ModuleTitleProps> = ({ title, totalTime }) => {
  return (
    <StyledAccordionSummary 
      expandIcon={<ExpandMoreIcon />}
      sx={{ 
        py: 1.0,
        '&.Mui-expanded': {
          minHeight: 0, // Remove o minHeight padrão quando expandido
        },
        '& .MuiAccordionSummary-content': { 
          justifyContent: 'space-between',
          alignItems: 'center'
        } 
      }}
    >
      <Typography>{title}</Typography>
      <Typography variant="body2">
        ({totalTime})
      </Typography>
    </StyledAccordionSummary>
  );
}

export default ModuleTitle;