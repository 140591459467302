import React from 'react';
import { Grid } from '@mui/material';
import CourseCard from '../CourseCard/CourseCard';
import EmptyContent from '../EmptyContent/EmptyContent';
import { useNavigate } from 'react-router-dom';
import { MyCourse } from '../../types/course';

interface CompletedCoursesProps {
  courses: MyCourse[];
}

const CompletedCourses: React.FC<CompletedCoursesProps> = ({ courses }) => {
  const navigate = useNavigate();

  if (!courses || courses.length === 0) {
    return <EmptyContent message="Você ainda não concluiu nenhum curso." />;
  }

  const handleIssueCertificate = (courseId: string) => {
    navigate(`/issue-certificate/${courseId}`);
  };

  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      {courses.map((myCourse) => (
        <Grid item xs={12} sm={6} md={4} key={myCourse.courseId}>
          <CourseCard 
            course={{
              ...myCourse.course,
              id: myCourse.courseId,
              status: myCourse.status,
              progress: myCourse.progress,
            }}
            showProgress={false}
            onIssueCertificate={handleIssueCertificate}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CompletedCourses;