import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, useTheme } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderTop: `1px solid ${theme.palette.divider}`,
                py: 6,
                mt: 10,
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Sobre nós
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            A Microstack oferece uma nova abordagem para o aprendizado online. Somos uma plataforma de educação online dedicada a fornecer conhecimento em unidades de forma modular.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Contato
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Rua Exemplo, 123, Cidade, Estado, 12345-678
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Email: info@microstack.com
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Telefone: +55 (11) 1234-5678
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Siga-nos
                        </Typography>
                        <Box>
                            <IconButton aria-label="facebook" color="primary">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton aria-label="twitter" color="primary">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton aria-label="linkedin" color="primary">
                                <LinkedInIcon />
                            </IconButton>
                            <IconButton aria-label="instagram" color="primary">
                                <InstagramIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {'Copyright © '}
                        <Link color="inherit" href="https://microstack.com/">
                            Microstack
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;