import React from 'react';
import { Box, Typography, Button } from '@mui/material';

interface ErrorDisplayProps {
  error: Error;
  isAdmin?: boolean;
  onRetry?: () => void;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error, isAdmin, onRetry }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '80vh',
        p: 2
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Ops! Algo deu errado.
      </Typography>

      <Typography variant="h6" color="error" paragraph>
        {error.message}
      </Typography>

      {onRetry && (
        <Button variant="contained" onClick={onRetry} sx={{ mt: 2 }}>
          Tentar novamente
        </Button>
      )}

      <Button variant="contained" onClick={() => window.history.back()} sx={{ mt: 2, padding: '10px 100px' }}>
        Voltar
      </Button>

    </Box>
  );
};

export default ErrorDisplay;