import React, { useState, useEffect } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import InProgressCourses from '../../components/MyCourses/InProgressCourses';
import CompletedCourses from '../../components/MyCourses/CompletedCourses';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { fetchMyCourses } from '../../services/courseService';
import { MyCourse } from '../../types/course';

const MyCourses: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [courses, setCourses] = useState<MyCourse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        setIsLoading(true);
        const myCourses = await fetchMyCourses();
        setCourses(myCourses);
        setIsLoading(false);
      } catch (err) {
        console.error('Erro ao carregar cursos:', err);
        setError('Não foi possível carregar seus cursos. Por favor, tente novamente mais tarde.');
        setIsLoading(false);
      }
    };

    loadCourses();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  if (isLoading) {
    return <EmptyContent message="Carregando seus cursos..." />;
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  const inProgressCourses = courses.filter(course => course && (course.status === 'InProgress' || course.status === 'NotStarted'));
  const completedCourses = courses.filter(course => course && course.status === 'Completed');

  if (courses.length === 0) {
    return <EmptyContent message="Você ainda não tem nenhum curso." />;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Meus Cursos
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="my courses tabs">
          <Tab label="Em Progresso" />
          <Tab label="Concluídos" />
        </Tabs>
      </Box>
      {currentTab === 0 && <InProgressCourses courses={inProgressCourses} />}
      {currentTab === 1 && <CompletedCourses courses={completedCourses} />}
    </Container>
  );
};

export default MyCourses;