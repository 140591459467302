import React from 'react';
import { Box } from '@mui/material';
import ImageWithFallback from './ImageWithFallback';

// Importe ou defina a URL da sua imagem de fallback
const FALLBACK_IMAGE_URL = 'https://via.placeholder.com/300x200?text=Imagem+não+disponível';

interface CourseImageProps {
  imageUrl: string;
  title?: string;
}

const CourseImage: React.FC<CourseImageProps> = ({ imageUrl, title }) => {
  return (
    <Box 
      sx={{ 
        position: 'relative', 
        paddingTop: '56.25%', 
        backgroundColor: '#f0f0f0',
        overflow: 'hidden'
      }}
    >
      <ImageWithFallback
        src={imageUrl}
        fallbackSrc={FALLBACK_IMAGE_URL}
        alt={title || 'Course image'}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
};

export default CourseImage;