import React from 'react';
import { Box, Typography } from '@mui/material';
import { Star } from '@mui/icons-material';

interface CourseRatingProps {
  rating?: number;
}

const CourseRating: React.FC<CourseRatingProps> = ({ rating }) => (
  <Box display="flex" alignItems="center">
    <Typography variant="body2" fontWeight="bold" sx={{ mr: 0.5 }}>
      {rating !== undefined ? rating.toFixed(1) : 'N/A'}
    </Typography>
    <Star fontSize="small" sx={{ color: 'gold' }} />
  </Box>
);

export default CourseRating;