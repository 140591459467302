import { create } from 'zustand';
import { PaymentRequest } from '@stripe/stripe-js';

interface GooglePayState {
  paymentRequest: PaymentRequest | null;
  error: string | null;
  isProcessing: boolean;
  handlePayment: ((clientSecret: string, orderId: string) => Promise<void>) | null;
  setPaymentRequest: (paymentRequest: PaymentRequest | null) => void;
  setError: (error: string | null) => void;
  setIsProcessing: (isProcessing: boolean) => void;
  setHandlePayment: (handlePayment: ((clientSecret: string, orderId: string) => Promise<void>) | null) => void;
  reset: () => void;
}

export const useGooglePayStore = create<GooglePayState>((set) => ({
  paymentRequest: null,
  error: null,
  isProcessing: false,
  handlePayment: null,
  setPaymentRequest: (paymentRequest) => set({ paymentRequest }),
  setError: (error) => set({ error }),
  setIsProcessing: (isProcessing) => set({ isProcessing }),
  setHandlePayment: (handlePayment) => set({ handlePayment }),
  reset: () => set({
    paymentRequest: null,
    error: null,
    isProcessing: false,
    handlePayment: null,
  }),
}));
