import React from 'react';
import { Box } from '@mui/material';
import { StripeApplePayWrapper } from './gateways/StripeApplePay';
import { PaymentGateway } from '../../types/payment';

interface GatewayApplePayProps {
  gateway: PaymentGateway;
  pubkey: string;
  amount: number;
  onFormComplete: (isComplete: boolean) => void;
  onPaymentSuccess: (orderId: string) => void;
}

const GatewayApplePay: React.FC<GatewayApplePayProps> = ({
  gateway,
  pubkey,
  amount,
  onFormComplete,
  onPaymentSuccess
}) => {
  const renderGateway = () => {
    switch (gateway) {
      case PaymentGateway.Stripe:
        return (
          <StripeApplePayWrapper
            pubkey={pubkey}
            amount={amount}
            onFormComplete={onFormComplete}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {renderGateway()}
    </Box>
  );
};

export default GatewayApplePay;
