import React, { useState } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import AccountTab from '../../components/AccountSettings/AccountTab';
import NotificationsTab from '../../components/Notifications/NotificationsTab';

const AccountSettings: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Configurações da Conta
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="account settings tabs">
          <Tab label="Conta" />
          <Tab label="Notificações" />
        </Tabs>
      </Box>
      {currentTab === 0 && <AccountTab />}
      {currentTab === 1 && <NotificationsTab />}
    </Container>
  );
};

export default AccountSettings;