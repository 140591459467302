import React from 'react';
import { Box, Typography } from '@mui/material';
import VideoPlayer from '../VideoPlayer';

interface CourseVideoSectionProps {
  videoUrl: string | null;
  onNextLesson?: () => void;
}

const CourseVideoSection: React.FC<CourseVideoSectionProps> = ({ videoUrl, onNextLesson }) => (
  <Box sx={{ width: '100%', aspectRatio: '16/9' }}>
    {videoUrl ? (
      <VideoPlayer videoUrl={videoUrl} onNextLesson={onNextLesson} />
    ) : (
      <Box sx={{ 
        bgcolor: 'grey.300', 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <Typography>Selecione uma aula para começar</Typography>
      </Box>
    )}
  </Box>
);

export default CourseVideoSection;