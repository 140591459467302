import Keycloak from 'keycloak-js';

const keycloakConfig = {
    "realm": process.env.REACT_APP_KEYCLOAK_REALM,
    "auth-server-url": process.env.REACT_APP_KEYCLOAK_AUTH_URL,
    "ssl-required": "external",
    "resource": process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    "public-client": true,
    "confidential-port": 0
};

const keycloak = new Keycloak({
    url: keycloakConfig['auth-server-url'],
    realm: keycloakConfig.realm,
    clientId: keycloakConfig.resource
});

export const initOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
    checkLoginIframe: false
};

export { keycloak };
