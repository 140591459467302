import React from 'react';
import { CircularProgress } from '@mui/material';
import CenteredLayout from '../CenteredLayout/CenteredLayout';

const LoadingSpinner: React.FC = () => (
  <CenteredLayout>
    <CircularProgress />
  </CenteredLayout>
);

export default LoadingSpinner;