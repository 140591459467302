import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import NotificationItem from './NotificationItem';
import { NotificationNav, NotificationAction } from '../../types/notification';

interface NotificationListProps {
  notifications: NotificationNav[];
  onAction: (notification: NotificationNav, action: NotificationAction) => void;
}

const NOTIFICATIONS_PER_PAGE = 4;

const NotificationList: React.FC<NotificationListProps> = ({ notifications, onAction }) => {
  const [displayedNotifications, setDisplayedNotifications] = useState<NotificationNav[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setDisplayedNotifications(notifications.slice(0, NOTIFICATIONS_PER_PAGE));
  }, [notifications]);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setDisplayedNotifications(notifications.slice(0, nextPage * NOTIFICATIONS_PER_PAGE));
  };

  return (
    <Box>
      {displayedNotifications.length > 0 ? (
        <>
          {displayedNotifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onAction={onAction}
            />
          ))}
          {notifications.length > displayedNotifications.length && (
            <Box sx={{ p: 2 }}>
              <Button 
                onClick={handleLoadMore} 
                variant="outlined"
                fullWidth
                sx={{
                  py: 0.5,
                  textTransform: 'none',
                  borderColor: 'divider', 
                  backgroundColor: 'action.hover',
                  '&:hover': {
                    backgroundColor: 'action.selected',
                  },
                }}
              >
                Mostrar mais
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Nenhuma notificação
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationList;