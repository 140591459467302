import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { fetchCourseDetails, checkCourseOwnership } from '../../services/courseService';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import CoursePurchaseBreadcrumb from '../../components/CoursePurchase/CoursePurchaseBreadcrumb';
import CoursePurchaseHeader from '../../components/CoursePurchase/CoursePurchaseHeader';
import CoursePurchaseContent from '../../components/CoursePurchase/CoursePurchaseContent';
import CoursePurchaseSidebar from '../../components/CoursePurchase/CoursePurchaseSidebar';
import { useCheckoutStore } from '../../stores/checkout/checkoutStore';
import LoadScreen from '../../components/LoadScreen/LoadScreen';

/**
 * CoursePurchase Page Component
 * 
 * Esta página exibe os detalhes de um curso para compra, incluindo
 * informações do curso, preço, e opções de aplicação de cupom.
 * 
 * Funcionalidades:
 * - Carregamento e exibição dos detalhes do curso
 * - Verificação se o usuário já possui o curso
 * - Aplicação automática de cupom via URL
 * - Layout responsivo para dispositivos móveis e desktop
 * - Integração com o Keycloak para autenticação
 * 
 * @returns {React.ReactElement} A página CoursePurchase renderizada
 */
const CoursePurchase: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setCourse, applyCoupon } = useCheckoutStore();

  const loadCourseDetails = useCallback(async () => {
    try {
      setLoading(true);

      if (!courseId)
        throw new Error('Course ID is undefined');

      const details = await fetchCourseDetails(courseId);
      setCourse(details);

      if (keycloak.authenticated) {
        const owned = await checkCourseOwnership(courseId);
        if (owned) {
          navigate(`/course/${courseId}`);
          return;
        }
      }

      const urlParams = new URLSearchParams(location.search);
      const couponCode = urlParams.get('coupon');
      if (couponCode) {
        await applyCoupon(couponCode);
      }

      setLoading(false);
    } catch (err) {
      console.error('Erro ao carregar detalhes do curso:', err);
      setError('Não foi possível carregar os detalhes do curso. Por favor, tente novamente mais tarde.');
      setLoading(false);
    }
  }, [courseId, keycloak.authenticated, navigate, location.search, setCourse, applyCoupon]);

  useEffect(() => {
    loadCourseDetails();
  }, [loadCourseDetails]);

  const courseDetails = useCheckoutStore(state => state.course);

  if (loading) {
    return (
      <LoadScreen />
    );
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  if (!courseDetails) {
    return <EmptyContent message="Nenhum detalhe do curso disponível." />;
  }

  return (
    <Container maxWidth="lg">
      <CoursePurchaseBreadcrumb courseTitle={courseDetails.title} />
      {courseDetails.existPendingOrder && (
        <div className="warning-box" style={{
          padding: '20px',
          border: 'none',
          backgroundColor: '#FFFFCD',
          borderRadius: '12px',
          marginBottom: '20px',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          transition: 'transform 0.2s',
          cursor: 'pointer'
        }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
          <span style={{ marginRight: '15px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#FF6F00" viewBox="0 0 24 24">
              <path d="M1 21h22L12 2 1 21zm11-4h-2v-2h2v2zm0-4h-2V7h2v6z" />
            </svg>
          </span>
          <p style={{ color: '#333', margin: 0, fontSize: '16px', fontWeight: '500' }}>
            Existe um pedido pendente com pagamento sendo processado. Caso já tenha feito o pagamento, aguarde o processamento, você receberá uma notificação quando finalizar.
          </p>
        </div>
      )}
      <Grid container spacing={4}>
        {isMobile && (
          <Grid item xs={12}>
            <CoursePurchaseSidebar />
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <CoursePurchaseHeader courseDetails={courseDetails} />
          <CoursePurchaseContent courseDetails={courseDetails} />
        </Grid>
        {!isMobile && (
          <Grid item md={4}>
            <CoursePurchaseSidebar />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default React.memo(CoursePurchase);