import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Button, Paper, Divider, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SchoolIcon from '@mui/icons-material/School';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Order } from '../../types/payment';

/**
 * Componente OrderConfirmation
 * 
 * Este componente exibe a confirmação do pedido após um checkout bem-sucedido.
 * Ele mostra os detalhes do pedido, incluindo o curso comprado, o método de pagamento,
 * e instruções adicionais dependendo do método de pagamento escolhido.
 * 
 * @param {Object} props - Propriedades do componente
 * @param {OrderDetails} props.orderDetails - Detalhes do pedido
 */
const OrderConfirmation: React.FC<{ orderDetails: Order }> = ({ orderDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getPaymentIcon = (method?: string) => {
    switch (method) {
      case 'boleto':
        return <ReceiptIcon />;
      case 'pix':
        return <QrCodeIcon />;
      case 'paypal':
        return <CreditCardIcon />;
      default:
        return <PaymentIcon />;
    }
  };

  return (
    <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mt: 4 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={isMobile ? 2 : 4}>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: isMobile ? 40 : 60, mb: 2 }} />
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom align="center">
          Pedido Confirmado!
        </Typography>
        <Typography variant="subtitle1" align="center">
          Número do Pedido: {orderDetails.id}
        </Typography>
      </Box>

      <Divider sx={{ my: isMobile ? 2 : 3 }} />

      <Typography variant="h6" gutterBottom>
        Detalhes do Pedido
      </Typography>
      <Box mb={isMobile ? 2 : 3}>
        {orderDetails.course && (
          <>
            <Box display="flex" alignItems="center" mb={1}>
              <SchoolIcon sx={{ mr: 1 }} />
              <Typography>Curso: {orderDetails.course.title}</Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
          </>
        )}
        <Box display="flex" alignItems="center" mb={1}>
          <PaymentIcon sx={{ mr: 1 }} />
          <Typography>Preço: R$ {orderDetails.totalAmount?.toFixed(2) || '0.00'}</Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box display="flex" alignItems="center">
          {getPaymentIcon(orderDetails.paymentMethod)}
          <Typography sx={{ ml: 1 }}>Método de Pagamento: {orderDetails.paymentMethod || 'Não especificado'}</Typography>
        </Box>
      </Box>

      <Divider sx={{ my: isMobile ? 2 : 3 }} />

      {(orderDetails.paymentMethod === 'boleto' || orderDetails.paymentMethod === 'pix' || orderDetails.paymentMethod === 'paypal') && (
        <Paper elevation={2} sx={{ p: 2, mb: isMobile ? 2 : 3 }}>
          {orderDetails.paymentMethod === 'boleto' && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Instruções para Pagamento do Boleto:
              </Typography>
              <Typography>
                O boleto será enviado para o seu e-mail. Por favor, efetue o pagamento até a data de vencimento.
              </Typography>
            </>
          )}

          {orderDetails.paymentMethod === 'pix' && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Instruções para Pagamento via PIX:
              </Typography>
              <Typography>
                O QR Code do PIX será exibido na próxima tela. Você também receberá as informações por e-mail.
              </Typography>
            </>
          )}

          {orderDetails.paymentMethod === 'paypal' && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Pagamento via PayPal:
              </Typography>
              <Typography>
                O pagamento via PayPal foi processado com sucesso. Você receberá um e-mail de confirmação do PayPal em breve.
              </Typography>
            </>
          )}
        </Paper>
      )}

      <Divider sx={{ my: isMobile ? 2 : 3 }} />

      {orderDetails.course && (
        <Box mt={isMobile ? 2 : 4} display="flex" justifyContent="center">
          <Button
            component={Link}
            to={`/course/${orderDetails.course.id}`}
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            startIcon={<SchoolIcon />}
            sx={{ padding: '15px' }}
          >
            Ir para o Curso
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default OrderConfirmation;