import React, { useState } from 'react';
import { IconButton, Badge, Menu, Typography, Box } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NotificationList from './NotificationList';
import { NotificationNav, NotificationAction } from '../../types/notification';
import { useNotificationStore } from '../../stores/notification/useNotificationStore';

const Notifications: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  
  const {
      notifications,
      performAction,
      getUnreadCount
  } = useNotificationStore();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleAction = async (notification: NotificationNav, action: NotificationAction) => {
      try {
          await performAction(notification, action);
          if (action.url) {
              navigate(action.url);
          }
      } catch (error) {
          console.error('Erro ao executar ação da notificação:', error);
      }
      handleClose();
  };

  return (
      <>
          <IconButton
              size="large"
              aria-label="mostrar notificações"
              color="inherit"
              onClick={handleMenu}
          >
              <Badge badgeContent={getUnreadCount()} color="error">
                  <NotificationsIcon />
              </Badge>
          </IconButton>
          <Menu
              id="menu-notifications"
              anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              slotProps={{
                paper: {
                    style: {
                        maxHeight: 'calc(100vh - 100px)',
                        width: '360px',
                        borderRadius: '10px',
                    },
                },
            }}
          >
              <Box sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="h6">Notificações</Typography>
              </Box>
              <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                  <NotificationList
                      notifications={notifications}
                      onAction={handleAction}
                  />
              </Box>
          </Menu>
      </>
  );
};

export default Notifications;