import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const Login: React.FC = (state: any) => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    keycloak.login({
      redirectUri: `${window.location.origin}${from.pathname}`
    });
  }, [keycloak, from.pathname]);

  return null;
};

export default Login;