import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Radio, FormControlLabel, Box, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { paymentService } from '../../services/paymentService';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import EmptyContent from '../EmptyContent/EmptyContent';
import { PaymentOption } from '../../types/payment';

interface PaymentOptionsProps {
  onSelect: (method: string) => void;
}

/**
 * Componente que exibe as opções de pagamento disponíveis
 * 
 * @component
 * @param {Object} props
 * @param {Function} props.onSelect - Função chamada quando um método de pagamento é selecionado
 */
const PaymentOptions: React.FC<PaymentOptionsProps> = ({ onSelect }) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentOption[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      try {
        const options = await paymentService.getPaymentOptions();
        setPaymentMethods(options.filter(option => option.enabled));
        setLoading(false);
      } catch (err) {
        setError(new Error('Não foi possível carregar as opções de pagamento.'));
        setLoading(false);
      }
    };

    fetchPaymentOptions();
  }, []);

  const handleMethodChange = (method: string) => {
    setSelectedMethod(method);
    onSelect(method);
  };

  if (loading) return <CircularProgress />;
  if (error) return <ErrorDisplay error={error} />;
  if (paymentMethods.length === 0) return <EmptyContent message="Nenhuma opção de pagamento disponível." />;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Opções de Pagamento</Typography>
      {paymentMethods.map((method) => (
        <Accordion key={method.id} expanded={selectedMethod === method.type}>
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon />}
            onClick={() => handleMethodChange(method.type)}
          >
            <FormControlLabel
              value={method.type}
              control={<Radio checked={selectedMethod === method.type} />}
              label={method.name}
            />
          </AccordionSummary>
        </Accordion>
      ))}
    </Box>
  );
};

export default PaymentOptions;