import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../theme';

const technologies = [
    'CSharp', 'C++', 'Python', 'C', 'Golang', 'Nginx', 'AWS', 'Google Cloud', 'Azure', 'Kubernetes', 
    'Docker', 'Terraform', 'Ansible', 'Elasticsearch', 'Logstash', 'Kibana', 'Prometheus', 'Grafana'
  ];

interface Logo {
  name: string;
  svg: string;
}

interface Dimensions {
  width: number;
  height: number;
}

const TechBackground: React.FC = () => {
  const [logos, setLogos] = useState<Logo[]>([]);
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

  useEffect(() => {
    const loadSVGs = async () => {
      const loadedLogos = await Promise.all(
        technologies.map(async (tech) => {
          const fileName = tech.toLowerCase().replace(/\s+/g, '-');
          const svgPath = `/assets/stacks/${fileName}.svg`;
          try {
            const response = await fetch(svgPath);
            if (!response.ok) throw new Error('SVG not found');
            const svgText = await response.text();
            return { name: tech, svg: svgText };
          } catch (error) {
            console.log(`Failed to load SVG for ${tech}: ${error instanceof Error ? error.message : String(error)}`);
            return null;
          }
        })
      );
      setLogos(loadedLogos.filter((logo): logo is Logo => logo !== null));
    };

    loadSVGs();

    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const logoSize = 200;
  const spacing = 95;
  const itemSize = logoSize + spacing;

  const cols = Math.floor(dimensions.width / itemSize);
  const rows = Math.floor(dimensions.height / itemSize);
  const totalLogos = cols * rows;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'center',
        padding: `${spacing / 2}px`,
      }}
    >
      <svg width="0" height="0">
        <defs>
          <filter id="grayscale">
            <feColorMatrix type="matrix" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
      </svg>
      {Array.from({ length: totalLogos }).map((_, index) => {
        const logo = logos[index % logos.length];
        return (
          <Box
            key={index}
            sx={{
              width: `${logoSize}px`,
              height: `${logoSize}px`,
              margin: `${spacing / 2}px`,
              opacity: 0.1,
              '& svg': {
                width: '80%',
                height: '80%',
                filter: 'url(#grayscale)',
              },
            }}
            dangerouslySetInnerHTML={{ __html: logo ? logo.svg : '' }}
          />
        );
      })}
    </Box>
  );
};

export default TechBackground;