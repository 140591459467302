import api from '../api/api';
import { NotificationNav } from '../types/notification';

class NotificationService {
    
    async getNotifications(): Promise<NotificationNav[]> {
        try {
            const response = await api.get('/api/v1/notifications');
            return response.data.notifications;
        } catch (error) {
            console.error('Erro ao buscar notificações:', error);
            throw error;
        }
    }

    async markAsRead(id: string): Promise<void> {
        try {
            await api.patch<NotificationNav>(`/api/v1/notifications/${id}/mark-as-read`);
        } catch (error) {
            console.error('Erro ao marcar notificação como lida:', error);
            throw error;
        }
    }

    async performAction(id: string, actionType: string, actionData?: any): Promise<any> {
        try {
            switch (actionType) {
                case 'remindLater':
                    return await api.post(`/api/v1/notifications${id}/remind-later`, actionData);
                default:
                    return await this.markAsRead(id);
            }
        } catch (error) {
            console.error('Erro ao executar ação da notificação:', error);
            throw error;
        }
    }
}

const notificationService = new NotificationService();

export default notificationService;