import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Typography, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  SelectChangeEvent,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { formatCPF, formatCNPJ, formatPhone, validateCPF, validateCNPJ } from '../../../utils/formatters';
import { useKeycloak } from '@react-keycloak/web';
import { useAddressSearch, Address } from '../../../hooks/useAddressSearch';

interface StripeBankSlipProps {
  onFormComplete: (isComplete: boolean) => void;
  onDataChange: (data: BankSlipData) => void;
}

export type DocumentType = 'cpf' | 'cnpj';

export interface BankSlipData {
  name: string;
  documentType: DocumentType;
  document: string;
  email: string;
  phone: string;
  address: Address;
}

const formatCep = (value: string): string => {
  const numbers = value.replace(/\D/g, '');
  return numbers.replace(/(\d{5})(\d{3})/g, '$1-$2');
};

export const StripeBankSlip: React.FC<StripeBankSlipProps> = ({
  onFormComplete,
  onDataChange
}) => {
  const { keycloak } = useKeycloak();
  const { searchAddress, loading: addressLoading, error: addressError } = useAddressSearch();
  
  const [formData, setFormData] = useState<BankSlipData>({
    name: '',
    documentType: 'cpf',
    document: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      complement: '',
      city: '',
      state: '',
      postalCode: ''
    }
  });

  const [errors, setErrors] = useState<Partial<Record<keyof BankSlipData | 'address', string>>>({});

  useEffect(() => {
    if (keycloak.authenticated) {
      const userProfile = keycloak.tokenParsed;
      setFormData(prev => ({
        ...prev,
        name: userProfile?.name || '',
        email: userProfile?.email || ''
      }));

      validateForm({
        ...formData,
        name: userProfile?.name || '',
        email: userProfile?.email || ''
      });
    }
  }, [keycloak.authenticated, keycloak.tokenParsed]);

  const validateForm = (data: BankSlipData) => {
    const newErrors: Partial<Record<keyof BankSlipData | 'address', string>> = {};
    
    if (!data.name) newErrors.name = 'Nome é obrigatório';
    
    if (!data.document) {
      newErrors.document = 'Documento é obrigatório';
    } else if (data.documentType === 'cpf') {
      if (!validateCPF(data.document)) {
        newErrors.document = 'CPF inválido';
      }
    } else if (data.documentType === 'cnpj') {
      if (!validateCNPJ(data.document)) {
        newErrors.document = 'CNPJ inválido';
      }
    }

    if (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      newErrors.email = 'Email inválido';
    }
    if (!data.phone || data.phone.replace(/\D/g, '').length < 10) {
      newErrors.phone = 'Telefone inválido';
    }

    // Validação do endereço
    if (!data.address.postalCode || data.address.postalCode.replace(/\D/g, '').length !== 8) {
      newErrors.address = 'CEP inválido';
    }
    if (!data.address.line1) newErrors.address = 'Endereço é obrigatório';
    if (!data.address.city) newErrors.address = 'Cidade é obrigatória';
    if (!data.address.state) newErrors.address = 'Estado é obrigatório';

    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    onFormComplete(isValid);
    return isValid;
  };

  const handleChange = (field: keyof BankSlipData | keyof Address, isAddress = false) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    let value = event.target.value;

    if (field === 'document') {
      if (formData.documentType === 'cpf') {
        value = formatCPF(value);
      } else {
        value = formatCNPJ(value);
      }
    } else if (field === 'phone') {
      value = formatPhone(value);
    } else if (field === 'postalCode') {
      value = formatCep(value);
    }

    const newData = { 
      ...formData,
      ...(isAddress 
        ? { address: { ...formData.address, [field]: value } }
        : { [field]: value })
    };
    
    if (field === 'documentType') {
      newData.document = '';
    }
    
    setFormData(newData);
    
    if (validateForm(newData)) {
      onDataChange(newData);
    }
  };

  const handleSearchCep = async () => {
    const address = await searchAddress(formData.address.postalCode);
    if (address) {
      const newData = {
        ...formData,
        address: {
          ...address,
          line1: address.line1 // Será atualizado com o número depois
        }
      };
      setFormData(newData);
      validateForm(newData);
    }
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = event.target.value;
    const newData = {
      ...formData,
      address: {
        ...formData.address,
        line1: `${formData.address.line1}, ${number}`.replace(/, $/, '')
      }
    };
    setFormData(newData);
    validateForm(newData);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Dados para o Boleto
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome Completo"
            value={formData.name}
            onChange={handleChange('name')}
            error={!!errors.name}
            helperText={errors.name}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="document-type-label">Tipo de Documento</InputLabel>
            <Select
              labelId="document-type-label"
              value={formData.documentType}
              label="Tipo de Documento"
              onChange={handleChange('documentType')}
            >
              <MenuItem value="cpf">CPF</MenuItem>
              <MenuItem value="cnpj">CNPJ</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label={formData.documentType.toUpperCase()}
            value={formData.document}
            onChange={handleChange('document')}
            error={!!errors.document}
            helperText={errors.document}
            inputProps={{ 
              maxLength: formData.documentType === 'cpf' ? 14 : 18 
            }}
            placeholder={formData.documentType === 'cpf' ? '000.000.000-00' : '00.000.000/0000-00'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Telefone"
            value={formData.phone}
            onChange={handleChange('phone')}
            error={!!errors.phone}
            helperText={errors.phone}
            inputProps={{ maxLength: 15 }}
            placeholder="(00) 00000-0000"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleChange('email')}
            error={!!errors.email}
            helperText={errors.email}
            disabled
            placeholder="exemplo@email.com"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Endereço
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="CEP"
            value={formData.address.postalCode}
            onChange={handleChange('postalCode', true)}
            error={!!errors.address}
            helperText={addressError || errors.address}
            inputProps={{ maxLength: 9 }}
            placeholder="00000-000"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {addressLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <IconButton
                      onClick={handleSearchCep}
                      disabled={!formData.address.postalCode || formData.address.postalCode.replace(/\D/g, '').length !== 8}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Endereço"
            value={formData.address.line1.split(',')[0]}
            onChange={handleChange('line1', true)}
            error={!!errors.address}
            disabled={!formData.address.postalCode}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Número"
            onChange={handleChange('line1', true)}
            error={!!errors.address}
            disabled={!formData.address.postalCode}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Complemento"
            value={formData.address.complement}
            onChange={handleChange('complement', true)}
            placeholder="Opcional"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Cidade"
            value={formData.address.city}
            onChange={handleChange('city', true)}
            error={!!errors.address}
            disabled={!formData.address.postalCode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Estado"
            value={formData.address.state}
            onChange={handleChange('state', true)}
            error={!!errors.address}
            disabled={!formData.address.postalCode}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
