import { create } from 'zustand';
import { CardDetails, InstallmentOption } from '../../types/payment/index';

interface CardValidation {
  fullName: boolean;
  cpfCnpj: boolean;
  birthDate: boolean;
}

interface CreditCardState {
  cardDetails: CardDetails;
  validation: CardValidation;
  selectedInstallment: number;
  isFormValid: boolean;
  formErrors: Partial<Record<keyof CardDetails, string>>;
  setCardDetails: (details: Partial<CardDetails>) => void;
  validateFullName: (name: string) => boolean;
  validateCpfCnpj: (document: string) => boolean;
  validateBirthDate: (date: string) => boolean;
  setInstallmentOptions: (options: InstallmentOption[]) => void;
  setSelectedInstallment: (installment: number) => void;
  validateForm: () => void;
  isFormComplete: () => boolean;
}

const validateFullName = (name: string): boolean => {
  // Nome deve ter pelo menos duas palavras e cada palavra com no mínimo 2 caracteres
  const words = name.trim().split(/\s+/);
  return words.length >= 2 && words.every(word => word.length >= 2);
};

const validateCpfCnpj = (document: string): boolean => {
  const numbers = document.replace(/\D/g, '');
  
  if (numbers.length !== 11 && numbers.length !== 14) {
    return false;
  }

  // Validação básica de CPF
  if (numbers.length === 11) {
    if (/^(\d)\1{10}$/.test(numbers)) {
      return false;
    }
    
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(numbers.charAt(i)) * (10 - i);
    }
    let digit = 11 - (sum % 11);
    if (digit >= 10) digit = 0;
    if (digit !== parseInt(numbers.charAt(9))) {
      return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(numbers.charAt(i)) * (11 - i);
    }
    digit = 11 - (sum % 11);
    if (digit >= 10) digit = 0;
    if (digit !== parseInt(numbers.charAt(10))) {
      return false;
    }

    return true;
  }

  // Validação básica de CNPJ
  if (numbers.length === 14) {
    if (/^(\d)\1{13}$/.test(numbers)) {
      return false;
    }

    let size = numbers.length - 2;
    let numbers_array = numbers.split('');
    let verification = numbers.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers_array[size - i]) * pos--;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(verification.charAt(0))) {
      return false;
    }

    size = size + 1;
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers_array[size - i]) * pos--;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(verification.charAt(1))) {
      return false;
    }

    return true;
  }

  return false;
};

const validateBirthDate = (date: string): boolean => {
  if (!date) return false;

  const birthDate = new Date(date);
  const today = new Date();
  const minAge = 18;
  const maxAge = 100;

  // Verifica se é uma data válida
  if (isNaN(birthDate.getTime())) {
    return false;
  }

  // Calcula a idade
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  // Verifica se a idade está dentro do intervalo permitido
  return age >= minAge && age <= maxAge;
};

/**
 * CreditCardStore
 * 
 * Este store gerencia o estado do formulário de cartão de crédito,
 * incluindo detalhes do cartão, opções de parcelamento, validação do formulário
 * e verificação de preenchimento completo.
 * 
 * O store lida com valores mascarados para os campos do cartão de crédito,
 * removendo caracteres não numéricos antes da validação.
 * 
 * O store também lida com situações onde as opções de parcelamento podem não estar disponíveis,
 * definindo o padrão como "1x" quando não há opções.
 */
export const useCreditCardStore = create<CreditCardState>((set, get) => ({
  cardDetails: {
    cardNumber: '',
    expiry: '',
    cvv: '',
    fullName: '',
    cpfCnpj: '',
    birthDate: '',
    brand: undefined
  },
  validation: {
    fullName: false,
    cpfCnpj: false,
    birthDate: false
  },
  selectedInstallment: 1,
  isFormValid: false,
  formErrors: {},
  setCardDetails: (details: Partial<CardDetails>) => {
    set((state) => {
      const newDetails = { ...state.cardDetails, ...details };
      const newValidation = {
        fullName: validateFullName(newDetails.fullName),
        cpfCnpj: validateCpfCnpj(newDetails.cpfCnpj),
        birthDate: validateBirthDate(newDetails.birthDate)
      };
      return {
        cardDetails: newDetails,
        validation: newValidation
      };
    });
    get().validateForm();
  },
  validateFullName,
  validateCpfCnpj,
  validateBirthDate,
  setInstallmentOptions: (options: InstallmentOption[]) => {
    set({ 
      selectedInstallment: options.length > 0 ? options[0].installments : 1
    });
    get().validateForm();
  },
  setSelectedInstallment: (installment: number) => {
    set({ selectedInstallment: installment });
    get().validateForm();
  },
  validateForm: () => {
    const { cardDetails, selectedInstallment } = get();
    const errors: Partial<Record<keyof CardDetails, string>> = {};

    if (cardDetails.cardNumber.replace(/\D/g, '').length < 13) errors.cardNumber = 'Número de cartão inválido';

    if (!/^((0[1-9]|1[0-2])\/\d{2})$/.test(cardDetails.expiry)) errors.expiry = 'Data de expiração não é válida';

    // data expiracao tem que ser maior que a data atual
    const [month, year] = cardDetails.expiry.split("/").map(Number);
    const fullYear = year + (year < 100 ? 2000 : 0);
    if (new Date(fullYear, month, 0) < new Date()) errors.expiry = 'Data de expiração inválida';

    if (cardDetails.cvv.replace(/\D/g, '').length !== 3) errors.cvv = 'CVC inválido';

    const cpfCnpjNumbers = cardDetails.cpfCnpj.replace(/\D/g, '');
    if (cpfCnpjNumbers.length !== 11 && cpfCnpjNumbers.length !== 14) errors.cpfCnpj = 'CPF/CNPJ inválido';

    const isValid = Object.keys(errors).length === 0 && selectedInstallment >= 1;

    set({ formErrors: errors, isFormValid: isValid });
  },
  isFormComplete: () => {
    const { cardDetails, isFormValid } = get();
    const allFieldsFilled = Object.values(cardDetails).every(value => value !== '');
    const result = allFieldsFilled && isFormValid;
    return result;
  }
}));