import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Profile from '../pages/Profile/Profile';
import MyCourses from '../pages/MyCourses/MyCourses';
import CourseLearning from '../pages/CourseLearning/CourseLearning';
import AccountSettings from '../pages/AccountSettings/AccountSettings';
import IssueCertificate from '../pages/IssueCertificate/IssueCertificate';
import CoursePurchase from '../pages/CoursePurchase/CoursePurchase';
import Checkout from '../pages/Checkout/Checkout';
import CreditCardPayment from '../pages/Payment/CreditCardPayment';
import PixPayment from '../pages/Payment/PixPayment';
import NupayPayment from '../pages/Payment/NupayPayment';
import OrderStatus from '../pages/Order/OrderStatus';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import ProtectedRoute from './ProtectedRoute';
import { Register } from '../pages/Register/Register';
import { Orders } from '../pages/Orders/Orders';
import GooglePayPayment from '../pages/Payment/GooglePayPayment';
import ApplePayPayment from '../pages/Payment/ApplePayPayment';
import BankSlipPayment from '../pages/Payment/BankSlipPayment';

const AppRoutes: React.FC = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route
        path="/"
        element={
          <Home />
        } />

      <Route
        path="/login"
        element={
          <Login />
        } />

      <Route
        path="/register"
        element={
          <Register />
        } />

      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-courses"
        element={
          <ProtectedRoute>
            <MyCourses />
          </ProtectedRoute>
        }
      />
      <Route path="/course/:id" element={
        <ProtectedRoute>
          <CourseLearning />
        </ProtectedRoute>
      } />
      <Route
        path="/configuracoes"
        element={
          <ProtectedRoute>
            <AccountSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/issue-certificate/:courseId"
        element={
          <ProtectedRoute>
            <IssueCertificate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/course-purchase/:courseId"
        element={
          <CoursePurchase />
        }
      />
      <Route
        path="/checkout/:courseId"
        element={
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment/creditcard/:courseId"
        element={
          <ProtectedRoute>
            <CreditCardPayment />
          </ProtectedRoute>
        } />
      <Route
        path="/payment/pix/:courseId"
        element={
          <ProtectedRoute>
            <PixPayment />
          </ProtectedRoute>
        } />
      <Route
        path="/payment/bankSlip/:courseId"
        element={
          <ProtectedRoute>
            <BankSlipPayment />
          </ProtectedRoute>}
      />
      <Route
        path="/payment/nupay/:courseId"
        element={
          <ProtectedRoute>
            <NupayPayment />
          </ProtectedRoute>}
      />
      <Route
        path="/payment/googlePay/:courseId"
        element={
          <ProtectedRoute>
            <GooglePayPayment />
          </ProtectedRoute>}
      />
      <Route
        path="/payment/applePay/:courseId"
        element={
          <ProtectedRoute>
            <ApplePayPayment />
          </ProtectedRoute>}
      />
      <Route
        path="/payment/bankSlip/:courseId"
        element={
          <ProtectedRoute>
            <BankSlipPayment />
          </ProtectedRoute>}
      />
      <Route
        path="/order-status/:orderId"
        element={
          <ProtectedRoute>
            <OrderStatus />
          </ProtectedRoute>}
      />
      <Route
        path="/orders"
        element={
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>}
      />
    </Routes>
  </>
);

export default AppRoutes;