import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import RecommendedCourses from '../RecommendedCourses';

const RecommendedCoursesSection: React.FC = () => {
  return (
    <Box sx={{ mb: 6 }}>
      <Divider sx={{ my: 4, borderColor: 'grey.500' }} />
      <Typography variant="h5" gutterBottom>
        Cursos Recomendados
      </Typography>
      <Typography variant="body1" gutterBottom color="text.secondary">
        Selecionados especialmente para você. Dê o próximo passo na sua carreira hoje!
      </Typography>
      <Box sx={{ mt: 4 }}>
        <RecommendedCourses />
      </Box>
    </Box>
  );
};

export default RecommendedCoursesSection;