import React from 'react';
import { Box, Typography } from '@mui/material';

interface CourseDescriptionSectionProps {
  description: string;
  duration: string;
}

const CourseDescriptionSection: React.FC<CourseDescriptionSectionProps> = ({ description, duration }) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Sobre o Curso
    </Typography>
    <Typography variant="body1" paragraph>
      {description}
    </Typography>
    <Typography variant="body2">
      Duração total: {duration}
    </Typography>
  </Box>
);

export default CourseDescriptionSection;