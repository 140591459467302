import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RegisterButton: React.FC = () => {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <Button 
      onClick={handleRegister}
      variant="contained"
      color="primary"
      sx={{
        padding: '8px 40px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        transition: 'all 0.1s ease-in-out',
        marginLeft: 2,
        '&:hover': {
          backgroundColor: 'primary.dark',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        },
      }}
    >
      Registrar
    </Button>
  );
};

export default RegisterButton;