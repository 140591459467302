import React from 'react';
import { Typography, Box } from '@mui/material';
import CoursesCatalog from '../CoursesCatalog';
import { WelcomeData } from '../../../types/platform';

interface CourseCatalogSectionProps {
  welcomeData: WelcomeData;
}

const CourseCatalogSection: React.FC<CourseCatalogSectionProps> = ({ welcomeData }) => {
  return (
    <Box sx={{ mb: 6 }}>
      <Typography variant="h5" gutterBottom>
        Catálogo de Cursos
      </Typography>
      <Typography variant="body1" gutterBottom color="text.secondary">
        { welcomeData.description }
      </Typography>
      <Box sx={{ mt: 4 }}>
        <CoursesCatalog />
      </Box>
    </Box>
  );
};

export default CourseCatalogSection;