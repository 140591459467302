import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import { UserProfileItem } from '../../../../types/user';

interface UserAvatarProps {
  user: UserProfileItem;
  username: string;
  size?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user, username, size = 40 }) => {
  const theme = useTheme();

  const getInitials = (name: string): string => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Avatar
      src={user.avatarUrl}
      alt={username}
      sx={{ 
        width: size, 
        height: size,
        border: `3px solid ${theme.palette.primary.main}`,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      }}
    >
      {!user.avatarUrl && getInitials(username)}
    </Avatar>
  );
};

export default UserAvatar;