import React, { useState } from 'react';
import { Box, Typography, Divider, Paper } from '@mui/material';
import ActionButton from '../Buttons/ActionButton';
import { CourseDetails } from '../../types/course';
import { Coupon } from '../../types/payment/index';

interface PaymentSummaryProps {
  courseDetails: CourseDetails;
  appliedCoupon: Coupon | null;
  handleSubmit: (total: number) => Promise<void>;
  handleGoBack: () => void;
  submitButtonText?: string;
  isSubmitDisabled?: boolean;
}

/**
 * Componente PaymentSummary
 * 
 * Este componente exibe um resumo final do pedido na tela de pagamento,
 * incluindo detalhes do curso, preço, descontos aplicados e botões de ação.
 * 
 * @param courseDetails - Detalhes do curso sendo comprado
 * @param appliedCoupon - Cupom aplicado ao pedido, se houver
 * @param handleSubmit - Função para lidar com a submissão do pagamento
 * @param handleGoBack - Função para voltar à tela anterior
 * @param submitButtonText - Texto personalizado para o botão de submissão
 * @param isSubmitDisabled - Indica se o botão de submissão deve estar desabilitado
 */
const PaymentSummary: React.FC<PaymentSummaryProps> = ({ 
  courseDetails, 
  appliedCoupon, 
  handleSubmit, 
  handleGoBack, 
  submitButtonText = "Finalizar Pagamento",
  isSubmitDisabled = false
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const calculateTotal = (): number => {
    let total = courseDetails.price * (1 - courseDetails.discount / 100);
    if (appliedCoupon) {
      if (appliedCoupon.type === 'percentage') {
        total *= (1 - appliedCoupon.discount / 100);
      } else {
        total -= appliedCoupon.discount;
      }
    }
    return Math.max(0, total);
  };

  const total = calculateTotal();

  const onSubmit = async () => {
    setLoading(true);
    await handleSubmit(total);
    setLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 2, flexGrow: 1 }}>
        <Typography variant="h6" gutterBottom>Resumo do Pagamento</Typography>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body1">Curso:</Typography>
          <Typography variant="body1" fontWeight="bold">{courseDetails.title}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body2">Preço original:</Typography>
          <Typography variant="body2">R$ {courseDetails.price.toFixed(2)}</Typography>
        </Box>
        {courseDetails.discount > 0 && (
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body2">Desconto do curso:</Typography>
            <Typography variant="body2" color="error">-R$ {(courseDetails.price * courseDetails.discount / 100).toFixed(2)}</Typography>
          </Box>
        )}
        {appliedCoupon && (
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body2">Desconto do cupom:</Typography>
            <Typography variant="body2" color="error">
              {appliedCoupon.type === 'percentage'
                ? `-${appliedCoupon.discount}%`
                : `-R$ ${appliedCoupon.discount.toFixed(2)}`}
            </Typography>
          </Box>
        )}
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="h6">Total a pagar:</Typography>
          <Typography variant="h6" color="primary">R$ {total.toFixed(2)}</Typography>
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <ActionButton
          text={submitButtonText}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || isSubmitDisabled}
          loading={loading}
          sx={{ padding: '20px' }}
        />
        <ActionButton
          text="Voltar"
          onClick={handleGoBack}
          variant="outlined"
          fullWidth
          disabled={loading}
          sx={{ padding: '20px' }}
        />
      </Box>
    </Box>
  );
};

export default PaymentSummary;