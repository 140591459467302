import { Course, CourseDetails } from "../course";

export interface CoursePurchaseItem extends Course {
  rating: number;
  ratingCount: number;
  price: number;
  discount: number;
  lastUpdated: string;
  languages: string[];
  subtitles: string[];
  learningObjectives: string[];
  lessonCount: number;
}

export interface PaymentOption {
  id: string;
  type: string;
  name: string;
  enabled: boolean;
}

export interface Order {
  id: string;
  userId: string;
  courseId: string;
  paymentMethod: string;
  couponCode?: string;
  totalAmount: number;
  status: EOrderStatus;
  paymentStatus: PaymentStatus;
  createdAt: string;
  course?: {
    id: string;
    title: string;
    description: string;
    instructor: string;
    duration: string;
    level: string;
  };
  paymentClientSecret: string;
  boletoInfo?: BoletoSpam;
}

export enum ECouponType {
  Percentage = 'percentage',
  Fixed = 'fixed'
}

export interface Coupon {
  id: string;
  code: string;
  discount: number;
  type: ECouponType;
  discountedPrice: number;
}

export interface InstallmentOption {
  installments: number;
  installmentValue: number;
  interestRate: number;
  totalValue: number;
}

export interface InstallmentPlan {
  id: string;
  courseId: string;
  cardBin: string;
  options: InstallmentOption[];
}

export enum PaymentStatus{
  Completed = 'Completed',
  Pending = 'Pending',
  Processing = 'Processing',
  Canceled = 'Cancelled',
  Failed = 'Failed',
  RefundStarted = 'RefundStarted',
  Refunded = 'Refunded',
  WaitingConfirmation = 'WaitingConfirmation'
}

export enum EOrderStatus{
  Completed = 'Completed',
  Pending = 'Pending',
  Processing = 'Processing',
  Canceled = 'Cancelled',
  Refunded = 'Refunded'
}

export enum PaymentAction{
  RedirectToUrl = 'RedirectToUrl',
  Secure3D = 'Secure3D'
}
export interface PaymentResponse {
  orderId: string;
  status: PaymentStatus;
  action?: PaymentAction;
  redirectUrl?: string;
  message?: string;
  clientSecret?: string;
}

export interface BoletoSpam{
  hostedUrl: string;
  pdfUrl: string;
  number: string;
  expirationDate: string;
}

export interface PaymentErrorResponse {
  status: number;
  type: string;
  title: string;
  detail: string;
  errors?: string[];
}

export interface ProcessPaymentRequest {
  courseId: string;
  paymentMethod: string;
  couponCode?: string;
  paymentDetails: any;
}

export interface MyCourse {
  id: string;
  userId: string;
  courseId: string;
  title: string;
  description: string;
  instructor: string;
  duration: string;
  level: string;
  status: 'InProgress' | 'Completed';
  progress: number;
  startDate: string;
  lastAccessDate: string;
}

export interface CardDetails {
  cardNumber: string;
  fullName: string;
  expiry: string;
  cvv: string;
  cpfCnpj: string;
  birthDate: string;
  brand?: string;
}

export enum PaymentGateway {
  Stripe = 'Stripe',
  Cielo = 'Cielo',
  PagSeguro = 'PagSeguro'
}

export interface SelectedGatewayResponse {
  gateway: PaymentGateway;
  pubkey: string;
}

export interface CardToken {
  token: string;
  gateway: PaymentGateway;
  brand?: string;
  last4?: string;
}

export enum EPaymentMethod {
  CreditCard = 'CreditCard',
  DebitCard = 'DebitCard',
  Boleto = 'BankSlip',
  Pix = 'Pix',
  PayPal = 'Paypal',
  GooglePay = 'GooglePay',
  ApplePay = 'ApplePay',
  NuPay = 'Nupay',
  Crypto = 'Crypto'
}

export interface PaymentGatewayRequest {
  paymentMethod: EPaymentMethod;
  possiblePrice: number;
  ignoredGateways?: PaymentGateway[];
}

export interface CreditCardPaymentDetails {
  token: string;
  gateway: PaymentGateway;
  desiredInstallments: number;
  total: number;
}

export interface GooglePayDetails {
  last4?: string;
  brand?: string;
}

export interface PaymentMethodDetails {
  creditCard?: CardDetails;
  googlePay?: GooglePayDetails;
  applePay?: any;
  // Outros métodos de pagamento aqui
}

export interface WithPaymentProcessingProps {
  courseDetails: CourseDetails;
  appliedCoupon: Coupon | null;
  handleSubmit: (paymentMethod: string, paymentDetails: any) => Promise<PaymentResponse>;
  handleGoBack: () => void;
}