import api from '../api/api';
import { MyOrderOrdersQueryParams, MyOrderOrdersResponse } from '../types/order';
import { Order } from '../types/payment';

export const createOrder = async (orderData: Partial<Order>): Promise<Order> => {
  try {
    const response = await api.post<Order>('/orders', orderData);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar pedido:', error);
    throw error;
  }
};

export const getOrderDetails = async (orderId: string): Promise<Order> => {
  try {
    console.log('Pedido ID:', orderId);
    const response = await api.get(`/api/v1/orders/${orderId}`);
    console.log('Resultado da busca:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao obter detalhes do pedido:', error);
    throw error;
  }
};

export const orderService = {
  getAll: async (params: MyOrderOrdersQueryParams = { page: 1, pageSize: 12 }): Promise<MyOrderOrdersResponse> => {
    const response = await api.get('/api/v1/orders/all', { 
      params: {
        Page: params.page,
        PageSize: params.pageSize
      }
    });
    return response.data;
  }
};