import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Importe o seu SVG aqui
import LogoSvg from './LogoSvg';

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography-root': {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
}));

const MicroText = styled('span')(({ theme }) => ({
  color: '#ffffff', // Branco
  textTransform: 'lowercase',
}));

const StackText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[400], // Uma cor mais clara para melhor contraste
  textTransform: 'lowercase',
  fontWeight: 500,
}));

interface LogoProps {
  size?: 'small' | 'medium' | 'large';
}

const Logo: React.FC<LogoProps> = ({ size = 'medium' }) => {
  const fontSize = size === 'small' ? '1.2rem' : size === 'large' ? '2rem' : '1.5rem';
  const logoSize = size === 'small' ? 24 : size === 'large' ? 40 : 32;
  
  return (
    <LogoContainer>
      <LogoSvg width={logoSize} height={logoSize} />
      <Typography variant="h6" sx={{ fontSize, ml: 2 }}> {/* Adicionado margem à esquerda */}
        <MicroText>micro</MicroText>
        <StackText>stack</StackText>
      </Typography>
    </LogoContainer>
  );
};

export default Logo;