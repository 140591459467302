import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';
import CourseCard from '../CourseCard/CourseCard';
import { fetchCatalogCourses } from '../../services/courseService';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import EmptyContent from '../EmptyContent/EmptyContent';
import { CatalogCourse, CourseCardItem } from '../../types/course';

const CoursesCatalog: React.FC = () => {
  const [catalogCourses, setCatalogCourses] = useState<CatalogCourse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadCatalogCourses = async () => {
      try {
        setLoading(true);
        const courses = await fetchCatalogCourses();
        setCatalogCourses(courses);
        setLoading(false);
      } catch (err) {
        console.error('Erro ao carregar cursos do catálogo:', err);
        setError('Não foi possível carregar os cursos do catálogo. Por favor, tente novamente mais tarde.');
        setLoading(false);
      }
    };

    loadCatalogCourses();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  if (catalogCourses.length === 0) {
    return <EmptyContent message="Nenhum curso disponível no momento." />;
  }

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
        Catálogo de Cursos
      </Typography>
      <Grid container spacing={3}>
        {catalogCourses.map((course) => (
          <Grid item key={course.id} xs={12} sm={6} md={4} lg={3.2}>
            <CourseCard course={course as CourseCardItem} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoursesCatalog;