import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, useTheme, useMediaQuery } from '@mui/material';
import { CreditCard, Smartphone, Security, AccountBalance } from '@mui/icons-material';
import PaymentSummary from '../../components/Payment/PaymentSummary';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import withPaymentProcessing from '../../hocs/withPaymentProcessing';
import { CourseDetails } from '../../types/course';
import { Coupon, PaymentResponse } from '../../types/payment/index';

interface NupayPaymentProps {
  courseDetails: CourseDetails;
  appliedCoupon: Coupon | null;
  handleSubmit: (paymentMethod: string, paymentDetails: any) => Promise<PaymentResponse>;
  handleGoBack: () => void;
}

/**
 * Componente NupayPayment
 * 
 * Este componente renderiza a página de pagamento via Nupay (Nubank).
 * Ele exibe informações sobre o método de pagamento, um resumo do pedido,
 * e lida com o processo de pagamento.
 * 
 * @component
 */
const NupayPayment: React.FC<NupayPaymentProps> = ({ courseDetails, appliedCoupon, handleSubmit, handleGoBack }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleNupaySubmit = async (total: number): Promise<void> => {
    await handleSubmit('nupay', { total });
    return;
  };

  if (!courseDetails) return <EmptyContent message="Nenhum detalhe do curso disponível para pagamento." />;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Pagamento via Nupay</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2 }}>
            <Box component="img" src="/assets/stacks/nupay.svg" alt="Nupay Logo" sx={{ width: isMobile ? 100 : isTablet ? 120 : 140, mb: 2 }} />
            <Typography variant="h6" gutterBottom>Conheça o novo pagamento do Nubank!</Typography>
            <List>
              <ListItem>
                <ListItemIcon><Smartphone /></ListItemIcon>
                <ListItemText primary="A compra será finalizada no app do Nubank" />
              </ListItem>
              <ListItem>
                <ListItemIcon><CreditCard /></ListItemIcon>
                <ListItemText primary="Parcele sua compra sem juros ou pague à vista pelo débito" />
              </ListItem>
              <ListItem>
                <ListItemIcon><Security /></ListItemIcon>
                <ListItemText primary="Não precisa preencher os dados do seu cartão" />
              </ListItem>
              <ListItem>
                <ListItemIcon><Security /></ListItemIcon>
                <ListItemText primary="Pode confiar, é seguro. Sua compra é protegida pelas nossas medidas de segurança" />
              </ListItem>
              <ListItem>
                <ListItemIcon><AccountBalance /></ListItemIcon>
                <ListItemText primary="Para usar essa forma de pagamento, você precisa ser cliente do Nubank e ter limite de crédito ou saldo disponível na conta." />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: { md: 'sticky' }, top: 20 }}>
            <PaymentSummary 
              courseDetails={courseDetails} 
              appliedCoupon={appliedCoupon}
              handleSubmit={handleNupaySubmit}
              handleGoBack={handleGoBack}
              submitButtonText="Pagar com Nupay"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withPaymentProcessing(NupayPayment);