import React from 'react';
import { Button, SxProps, Theme, CircularProgress } from '@mui/material';
import { useTheme, lighten } from '@mui/material/styles';

interface ActionButtonProps {
  text: string;
  onClick: () => void;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;  // Nova propriedade para controlar o estado de carregamento
}

/**
 * Componente ActionButton
 * 
 * Este é um botão de ação reutilizável com estilos personalizados e efeitos de hover.
 * 
 * Props:
 * @param {string} text - O texto a ser exibido no botão
 * @param {function} onClick - A função a ser chamada quando o botão é clicado
 * @param {string} [variant='contained'] - A variante do botão (contained, outlined, text)
 * @param {string} [color='primary'] - A cor do botão
 * @param {boolean} [fullWidth=true] - Se o botão deve ocupar a largura total
 * @param {object} [sx] - Estilos adicionais para o botão
 * @param {boolean} [disabled=false] - Se o botão deve estar desabilitado
 * @param {string} [type='button'] - O tipo do botão (button, submit, reset)
 * @param {React.ReactNode} [startIcon] - Ícone para ser exibido antes do texto
 * @param {React.ReactNode} [endIcon] - Ícone para ser exibido depois do texto
 */
const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  onClick,
  variant = 'contained',
  color = 'primary',
  fullWidth = true,
  sx = {},
  disabled = false,
  type = 'button',
  startIcon,
  endIcon,
  loading = false  // Valor padrão é falso
}) => {
  const theme = useTheme();

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      type={type}
      startIcon={!loading && startIcon}
      endIcon={!loading && endIcon}
      sx={{
        padding: 2,
        color: theme.palette.text.primary,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          backgroundColor: lighten(theme.palette[color].main, 0.2),
          transform: 'scale(1.02)',
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
        },
        ...sx
      }}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : text}
    </Button>
  );
};

export default ActionButton;