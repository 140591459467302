import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import CouponInput from '../CoursePurchase/CouponInput';
import { useCheckoutStore } from '../../stores/checkout/checkoutStore';

/**
 * OrderSummary Component
 * 
 * Este componente exibe um resumo do pedido, incluindo detalhes do curso,
 * preços, descontos aplicados e o total final.
 * 
 * Funcionalidades:
 * - Exibição do título do curso
 * - Exibição do preço original do curso
 * - Exibição do desconto do curso (se aplicável)
 * - Exibição do desconto do cupom (se aplicável)
 * - Cálculo e exibição do preço total
 * - Integração com o componente CouponInput para aplicação de cupons
 * 
 * @returns {React.ReactElement} O componente OrderSummary renderizado
 */

const OrderSummary: React.FC = () => {
  const { course, appliedCoupon, calculateTotal } = useCheckoutStore();

  if (!course) return null;

  const total = calculateTotal();

  return (
    <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
      <Typography variant="h6" gutterBottom>Resumo do Pedido</Typography>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography>Curso:</Typography>
        <Typography>{course.title}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography>Preço original:</Typography>
        <Typography>R$ {course.price?.toFixed(2)}</Typography>
      </Box>
      {course.discount > 0 && (
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>Desconto do curso:</Typography>
          <Typography color="error">-R$ {(course.price * course.discount / 100).toFixed(2)}</Typography>
        </Box>
      )}
      {appliedCoupon && (
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>Desconto do cupom:</Typography>
          <Typography color="error">
            {appliedCoupon.type === 'percentage'
              ? `-${appliedCoupon.discount}%`
              : `-R$ ${appliedCoupon.discount?.toFixed(2)}`}
          </Typography>
        </Box>
      )}
      <Divider sx={{ my: 2 }} />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h6">Total:</Typography>
        <Typography variant="h6">R$ {total?.toFixed(2)}</Typography>
      </Box>
      <CouponInput />
    </Box>
  );
};

export default OrderSummary;