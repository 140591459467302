import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, Box, Checkbox, FormControlLabel } from '@mui/material';

interface DeleteAccountModalProps {
  open: boolean;
  onClose: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ open, onClose }) => {
  const [confirmText, setConfirmText] = useState<string>('');
  const [acceptRisk, setAcceptRisk] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (confirmText === 'excluir minha conta' && acceptRisk) {
      // Aqui você implementaria a lógica para excluir a conta
      console.log('Conta excluída');
      onClose();
    }
  };

  const isDeleteDisabled = confirmText !== 'excluir minha conta' || !acceptRisk;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '500px',
        }
      }}
    >
      <DialogTitle sx={{ color: 'error.main' }}>Excluir Conta</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ px: 3, py: 2 }}>
          <Typography variant="body1" paragraph>
            Atenção: Esta ação é irreversível. Todos os seus dados, incluindo perfil, cursos e progresso, serão permanentemente excluídos.
          </Typography>
          <Typography variant="body1" paragraph>
            Se você realmente deseja prosseguir, por favor, digite "excluir minha conta" no campo abaixo e marque a caixa de confirmação.
          </Typography>
          <Box sx={{ my: 2 }}>
            <TextField
              label="Digite 'excluir minha conta'"
              fullWidth
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              required
              autoComplete="off"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptRisk}
                onChange={(e) => setAcceptRisk(e.target.checked)}
                required
              />
            }
            label="Eu entendo que esta ação é irreversível e aceito o risco de perder todos os meus dados progressos e compras realizadas."
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button 
            type="submit" 
            variant="contained" 
            color="error" 
            disabled={isDeleteDisabled}
          >
            Excluir Minha Conta Permanentemente
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteAccountModal;