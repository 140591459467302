import React from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LearningObjectives from './LearningObjectives';
import CourseIncludes from './CourseIncludes';
import CourseContent from './CourseContent';
import { CourseDetails } from '../../types/course';

interface CoursePurchaseContentProps {
  courseDetails: CourseDetails;
}

const CoursePurchaseContent: React.FC<CoursePurchaseContentProps> = ({ courseDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!courseDetails) {
    return null;
  }

  return (
    <Box sx={{ mt: isMobile ? 2 : 4 }}>
      <LearningObjectives objectives={courseDetails.learningObjectives} />
      <Divider sx={{ my: isMobile ? 2 : 4 }} />
      <CourseIncludes lessonCount={courseDetails.lessonCount} totalDuration={courseDetails.totalDuration} />
      <Divider sx={{ my: isMobile ? 2 : 4 }} />
      <CourseContent modules={courseDetails.modules} />
    </Box>
  );
};

export default CoursePurchaseContent;