import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { CarouselData } from '../../types/platform';

interface BannerItem {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  bgColor: string;
}

const bannerItems: BannerItem[] = [
  {
    title: "Transforme sua carreira com a Microstack!",
    description: "Cursos rápidos e focados para impulsionar suas habilidades",
    buttonText: "Explore nossos cursos",
    buttonLink: "/search",
    bgColor: "linear-gradient(45deg, #00bcd4 30%, #ff4081 90%)"
  },
  {
    title: "Aprenda no seu ritmo",
    description: "Acesso ilimitado a cursos de alta qualidade, 24/7",
    buttonText: "Comece agora",
    buttonLink: "/search",
    bgColor: "linear-gradient(45deg, #ff9800 30%, #ff5722 90%)"
  },
  {
    title: "Certificados reconhecidos pelo mercado",
    description: "Destaque-se com nossas certificações profissionais",
    buttonText: "Ver certificações",
    buttonLink: "/certifications",
    bgColor: "linear-gradient(45deg, #4caf50 30%, #8bc34a 90%)"
  }
];

interface CarouselItemProps {
  item: BannerItem;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ item }) => {
  return (
    <Paper
      sx={{
        background: item.bgColor,
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        borderRadius: '12px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        {item.title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {item.description}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        component={Link}
        to={item.buttonLink}
        sx={{ mt: 2 }}
      >
        {item.buttonText}
      </Button>
    </Paper>
  );
};

const MarketingCarousel: React.FC<{ data: CarouselData[] }> = ({ data }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Carousel
        animation="slide"
        interval={6000}
        indicators={true}
        navButtonsAlwaysVisible={true}
      >
        {bannerItems.map((item, index) => (
          <CarouselItem key={index} item={item} />
        ))}
      </Carousel>
    </Box>
  );
};

export default MarketingCarousel;