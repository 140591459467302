import React from 'react';
import { Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const LoginButton: React.FC = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const handleLogin = () => {
    keycloak.login();
  };

  return (
    <Button 
      onClick={handleLogin} 
      component={Link} 
      to="/login"
      state={{ from: location }}
      variant="outlined"
      color="info"
      sx={{
        padding: '8px 40px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        transition: 'all 0.1s ease-in-out',
        borderWidth: '2px',
        '&:hover': {
          backgroundColor: 'rgba(25, 104, 215, 0.4)',
          borderColor: 'deepskyblue',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        },
      }}
    >
      Login
    </Button>
  );
};

export default LoginButton;