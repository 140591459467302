import React from 'react';
import { Paper, Typography } from '@mui/material';
import RecommendedCourses from '../RecommendedCourses/RecommendedCourses';

const PersonalizedRecommendations: React.FC = () => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Recomendações Personalizadas
      </Typography>
      <RecommendedCourses />
    </Paper>
  );
};

export default PersonalizedRecommendations;