import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import NotificationSettings from './NotificationSettings';

const NotificationsTab: React.FC = () => {
  return (
    <Box sx={{ mt: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Configurações de Notificações
        </Typography>
        <NotificationSettings />
      </Paper>
    </Box>
  );
};

export default NotificationsTab;