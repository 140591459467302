import { create } from 'zustand';
import { orderService } from '../../services/orderService';
import { MyOrder, MyOrderOrdersQueryParams } from '../../types/order';

interface OrdersState {
  orders: MyOrder[];
  isLoading: boolean;
  error: string | null;
  page: number;
  pageSize: number;
  total: number;
  fetchOrders: (params?: MyOrderOrdersQueryParams) => Promise<void>;
}

export const useOrdersStore = create<OrdersState>((set) => ({
  orders: [],
  isLoading: false,
  error: null,
  page: 1,
  pageSize: 12,
  total: 0,
  fetchOrders: async (params = { page: 1, pageSize: 12 }) => {
    set({ isLoading: true, error: null });
    try {
      const response = await orderService.getAll(params);
      set({ 
        orders: response.items,
        page: response.page,
        pageSize: response.pageSize,
        total: response.total,
        isLoading: false 
      });
    } catch (error: any) {
      set({ 
        error: error.detail || 'Erro ao carregar os pedidos',
        isLoading: false 
      });
    }
  }
}));
