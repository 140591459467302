import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';

interface CourseInteractionSectionProps {
  showCode: boolean;
  onCodeToggle: () => void;
  codeSnippet: string | null;
}

const CourseInteractionSection: React.FC<CourseInteractionSectionProps> = ({ showCode, onCodeToggle, codeSnippet }) => (
  <Box>
    <Button 
      variant="contained" 
      startIcon={<CodeIcon />} 
      onClick={onCodeToggle}
      sx={{ mb: 2 }}
    >
      {showCode ? 'Esconder Código' : 'Mostrar Código'}
    </Button>
    {showCode && codeSnippet && (
      <Paper elevation={3} sx={{ p: 2, bgcolor: 'grey.100' }}>
        <Typography component="pre" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {codeSnippet}
        </Typography>
      </Paper>
    )}
  </Box>
);

export default CourseInteractionSection;