import React from 'react';
import { Chip } from '@mui/material';
import { School } from '@mui/icons-material';

interface CourseCategoryProps {
  category: string;
  color: string;
}

const CourseCategory: React.FC<CourseCategoryProps> = ({ category, color }) => (
  <Chip 
    icon={<School fontSize="small" />} 
    label={category} 
    size="small" 
    sx={{ 
      backgroundColor: color, 
      color: 'white',
      maxWidth: '100%',
      '& .MuiChip-label': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }}
  />
);

export default CourseCategory;