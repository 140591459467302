import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
    HttpTransportType,
    JsonHubProtocol
} from '@microsoft/signalr';
import { RealtimeMessage } from '../types/realtime/realtime';
import { useNotificationStore } from '../stores/notification/useNotificationStore';

/**
 * Enumeração que representa os possíveis estados da conexão.
 */
export enum ConnectionState {
    Disconnected = 'Disconnected',
    Connecting = 'Connecting',
    Connected = 'Connected',
    Reconnecting = 'Reconnecting',
    Disconnecting = 'Disconnecting'
}

/**
 * Serviço para gerenciar conexões SignalR e notificações em tempo real.
 */
export class NotificationEventService {
    private connection: HubConnection | null = null;
    private readonly messageHandlers: Set<(notification: RealtimeMessage) => void>;
    private readonly stateChangeCallbacks: Set<(state: ConnectionState) => void>;
    private readonly baseUrl: string;
    private token: string;
    private isConnecting: boolean;
    private reconnectAttempts: number;
    private readonly maxReconnectAttempts: number;
    private connectionCheckInterval: NodeJS.Timeout | null;
    private lastActivityTimestamp: number;
    private disposed: boolean;

    /**
     * Cria uma instância do serviço de notificação.
     * @param baseUrl - URL base do servidor SignalR.
     * @param token - Token de autenticação para a conexão.
     */
    constructor(baseUrl: string, token: string) {
        this.baseUrl = baseUrl;
        this.token = token;
        this.messageHandlers = new Set();
        this.stateChangeCallbacks = new Set();
        this.isConnecting = false;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.connectionCheckInterval = null;
        this.lastActivityTimestamp = Date.now();
        this.disposed = false;
    }

    /**
     * Conecta ao servidor SignalR.
     * @throws Lança um erro se o serviço já foi descartado.
     */
    public async connect(): Promise<void> {
        if (this.disposed) {
            throw new Error('Service has been disposed');
        }

        // Verifica se o token existe
        if (!this.token || this.token.trim() === '') {
            console.error('Token is missing or empty. Cannot connect.');
            throw new Error('Token is missing or empty');
        }

        if (await this.isConnectionValid()) {
            console.debug('Using existing valid connection');
            return;
        }

        if (this.isConnecting) {
            console.debug('Connection attempt already in progress');
            return;
        }

        try {
            this.isConnecting = true;
            await this.establishConnection();
            this.startConnectionMonitoring();
        } catch (error) {
            console.error('Failed to establish connection:', error);
            await this.cleanupConnection();
            throw error;
        } finally {
            this.isConnecting = false;
        }
    }

    private startConnectionMonitoring(): void {
        if (this.connectionCheckInterval) {
            clearInterval(this.connectionCheckInterval);
        }

        this.connectionCheckInterval = setInterval(async () => {
            if (this.disposed) {
                this.stopConnectionMonitoring();
                return;
            }

            const inactiveTime = Date.now() - this.lastActivityTimestamp;
            if (inactiveTime > 120000) { // 2 minutos de inatividade
                console.debug('Connection inactive, checking status...');
                if (!(await this.isConnectionValid())) {
                    console.debug('Connection invalid, attempting reconnect...');
                    await this.cleanupConnection();
                    await this.connect();
                }
            }
        }, 30000); // Verifica a cada 30 segundos
    }

    /**
     * Verifica se a conexão atual é válida.
     * @returns `true` se a conexão estiver ativa e válida, caso contrário, `false`.
     */
    private async isConnectionValid(): Promise<boolean> {
        if (!this.connection) {
            return false;
        }

        // Verifica se a conexão está no estado "Connected"
        if (this.connection.state === HubConnectionState.Connected) {
            this.updateLastActivity(); // Atualiza o timestamp da última atividade
            return true;
        }

        return false;
    }

    /**
     * Estabelece uma nova conexão com o servidor SignalR.
     * @throws Lança um erro se a conexão falhar.
     */
    private async establishConnection(): Promise<void> {
        // Verifica se o token existe
        if (!this.token || this.token.trim() === '') {
            console.error('Token is missing or empty. Cannot establish connection.');
            throw new Error('Token is missing or empty');
        }

        try {
            this.connection = new HubConnectionBuilder()
                .withUrl(`${this.baseUrl}/notifications`, {
                    accessTokenFactory: () => this.token,
                    transport: HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling,
                    skipNegotiation: false,
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                })
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        if (this.reconnectAttempts >= this.maxReconnectAttempts || !this.token || this.token.trim() === '') {
                            return null; // Stop retrying after max attempts
                        }
                        this.reconnectAttempts++;
                        const delay = Math.min(2000 * Math.pow(2, retryContext.previousRetryCount), 60000);
                        console.debug(`Scheduling reconnection attempt in ${delay}ms`);
                        return delay;
                    }
                })
                .withHubProtocol(new JsonHubProtocol())
                .withKeepAliveInterval(30000)
                .configureLogging(LogLevel.Information)
                .build();

            this.connection.serverTimeoutInMilliseconds = 30000 * 4;

            await this.setupConnectionHandlers();
            await this.startConnection();
        } catch (error) {
            console.error('Failed to establish connection:', error);
            throw error;
        }
    }

    /**
     * Configura os handlers de eventos da conexão SignalR.
     */
    private async setupConnectionHandlers(): Promise<void> {
        if (!this.connection) return;

        this.connection.on('ReceiveMessage', (notification: RealtimeMessage) => {
            this.updateLastActivity(); // Atualiza o timestamp da última atividade
            console.debug('Received notification:', notification);
            this.messageHandlers.forEach(handler => {
                try {
                    handler(notification);
                } catch (error) {
                    console.error('Error in notification handler:', error);
                }
            });
        });

        this.connection.onreconnecting((error) => {
            console.warn('Connection lost, attempting to reconnect...', error);
            this.notifyStateChange(ConnectionState.Reconnecting);
        });

        this.connection.onreconnected((connectionId) => {
            console.debug('Successfully reconnected:', connectionId);
            this.reconnectAttempts = 0;
            this.updateLastActivity();
            useNotificationStore.setState({ connectionState: ConnectionState.Connected });
            this.notifyStateChange(ConnectionState.Connected);
        });

        this.connection.onclose((error) => {
            console.debug('Connection closed:', error);
            this.handleConnectionClosed(error);
        });
    }

    /**
     * Para o monitoramento da conexão.
     */
    private stopConnectionMonitoring(): void {
        if (this.connectionCheckInterval) {
            clearInterval(this.connectionCheckInterval);
            this.connectionCheckInterval = null;
        }
    }

    /**
     * Atualiza o timestamp da última atividade.
     */
    private updateLastActivity(): void {
        this.lastActivityTimestamp = Date.now();
    }

    /**
     * Limpa a conexão atual e redefine o estado.
     */
    private async cleanupConnection(): Promise<void> {
        this.stopConnectionMonitoring();

        if (this.connection) {
            try {
                await this.connection.stop();
                console.debug('Connection stopped');
            } catch (error) {
                console.warn('Error stopping connection:', error);
            } finally {
                this.connection = null;
            }
        }

        this.isConnecting = false;
        this.reconnectAttempts = 0;
        this.notifyStateChange(ConnectionState.Disconnected);
    }

    /**
     * Inicia a conexão com o servidor SignalR.
     * @throws Lança um erro se a conexão não puder ser iniciada.
     */
    private async startConnection(): Promise<void> {
        if (!this.connection) {
            throw new Error('Connection not initialized');
        }

        if (this.getConnectionState() === ConnectionState.Connecting) {
            return;
        }

        try {
            this.notifyStateChange(ConnectionState.Connecting);
            await this.connection.start();
            console.debug('Connection established successfully');
            this.reconnectAttempts = 0;
            this.updateLastActivity();
            this.notifyStateChange(ConnectionState.Connected);
        } catch (error) {
            console.error('Failed to start connection:', error);
            this.notifyStateChange(ConnectionState.Disconnected);
            throw error;
        }
    }

    /**
     * Lida com o fechamento da conexão.
     * @param error - Erro que causou o fechamento da conexão, se houver.
     */
    private async handleConnectionClosed(error: Error | undefined): Promise<void> {
        if (error) {
            console.error('Connection closed due to error:', error);
        }

        await new Promise(resolve => setTimeout(resolve, 5000));

        useNotificationStore.setState({ connectionState: ConnectionState.Disconnected });

        if (!this.disposed && this.reconnectAttempts < this.maxReconnectAttempts) {
            console.debug('Attempting to reconnect after connection close');
            await this.connect();
        }
    }

    /**
     * Notifica todos os callbacks registrados sobre uma mudança de estado.
     * @param state - Novo estado da conexão.
     */
    private notifyStateChange(state: ConnectionState): void {
        console.debug(`Connection state changed to: ${state}`);
        this.stateChangeCallbacks.forEach(callback => callback(state));
    }

    /**
     * Registra um handler para receber mensagens do servidor.
     * @param handler - Função que será chamada quando uma mensagem for recebida.
     * @returns Uma função para cancelar o registro do handler.
     * @throws Lança um erro se o serviço já foi descartado.
     */
    public onMessage(handler: (notification: RealtimeMessage) => void): () => void {
        if (this.disposed) {
            throw new Error('Service has been disposed');
        }

        this.messageHandlers.add(handler);
        return () => {
            this.messageHandlers.delete(handler);
        };
    }

    /**
     * Registra um callback para ser notificado sobre mudanças de estado da conexão.
     * @param callback - Função que será chamada quando o estado da conexão mudar.
     * @returns Uma função para cancelar o registro do callback.
     * @throws Lança um erro se o serviço já foi descartado.
     */
    public onStateChange(callback: (state: ConnectionState) => void): () => void {
        if (this.disposed) {
            throw new Error('Service has been disposed');
        }

        this.stateChangeCallbacks.add(callback);
        return () => {
            this.stateChangeCallbacks.delete(callback);
        };
    }

    /**
     * Atualiza o token de autenticação e reconecta ao servidor.
     * @param newToken - Novo token de autenticação.
     * @throws Lança um erro se o serviço já foi descartado.
     */
    public async updateToken(newToken: string): Promise<void> {
        if (this.disposed) {
            throw new Error('Service has been disposed');
        }

        if (this.token === newToken) {
            return;
        }

        console.debug('Updating token and reconnecting...');
        this.token = newToken;
        await this.cleanupConnection();
        await this.connect();
    }

    /**
     * Obtém o estado atual da conexão.
     * @returns O estado atual da conexão.
     */
    public getConnectionState(): ConnectionState {
        if (this.disposed) {
            return ConnectionState.Disconnected;
        }

        if (!this.connection) {
            return ConnectionState.Disconnected;
        }

        switch (this.connection.state) {
            case HubConnectionState.Connected:
                return ConnectionState.Connected;
            case HubConnectionState.Connecting:
                return ConnectionState.Connecting;
            case HubConnectionState.Disconnected:
                return ConnectionState.Disconnected;
            case HubConnectionState.Disconnecting:
                return ConnectionState.Disconnecting;
            case HubConnectionState.Reconnecting:
                return ConnectionState.Reconnecting;
            default:
                return ConnectionState.Disconnected;
        }
    }

    /**
     * Verifica se a conexão está ativa.
     * @returns `true` se a conexão estiver ativa, caso contrário, `false`.
     */
    public isConnected(): boolean {
        return this.connection?.state === HubConnectionState.Connected;
    }

    /**
     * Desconecta do servidor.
     * @throws Lança um erro se o serviço já foi descartado.
     */
    public async disconnect(): Promise<void> {
        await this.cleanupConnection();
    }

    /**
     * Descarta o serviço, liberando todos os recursos.
     */
    public dispose(): void {
        if (this.disposed) {
            return;
        }

        this.disposed = true;
        this.messageHandlers.clear();
        this.stateChangeCallbacks.clear();
        this.disconnect().catch(error => {
            console.error('Error during disposal:', error);
        });
    }
}