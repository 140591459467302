import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Paper, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { paymentService } from '../../services/paymentService';
import { Order, PaymentResponse } from '../../types/payment';

interface OrderRejectionProps {
  orderDetails: Order;
}

/**
 * Componente OrderRejection
 * 
 * Este componente exibe informações sobre um pedido rejeitado e permite ao usuário tentar o pagamento novamente.
 * 
 * @param {OrderRejectionProps} props - Propriedades do componente
 * @param {Order} props.orderDetails - Detalhes do pedido rejeitado
 */
const OrderRejection: React.FC<OrderRejectionProps> = ({ orderDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleRetryPayment = async (): Promise<void> => {
    try {
      setIsProcessing(true);
      const result: PaymentResponse = await paymentService.processPayment(
        {
          courseId: orderDetails.courseId,
          paymentMethod: orderDetails.paymentMethod,
          couponCode: orderDetails.couponCode,
          paymentDetails: []
        }
      );
      navigate(`/order-status/${result.orderId}`, { replace: true });
    } catch (error) {
      console.error('Erro ao tentar o pagamento novamente:', error);
      // Aqui você pode adicionar uma lógica para mostrar uma mensagem de erro ao usuário
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mt: 4 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={isMobile ? 2 : 4}>
        <ErrorOutlineIcon color="error" sx={{ fontSize: isMobile ? 40 : 60, mb: 2 }} />
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom align="center">
          Pagamento Não Aprovado
        </Typography>
        <Typography variant="subtitle1" align="center">
          Número do Pedido: {orderDetails.id}
        </Typography>
      </Box>

      <Typography variant="body1" paragraph align={isMobile ? "left" : "center"}>
        Infelizmente, o pagamento para este pedido não foi aprovado. Isso pode ocorrer devido a:
      </Typography>

      <Box sx={{ mb: 2, pl: 2 }}>
        <Typography variant="body1" component="li">Saldo insuficiente</Typography>
        <Typography variant="body1" component="li">Problemas com o cartão de crédito</Typography>
        <Typography variant="body1" component="li">Informações de pagamento incorretas</Typography>
      </Box>

      <Typography variant="body1" paragraph align={isMobile ? "left" : "center"}>
        Recomendamos que você verifique suas informações de pagamento e tente novamente ou use um método de pagamento alternativo.
      </Typography>

      <Box mt={isMobile ? 2 : 4} display="flex" justifyContent="center" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <Button
          onClick={handleRetryPayment}
          variant="contained"
          color="primary"
          fullWidth={isMobile}
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} /> : 'Tentar Novamente'}
        </Button>
        <Button
          component={Link}
          to={`/payment-update/${orderDetails.id}`}
          variant="outlined"
          fullWidth={isMobile}
          disabled={isProcessing}
        >
          Atualizar Forma de Pagamento
        </Button>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          fullWidth={isMobile}
          disabled={isProcessing}
        >
          Voltar para o Início
        </Button>
      </Box>
    </Paper>
  );
};

export default OrderRejection;