import { create } from 'zustand';
import { userService } from '../../services/userService';

interface ApiValidationError {
  type: string;
  title: string;
  status: number;
  detail: string;
  errors?: Array<{
    propertyName: string;
    errorMessage: string;
  }>;
  requestId?: string;
  traceId?: string;
}

interface AuthState {
  isLoading: boolean;
  error: string | null;
  fieldErrors: Record<string, string>;
  register: (email: string, firstName: string, lastName: string, password: string) => Promise<boolean>;
  clearError: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  isLoading: false,
  error: null,
  fieldErrors: {},
  register: async (email: string, firstName: string, lastName: string, password: string) => {
    set({ isLoading: true, error: null, fieldErrors: {} });
    try {
      await userService.register({ email, firstName, lastName, password });
      set({ isLoading: false });
      return true;
    } catch (error: any) {
      const apiError = error as ApiValidationError;
      console.log('API Error:', apiError); // Para debug

      if (apiError?.type === 'ValidationFailure' && apiError.errors) {
        // Erro de validação com detalhes por campo
        const fieldErrors: Record<string, string> = {};
        apiError.errors.forEach((err) => {
          // Converte o nome da propriedade para lowercase para corresponder aos campos do formulário
          const fieldName = err.propertyName.charAt(0).toLowerCase() + err.propertyName.slice(1);
          fieldErrors[fieldName] = err.errorMessage;
          console.log(`Setting error for field ${fieldName}:`, err.errorMessage); // Para debug
        });
        
        set({ 
          fieldErrors,
          error: null, // Não mostra erro geral quando há erros específicos de campo
          isLoading: false 
        });
      } else if (apiError?.title === 'User.AlreadyExists') {
        // Erro de usuário já existente
        set({ 
          fieldErrors: { email: 'Este email já está em uso' },
          error: apiError.detail || 'Usuário já cadastrado',
          isLoading: false 
        });
      } else {
        // Erro geral
        set({ 
          error: apiError?.detail || apiError?.title || 'Ocorreu um erro durante o registro',
          isLoading: false 
        });
      }
      return false;
    }
  },
  clearError: () => set({ error: null, fieldErrors: {} })
}));
