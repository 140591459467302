import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import UserInfo from '../../components/Profile/UserInfo';
import UserProgress from '../../components/Profile/UserProgress';
import InterestsAndGoals from '../../components/Profile/InterestsAndGoals';
import PersonalizedRecommendations from '../../components/Profile/PersonalizedRecommendations';
import ProgressMetrics from '../../components/Profile/ProgressMetrics';
import ProfileService from '../../services/ProfileService';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { UserProfileItem } from '../../types/user';


const Profile: React.FC = () => {
  const { keycloak } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserProfileItem | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileData = await ProfileService.getUserProfile();
        setUserProfile(profileData);
      } catch (error) {
        setError(error instanceof Error ? error : new Error('An unknown error occurred'));
      }
    };
    fetchUserProfile();
  }, []);

  const user = {
    name: keycloak.tokenParsed?.name || 'Usuário',
    avatarUrl: keycloak.tokenParsed?.picture || '',
    email: keycloak.tokenParsed?.email || '',
  };

  if (error) {
    return <ErrorDisplay error={error} onRetry={() => window.location.reload()} />;
  }

  if (!userProfile) {
    return <EmptyContent message="Carregando perfil do usuário..." />;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Perfil do Usuário
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
        <Box sx={{ flex: { xs: '1', md: '0 0 30%' } }}>
          <UserInfo user={userProfile} />
          <Box sx={{ mt: 4 }}>
            <InterestsAndGoals interestsAndGoals={userProfile.interestsAndGoals} />
          </Box>
        </Box>
        <Box sx={{ flex: { xs: '1', md: '0 0 70%' } }}>
          <UserProgress progress={userProfile.progress} />
          <Box sx={{ mt: 4 }}>
            <ProgressMetrics progress={userProfile.progress} />
          </Box>
          <Box sx={{ mt: 4 }}>
            <PersonalizedRecommendations />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;