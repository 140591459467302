import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ShoppingCart } from '@mui/icons-material';

interface UserProfileMenuProps {
  onClose: () => void;
  onLogout: () => void;
}

const UserProfileMenu: React.FC<UserProfileMenuProps> = ({ onClose, onLogout }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <MenuItem onClick={() => handleNavigation('/profile')}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Perfil" />
      </MenuItem>
      <MenuItem onClick={() => handleNavigation('/my-courses')}>
        <ListItemIcon>
          <SchoolIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Meus Cursos" />
      </MenuItem>
      <MenuItem onClick={() => handleNavigation('/orders')}>
        <ListItemIcon>
          <ShoppingCart fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Meus Pedidos" />
      </MenuItem>
      <MenuItem onClick={() => handleNavigation('/configuracoes')}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Configurações" />
      </MenuItem>
      <MenuItem onClick={onLogout}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </MenuItem>
    </>
  );
};

export default UserProfileMenu;