import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import CourseHeader from '../../components/CourseLearning/CourseHeader';
import CourseLessonsSection from '../../components/CourseLearning/CourseLessons/CourseLessonsSection';
import CourseVideoSection from '../../components/CourseLearning/CourseVideoSection/CourseVideoSection';
import CourseInteractionSection from '../../components/CourseLearning/CourseInteractionSection/CourseInteractionSection';
import CourseDescriptionSection from '../../components/CourseLearning/CourseDescriptionSection/CourseDescriptionSection';
import { fetchCourseDetailsLearning } from '../../services/courseService';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { Course, Lesson } from '../../types/course';

const CourseLearning: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [courseDetails, setCourseDetails] = useState<Course | null>(null);
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(null);
  const [showCode, setShowCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const isAdmin = true; // TODO: Substituir isso pela lógica real de verificação de admin

  useEffect(() => {
    if (id) {
      fetchCourseData();
    }
  }, [id]);

  const fetchCourseData = async () => {
    try {
      setLoading(true);
      setError(null);
      if (!id) {
        throw new Error('ID do curso não fornecido');
      }
      const data = await fetchCourseDetailsLearning(id);
      if (!data) {
        throw new Error('Curso não encontrado');
      }
      setCourseDetails(data);
      if (data.modules && data.modules.length > 0 && data.modules[0].items.length > 0) {
        const firstLesson = data.modules[0].items[0];
        setCurrentLesson(firstLesson);
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An unknown error occurred'));
    } finally {
      setLoading(false);
    }
  };

  const handleLessonSelect = (lesson: Lesson) => {
    setCurrentLesson(lesson);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorDisplay error={error} isAdmin={isAdmin} onRetry={fetchCourseData} />;
  }

  if (!courseDetails) {
    return <EmptyContent message="Detalhes do curso não disponíveis." />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CourseHeader title={courseDetails.title} instructor={courseDetails.instructor} />
      <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2, gap: 2 }}>
        <Paper elevation={3} sx={{ width: '30%' }}>
          <CourseLessonsSection 
            modules={courseDetails.modules}
            onLessonSelect={handleLessonSelect}
            currentLessonId={currentLesson.id}
          />
        </Paper>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <CourseVideoSection videoUrl={currentLesson.videoUrl} />
          <CourseInteractionSection 
            showCode={showCode} 
            onCodeToggle={() => setShowCode(!showCode)} 
            codeSnippet={currentLesson.codeSnippet}
          />
          <CourseDescriptionSection 
            description={courseDetails.description}
            duration={courseDetails.totalDuration}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CourseLearning;