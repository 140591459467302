import api from '../api/api';

interface RegisterUserPayload {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

interface RegisterResponse {
  id: string;
  links: Array<{
    href: string;
    rel: string;
    method: string;
  }>;
}

interface ValidationError {
  type: string;
  title: string;
  status: number;
  detail: string;
  errors?: Array<{
    propertyName: string;
    errorMessage: string;
  }>;
  requestId?: string;
  traceId?: string;
}

export const userService = {
  register: async (payload: RegisterUserPayload): Promise<RegisterResponse> => {
    try {
      const response = await api.post('/api/v1/users/register', payload);
      return response.data;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response.data;
      }
      throw error;
    }
  }
};
