import React from 'react';
import { Box } from '@mui/material';
import { StripeBankSlip, BankSlipData } from './gateways/StripeBankSlip';
import { PaymentGateway } from '../../types/payment';

interface GatewayBankSlipProps {
  gateway: PaymentGateway;
  onFormComplete: (isComplete: boolean) => void;
  onDataChange: (data: BankSlipData) => void;
}

const GatewayBankSlip: React.FC<GatewayBankSlipProps> = ({
  gateway,
  onFormComplete,
  onDataChange
}) => {
  const renderGateway = () => {
    switch (gateway) {
      case PaymentGateway.Stripe:
        return (
          <StripeBankSlip
            onFormComplete={onFormComplete}
            onDataChange={onDataChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {renderGateway()}
    </Box>
  );
};

export default GatewayBankSlip;
