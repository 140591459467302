import React from 'react';
import { Paper, Typography, Box, LinearProgress } from '@mui/material';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer } from 'recharts';
import EmptyContent from '../EmptyContent/EmptyContent';
import { UserProgressItem } from '../../types/user';

interface ProgressMetricsProps {
  progress: UserProgressItem;
}

const ProgressMetrics: React.FC<ProgressMetricsProps> = ({ progress }) => {
  if (!progress) {
    return <EmptyContent message="Dados de progresso não disponíveis." />;
  }

  const skillData = progress.skillProgress?.map(skill => ({
    subject: skill.skill,
    A: skill.progress,
    fullMark: 100
  })) || [];

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Métricas de Progresso
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Habilidades Desenvolvidas
        </Typography>
        {skillData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={skillData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 100]} />
              <Radar name="Progresso" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
            </RadarChart>
          </ResponsiveContainer>
        ) : (
          <EmptyContent message="Nenhuma habilidade desenvolvida ainda." />
        )}
      </Box>

      <Box>
        <Typography variant="subtitle1" gutterBottom>
          Progresso por Curso
        </Typography>
        {progress.courseProgress && progress.courseProgress.length > 0 ? (
          progress.courseProgress.map((course) => (
            <Box key={course.courseId} sx={{ mb: 2 }}>
              <Typography variant="body2">{course.title}</Typography>
              <LinearProgress variant="determinate" value={course.progress} />
              <Typography variant="body2" align="right">{`${course.progress}%`}</Typography>
            </Box>
          ))
        ) : (
          <EmptyContent message="Nenhum curso em progresso." />
        )}
      </Box>
    </Paper>
  );
};

export default ProgressMetrics;