import React, { useState } from 'react';
import { IconButton, Menu, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import UserAvatar from './Components/UserAvatar';
import UserProfileHeader from './Components/UserProfileHeader';
import UserProfileMenu from './Components/UserProfileMenu';

const UserProfile: React.FC = () => {
  const { keycloak } = useKeycloak();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const user: any = {
    name: keycloak.tokenParsed?.name || 'Usuário',
    avatarUrl: keycloak.tokenParsed?.picture || '',
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/');
    keycloak.logout();
    handleClose();
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="conta do usuário"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <UserAvatar user={user} username={keycloak.tokenParsed?.name || 'Usuário'} size={32} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: 300,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <UserProfileHeader user={user} username={user.name} />
        <Divider />
        <UserProfileMenu onClose={handleClose} onLogout={handleLogout} />
      </Menu>
    </>
  );
};

export default UserProfile;