import { create } from 'zustand';
import { CourseDetails } from '../../types/course';
import { Coupon } from '../../types/payment';
import { verifyCoupon } from '../../services/couponService';
import { fetchCourseDetails } from '../../services/courseService';

interface CheckoutState {
  course: CourseDetails | null;
  appliedCoupon: Coupon | null;
  isLoading: boolean;
  error: string | null;
  selectedPaymentMethod: string | null;
  setCourse: (course: CourseDetails) => void;
  setAppliedCoupon: (coupon: Coupon | null) => void;
  applyCoupon: (code: string) => Promise<void>;
  removeCoupon: () => void;
  calculateTotal: () => number;
  loadCourseDetails: (courseId: string) => Promise<void>;
  setSelectedPaymentMethod: (method: string) => void;
  setError: (error: string | null) => void;
}

/**
 * Checkout Store
 * 
 * Este store gerencia o estado global relacionado ao processo de checkout,
 * incluindo detalhes do curso, cupom aplicado e cálculo do preço total.
 * 
 * Funcionalidades:
 * - Armazenamento dos detalhes do curso selecionado
 * - Gerenciamento do cupom aplicado
 * - Aplicação e remoção de cupons
 * - Cálculo do preço total considerando descontos do curso e cupons
 * - Gerenciamento de estados de carregamento e erros
 * 
 * @returns {Object} O store de checkout com seus estados e ações
 */
export const useCheckoutStore = create<CheckoutState>()((set, get) => ({
  course: null,
  appliedCoupon: null,
  isLoading: false,
  error: null,
  selectedPaymentMethod: null,
  setCourse: (course) => set({ course }),
  setAppliedCoupon: (coupon) => set({ appliedCoupon: coupon }),
  applyCoupon: async (code) => {
    set({ isLoading: true, error: null });
    try {
      const coupon = await verifyCoupon(code);
      if (coupon && 'id' in coupon) {
        set({ appliedCoupon: coupon, isLoading: false, error: null });
      } else {
        set({ appliedCoupon: null, error: 'Cupom inválido', isLoading: false });
      }
    } catch (error) {
      set({ appliedCoupon: null, error: 'Erro ao verificar cupom', isLoading: false });
    }
  },
  removeCoupon: () => set({ appliedCoupon: null, error: null }),
  calculateTotal: () => {
    const { course, appliedCoupon } = get();
    if (!course) return 0;
    let total = course.price * (1 - course.discount / 100);
    if (appliedCoupon) {
      if (appliedCoupon.type === 'percentage') {
        total *= (1 - appliedCoupon.discount / 100);
      } else if (appliedCoupon.type === 'fixed') {
        total = Math.max(0, total - appliedCoupon.discount);
      }
    }
    return Math.max(0, total);
  },
  loadCourseDetails: async (courseId) => {
    set({ isLoading: true, error: null });
    try {
      const details = await fetchCourseDetails(courseId);
      set({ course: details, isLoading: false });
    } catch (err) {
      set({ error: 'Não foi possível carregar os detalhes do curso.', isLoading: false });
    }
  },
  setSelectedPaymentMethod: (method) => set({ selectedPaymentMethod: method }),
  setError: (error) => set({ error }),
}));