import React from 'react';
import { MenuItem, Box } from '@mui/material';
import NotificationIcon from './NotificationIcon';
import NotificationTitle from './NotificationTitle';
import NotificationMessage from './NotificationMessage';
import NotificationActions from './NotificationActions';
import { NotificationAction, NotificationNav } from '../../types/notification';

interface NotificationItemProps {
  notification: NotificationNav;
  onAction: (notification: NotificationNav, action: NotificationAction) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onAction }) => {
  const handleClick = () => {
    if (notification.actions.length === 1) {
      onAction(notification, notification.actions[0]);
    }
  };

  return (
    <MenuItem 
      onClick={handleClick}
      sx={{ 
        py: 2,
        px: 0,
        borderBottom: '1px solid',
        borderColor: 'divider',
        '&:hover': { backgroundColor: 'action.hover' },
        alignItems: 'stretch',
        minHeight: 'auto',
        overflow: 'visible',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          px: 2,
        }}>
          <NotificationIcon type={notification.type} />
        </Box>
        <Box sx={{ 
          flexGrow: 1, 
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          pr: 2,
        }}>
          <NotificationTitle title={notification.title} />
          <NotificationMessage message={notification.message} />
          {notification.actions.length > 1 && (
            <NotificationActions 
              actions={notification.actions} 
              onAction={(action) => onAction(notification, action)} 
            />
          )}
        </Box>
      </Box>
    </MenuItem>
  );
};

export default NotificationItem;