import React, { useState, useEffect } from 'react';
import { Paper, Typography, Chip, Box } from '@mui/material';
import ProfileService from '../../services/ProfileService';
import { InterestsAndGoalsItem } from '../../types/user';

interface InterestsAndGoalsProps {
  interestsAndGoals: InterestsAndGoalsItem;
}

const InterestsAndGoals: React.FC<InterestsAndGoalsProps> = ({ interestsAndGoals }) => {

  if (!interestsAndGoals) return null;

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Interesses e Objetivos
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {interestsAndGoals.interests.map((interest, index) => (
          <Chip key={index} label={interest} />
        ))}
      </Box>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Objetivos:
      </Typography>
      <ul>
        {interestsAndGoals.goals.map((goal, index) => (
          <li key={index}>{goal}</li>
        ))}
      </ul>
    </Paper>
  );
};

export default InterestsAndGoals;