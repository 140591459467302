import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import AccessSecurity from './AccessSecurity';
import DeleteAccount from './DeleteAccount';

const AccountTab: React.FC = () => {
  return (
    <Box sx={{ mt: 3 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Acesso e Segurança
        </Typography>
        <AccessSecurity />
      </Paper>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Excluir Conta
        </Typography>
        <DeleteAccount />
      </Paper>
    </Box>
  );
};

export default AccountTab;