import api from '../api/api';
import { UserProfileItem } from '../types/user';
import { CatalogCourse } from '../types/course';
import { fetchRecommendedCourses } from './courseService';

const ProfileService = {
    getUserProfile: async (): Promise<UserProfileItem> => {
        try {
            const response = await api.get<UserProfileItem>('/api/v1/users/profile/profile');
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar perfil do usuário:', error);
            throw error;
        }
    },

    getRecommendedCourses: async (): Promise<CatalogCourse[]> => {
        try {
            return await fetchRecommendedCourses();
        } catch (error) {
            console.error('Erro ao buscar cursos recomendados:', error);
            throw error;
        }
    }
};

export default ProfileService;