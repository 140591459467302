import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled, keyframes, useTheme } from '@mui/system';

interface CardDetails {
  cardNumber: string;
  expiry: string;
  cvv: string;
  cpfCnpj: string;
  fullName: string;
  birthDate: string;
}

interface AnimatedCreditCardProps {
  cardDetails: CardDetails;
  focusedField: string | null;
  scale: number;
}

const emphasize = keyframes`
  to {
    opacity: 1;
    transform: scale(1.05);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  from {
    opacity: 0.8;
    transform: scale(1);
    text-shadow: none;
  }
`;

const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'scale' && prop !== 'isFlipped',
})<{ scale: number; isFlipped: boolean }>(({ theme, scale, isFlipped }) => ({
  width: '100%',
  maxWidth: '450px',
  aspectRatio: '1.586',
  backgroundColor: '#1e3a8a',
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: 'white',
  position: 'relative',
  transition: 'all 0.6s ease',
  transform: `rotateY(${isFlipped ? '180deg' : '0deg'}) scale(${scale})`,
  transformStyle: 'preserve-3d',
  margin: '0 auto',
}));

const CardFace = styled(Box)({
  backfaceVisibility: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const CardBack = styled(CardFace)({
  transform: 'rotateY(180deg)',
  backgroundColor: '#1e3a8a',
  borderRadius: '10px',
});

const MagneticStrip = styled(Box)({
  position: 'absolute',
  top: '20%',
  left: 0,
  width: '100%',
  height: '15%',
  backgroundColor: '#000',
});

const CardField = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFocused',
})<{ isFocused: boolean }>(({ isFocused }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  transition: 'all 0.3s ease',
  backgroundColor: isFocused ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
  animation: isFocused ? `${emphasize} 0.6s ease-in-out infinite alternate` : 'none',
  transform: isFocused ? 'scale(1.05)' : 'scale(1)',
}));

const CardNumber = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  letterSpacing: '0.1em',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
}));

const CardName = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
}));

const CardExpiry = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
}));

const CVVBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isEmphasized',
})<{ isEmphasized: boolean }>(({ isEmphasized }) => ({
  position: 'absolute',
  right: '10%',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: '8px 16px',
  backgroundColor: '#f0f0f0',
  borderRadius: '4px',
  color: '#000',
  animation: isEmphasized ? `${emphasize} 0.6s ease-in-out infinite alternate` : 'none',
}));

const formatCardNumber = (number: string): string => {
  // Se o número já estiver formatado com bullets, retorna como está
  if (number.includes('•')) {
    return number;
  }
  
  // Se não, formata o número limpo
  const cleaned = number.replace(/\D/g, '');
  const groups = cleaned.match(/.{1,4}/g) || [];
  return groups.join(' ');
};

const AnimatedCreditCard: React.FC<AnimatedCreditCardProps> = ({ cardDetails, focusedField, scale }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isFlipped = focusedField === 'cvv';

  return (
    <CardContainer scale={scale} isFlipped={isFlipped}>
      <CardFace>
        <Box>
          <Box sx={{ mb: 4 }}>
            <img src="/chip.png" alt="Chip" style={{ width: '50px', height: 'auto' }} />
          </Box>
          <CardField isFocused={focusedField === 'cardNumber'}>
            <CardNumber>
              {cardDetails.cardNumber ? formatCardNumber(cardDetails.cardNumber) : '•••• •••• •••• ••••'}
            </CardNumber>
          </CardField>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Box sx={{ flex: '1 1 auto', mr: 2, maxWidth: '70%' }}>
            <Typography variant="caption" sx={{ opacity: 0.8, mb: 0.5, display: 'block' }}>
              Nome no Cartão
            </Typography>
            <CardField isFocused={focusedField === 'fullName'}>
              <CardName>
                {cardDetails.fullName || 'NOME COMPLETO'}
              </CardName>
            </CardField>
          </Box>
          <Box>
            <Typography variant="caption" sx={{ opacity: 0.8, mb: 0.5, display: 'block' }}>
              Validade
            </Typography>
            <CardField isFocused={focusedField === 'expiry'}>
              <CardExpiry>
                {cardDetails.expiry || 'MM/AA'}
              </CardExpiry>
            </CardField>
          </Box>
        </Box>
      </CardFace>
      <CardBack>
        <MagneticStrip />
        <CVVBox isEmphasized={focusedField === 'cvv'}>
          <Typography variant="body2">
            {cardDetails.cvv || '•••'}
          </Typography>
        </CVVBox>
      </CardBack>
    </CardContainer>
  );
};

export default AnimatedCreditCard;