import React, { useState } from 'react';
import { Box, Typography, Switch, FormControlLabel, Paper, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChangePasswordModal from './ChangePasswordModal';

const AccessSecurity: React.FC = () => {
  const [twoFAEnabled, setTwoFAEnabled] = useState<boolean>(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false);

  const handleTwoFAToggle = () => {
    setTwoFAEnabled(!twoFAEnabled);
  };

  const handleOpenPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const handleClosePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  return (
    <Box>
      <Paper elevation={0} sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={handleOpenPasswordModal}>
        <Typography variant="subtitle1">Alterar Senha</Typography>
        <IconButton edge="end" aria-label="alterar senha">
          <ArrowForwardIosIcon />
        </IconButton>
      </Paper>

      <FormControlLabel
        control={<Switch checked={twoFAEnabled} onChange={handleTwoFAToggle} />}
        label="Habilitar Autenticação de Dois Fatores"
        sx={{ mt: 2, display: 'block' }}
      />

      <ChangePasswordModal
        open={isPasswordModalOpen}
        onClose={handleClosePasswordModal}
      />
    </Box>
  );
};

export default AccessSecurity;