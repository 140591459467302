import { Chip, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNotificationStore } from '../../stores/notification/useNotificationStore';
import { keycloak } from '../../config/keycloak';
import { ConnectionState } from '../../services/NotificationEventService';

export const FloatingStatus: React.FC = () => {
    const connectionState = useNotificationStore(state => state.connectionState);
    const initialize = useNotificationStore(state => state.initialize);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (keycloak.authenticated) {
            setShow(true);
            if (connectionState === ConnectionState.Connected) {
                setShow(true);
                const timer = setTimeout(() => setShow(false), 1000);
                return () => clearTimeout(timer);
            }
        }
    }, [connectionState]);

    if (!show) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000
            }}
        >
            <Chip
                label={connectionState}
                color={connectionState === 'Connected' ? 'success' : 'warning'}
                size="small"
                onDelete={async () => {
                    if (connectionState === ConnectionState.Disconnected) {
                        const token = await keycloak.token;
                        await initialize(token);
                    } else {
                        setShow(false);
                    }
                }}
            />
        </Box>
    );
};