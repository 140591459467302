import React from 'react';
import { Box, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';

interface CourseDurationProps {
  duration: string;
}

const CourseDuration: React.FC<CourseDurationProps> = ({ duration }) => (
  <Box display="flex" alignItems="center">
    <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
    <Typography variant="body2" color="text.secondary">
      {duration}
    </Typography>
  </Box>
);

export default CourseDuration;