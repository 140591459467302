import React, { useEffect, useState, useCallback } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, useTheme } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { 
  Elements, 
  CardNumberElement, 
  CardExpiryElement, 
  CardCvcElement,
  useStripe, 
  useElements 
} from '@stripe/react-stripe-js';
import { useCreditCardStore } from '../../../stores/creditCard/useCreditCardStore';
import { GatewayFormProps, GatewayCardChangeEvent } from './types';
import { CardDetails, PaymentResponse } from '../../../types/payment/index';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export interface StripePaymentHandler {
  handlePaymentAction: (response: PaymentResponse) => Promise<void>;
}

const StripeCardContent: React.FC<Omit<GatewayFormProps, 'pubkey'>> = ({ 
  onFormComplete, 
  onTokenGenerated,
  onFocus,
  onBlur
}) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [complete, setComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [elementComplete, setElementComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  });
  const { cardDetails, setCardDetails, validation, formErrors } = useCreditCardStore();

  const cardElementStyle = {
    base: {
      fontSize: '16px',
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
      WebkitAutofill: {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent'
      },
      '::placeholder': {
        color: theme.palette.text.secondary,
      },
      ':-webkit-autofill': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
      },
      padding: '16.5px 14px',
      iconColor: theme.palette.text.primary,
    },
    invalid: {
      color: theme.palette.error.main,
      iconColor: theme.palette.error.main,
    },
  };

  const cardElementOptions = {
    style: cardElementStyle,
    showIcon: true,
  };

  useEffect(() => {
    onFormComplete(complete);
  }, [complete, onFormComplete]);

  const handleCardNumberChange = (event: any) => {
    setElementComplete(prev => ({ ...prev, cardNumber: event.complete }));
    const changeEvent: GatewayCardChangeEvent = {
      complete: event.complete,
      brand: event.brand,
      error: event.error?.message,
      last4: event.value?.postalCode
    };

    // Simula um número de cartão mascarado
    const maskedNumber = event.empty 
      ? '' 
      : `${event.value?.postalCode ? `•••• •••• •••• ${event.value.postalCode}` : '•••• •••• •••• ••••'}`;

    const partialDetails: Partial<CardDetails> = {
      cardNumber: maskedNumber,
    };
    
    if (changeEvent.brand) {
      partialDetails.brand = changeEvent.brand;
    }
    
    setCardDetails(partialDetails);
  };

  const handleExpiryChange = (event: any) => {
    setElementComplete(prev => ({ ...prev, cardExpiry: event.complete }));
    if (event.complete && event.value) {
      const { month, year } = event.value;
      setCardDetails({ 
        expiry: `${month.toString().padStart(2, '0')}/${year.toString().slice(-2)}`
      });
    }
  };

  const handleCvcChange = (event: any) => {
    setElementComplete(prev => ({ ...prev, cardCvc: event.complete }));
    if (event.complete && event.value) {
      setCardDetails({ cvv: event.value });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if (name === 'cpfCnpj') {
      const cleaned = value.replace(/\D/g, '');
      let masked = cleaned;
      
      // Aplica máscara de CPF ou CNPJ
      if (cleaned.length <= 11) {
        // CPF: 000.000.000-00
        masked = cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
                       .replace(/(\d{3})(\d{3})(\d{3})/g, '$1.$2.$3')
                       .replace(/(\d{3})(\d{3})/g, '$1.$2')
                       .replace(/(\d{3})/g, '$1');
      } else {
        // CNPJ: 00.000.000/0000-00
        masked = cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
                       .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '$1.$2.$3/$4')
                       .replace(/(\d{2})(\d{3})(\d{3})/g, '$1.$2.$3')
                       .replace(/(\d{2})(\d{3})/g, '$1.$2')
                       .replace(/(\d{2})/g, '$1');
      }
      
      setCardDetails({ [name]: masked });
    } else {
      setCardDetails({ [name]: value });
    }
  };

  const getHelperText = (fieldName: string): string => {
    if (fieldName === 'fullName' && cardDetails.fullName && !validation.fullName) {
      return 'Digite o nome completo';
    }
    if (fieldName === 'cpfCnpj' && cardDetails.cpfCnpj && !validation.cpfCnpj) {
      return 'CPF/CNPJ inválido';
    }
    if (fieldName === 'birthDate' && cardDetails.birthDate && !validation.birthDate) {
      return 'Você deve ter entre 18 e 100 anos';
    }
    return '';
  };

  const hasError = (fieldName: string): boolean => {
    if (fieldName === 'fullName') {
      return !!cardDetails.fullName && !validation.fullName;
    }
    if (fieldName === 'cpfCnpj') {
      return !!cardDetails.cpfCnpj && !validation.cpfCnpj;
    }
    if (fieldName === 'birthDate') {
      return !!cardDetails.birthDate && !validation.birthDate;
    }
    return false;
  };

  const checkFormCompletion = useCallback(() => {
    const isStripeComplete = elementComplete.cardNumber && 
                            elementComplete.cardExpiry && 
                            elementComplete.cardCvc;

    const isFormComplete = isStripeComplete && 
                          validation.fullName && 
                          validation.cpfCnpj && 
                          validation.birthDate;

    onFormComplete(isFormComplete);
  }, [elementComplete, validation, onFormComplete]);

  useEffect(() => {
    checkFormCompletion();
  }, [checkFormCompletion]);

  const handlePaymentAction = async (response: PaymentResponse) => {
    if (!stripe) throw new Error('Stripe not initialized');

    try {
      setIsProcessing(true);

      if (response.status === 'WaitingConfirmation') {
        switch (response.action) {
          case 'RedirectToUrl':
            if (response.redirectUrl) {
              window.location.href = response.redirectUrl;
            }
            break;

          case 'Secure3D':
            const { error: confirmError } = await stripe.confirmCardPayment(response.clientSecret);
            if (confirmError) {
              throw new Error(confirmError.message);
            }
            break;

          default:
            throw new Error('Unknown payment action');
        }
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const generateToken = useCallback(async () => {
    if (!stripe || !elements) return null;

    const cardNumber = elements.getElement(CardNumberElement);
    if (!cardNumber) return null;

    const { token, error } = await stripe.createToken(cardNumber, {
      name: cardDetails.fullName,
    });

    if (error) {
      throw new Error(error.message);
    }

    return token?.id || null;
  }, [stripe, elements, cardDetails.fullName]);

  useEffect(() => {
    const handler: StripePaymentHandler = {
      handlePaymentAction
    };
    onTokenGenerated(generateToken, handler);
  }, [generateToken, handlePaymentAction, onTokenGenerated]);

  if (isProcessing) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '400px' 
      }}>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Box component="form" autoComplete="off" sx={{ width: '100%' }}>
      <Box sx={{ 
        colorScheme: 'none !important',
        '& .StripeElement': { 
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          padding: '16.5px 14px',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          colorScheme: 'none !important',
          '&--focus': {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
          },
          '&--invalid': {
            borderColor: theme.palette.error.main,
          },
        }
      }}>
        <Box sx={{ mb: 2 }}>
          <InputLabel shrink>Número do Cartão</InputLabel>
          <CardNumberElement 
            options={cardElementOptions}
            onChange={handleCardNumberChange}
            onFocus={() => onFocus?.('cardNumber')}
            onBlur={onBlur}
          />
        </Box>

        <TextField
          fullWidth
          label="Nome no Cartão"
          name="fullName"
          value={cardDetails.fullName}
          onChange={handleInputChange}
          onFocus={() => onFocus?.('fullName')}
          onBlur={onBlur}
          margin="normal"
          required
          error={hasError('fullName')}
          helperText={getHelperText('fullName')}
          autoComplete="off"
        />

        <Box display="flex" gap={2} sx={{ mb: 2 }}>
          <Box flex={1}>
            <InputLabel shrink>Data de Expiração</InputLabel>
            <CardExpiryElement 
              options={cardElementOptions}
              onChange={handleExpiryChange}
              onFocus={() => onFocus?.('expiry')}
              onBlur={onBlur}
            />
          </Box>

          <Box flex={1}>
            <InputLabel shrink>CVV</InputLabel>
            <CardCvcElement 
              options={cardElementOptions}
              onChange={handleCvcChange}
              onFocus={() => onFocus?.('cvv')}
              onBlur={onBlur}
            />
          </Box>
        </Box>

        <TextField
          fullWidth
          label="CPF/CNPJ"
          name="cpfCnpj"
          value={cardDetails.cpfCnpj}
          onChange={handleInputChange}
          onFocus={() => onFocus?.('cpfCnpj')}
          onBlur={onBlur}
          margin="normal"
          required
          error={hasError('cpfCnpj')}
          helperText={getHelperText('cpfCnpj')}
          inputProps={{
            maxLength: 18,
          }}
          autoComplete="off"
        />

        <TextField
          fullWidth
          label="Data de Nascimento"
          name="birthDate"
          type="date"
          value={cardDetails.birthDate}
          onChange={handleInputChange}
          margin="normal"
          required
          error={hasError('birthDate')}
          helperText={getHelperText('birthDate')}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: new Date().toISOString().split('T')[0],
          }}
          autoComplete="off"
        />
      </Box>
    </Box>
  );
};

const StripeGateway: React.FC<GatewayFormProps> = ({ pubkey, ...props }) => {
  const [stripePromise] = useState(() => loadStripe(pubkey));

  return (
    <Elements stripe={stripePromise}>
      <StripeCardContent {...props} />
    </Elements>
  );
};

export default StripeGateway;
