import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Paper, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { useOrdersStore } from '../../stores/order/useOrdersStore';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { EOrderStatus, EPaymentMethod, Order, PaymentStatus } from '../../types/payment';
import { MyOrder } from '../../types/order';

const formatCurrency = (value: number, currency: string = 'BRL') => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency
  }).format(value);
};

const getOrderStatusColor = (status: EOrderStatus) => {
  switch (status) {
    case EOrderStatus.Completed:
      return 'success';
    case EOrderStatus.Pending:
    case EOrderStatus.Canceled:
      return 'error';
    case EOrderStatus.Processing:
      return 'info';
    case EOrderStatus.Refunded:
      return 'warning';
    default:
      return 'default';
  }
};

const getPaymentStatusColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Completed:
      return 'success';
    case PaymentStatus.Pending:
    case PaymentStatus.WaitingConfirmation:
      return 'warning';
    case PaymentStatus.Processing:
      return 'info';
    case PaymentStatus.Failed:
      return 'error';
    default:
      return 'default';
  }
};

const getOrderStatusLabel = (orderStatus: EOrderStatus) => {
  switch (orderStatus) {
    case EOrderStatus.Completed:
      return 'Concluído';
    case EOrderStatus.Pending:
      return 'Pendente';
    case EOrderStatus.Canceled:
      return 'Cancelado';
    case EOrderStatus.Processing:
      return 'Processando';
    case EOrderStatus.Refunded:
      return 'Estornado';
    default:
      return orderStatus;
  }
};

const getPaymentStatusLabel = (paymentStatus: PaymentStatus) => {
  switch (paymentStatus) {
    case PaymentStatus.Completed:
      return 'Concluído';
    case PaymentStatus.Pending:
      return 'Pendente';
    case PaymentStatus.WaitingConfirmation:
      return 'Aguardando Confirmação';
    case PaymentStatus.Processing:
      return 'Processando';
    case PaymentStatus.Failed:
      return 'Falhou';
    default:
      return 'Não Iniciado';
  }
};

const getPaymentMethodLabel = (paymentMethod: string) => {
  if(!paymentMethod)
    return 'Desconhecido';

  switch (paymentMethod) {
    case EPaymentMethod.CreditCard:
      return 'Cartão de Crédito';
    case EPaymentMethod.Boleto:
      return 'Boleto';
    case EPaymentMethod.Pix:
      return 'Pix';
    case EPaymentMethod.PayPal:
      return 'PayPal';
    case EPaymentMethod.GooglePay:
      return 'Google Pay';
    case EPaymentMethod.ApplePay:
      return 'Apple Pay';
    case EPaymentMethod.NuPay:
      return 'NuPay';
    case EPaymentMethod.Crypto:
      return 'Crypto';
    default:
      return paymentMethod;
  }
};

interface OrderDetailsDialogProps {
  order: MyOrder;
  open: boolean;
  onClose: () => void;
}

const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = ({ order, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Detalhes do Pedido</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemText 
              primary="Curso" 
              secondary={order.courseName} 
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText 
              primary="Data do Pedido" 
              secondary={format(new Date(order.createdAt), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR })} 
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText 
              primary="Valor" 
              secondary={
                <>
                  {formatCurrency(order.amount)}
                  {order.couponCode && (
                    <Typography variant="caption" color="text.secondary" display="block">
                      Cupom aplicado: {order.couponCode}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText 
              primary="Status do Pagamento" 
              secondary={
                <Chip 
                  label={getPaymentStatusLabel(order.paymentStatus)}
                  color={getPaymentStatusColor(order.paymentStatus)}
                  size="small"
                />
              }
            />
          </ListItem>
          <Divider />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export const Orders: React.FC = () => {
  const { orders, isLoading, error, page, pageSize, total, fetchOrders } = useOrdersStore();
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = React.useState<MyOrder | null>(null);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuOrder, setMenuOrder] = React.useState<MyOrder | null>(null);

  React.useEffect(() => {
    fetchOrders();
  }, []);

  const handleChangePage = (_: unknown, newPage: number) => {
    fetchOrders({ page: newPage + 1, pageSize });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = parseInt(event.target.value, 10);
    fetchOrders({ page: 1, pageSize: newPageSize });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, order: MyOrder) => {
    setAnchorEl(event.currentTarget);
    setMenuOrder(order);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOrder(null);
  };

  const handleViewDetails = () => {
    if (menuOrder) {
      setSelectedOrder(menuOrder);
      setDetailsOpen(true);
    }
    handleMenuClose();
  };

  const handleViewStatus = () => {
    if (menuOrder) {
      navigate(`/order-status/${menuOrder.id}`);
    }
    handleMenuClose();
  };

  if (isLoading && orders.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Meus Pedidos
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Paper elevation={3}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Curso</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Order Status</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id} hover>
                    <TableCell>{order.courseName}</TableCell>
                    <TableCell>
                      {format(new Date(order.createdAt), "dd/MM/yyyy", { locale: ptBR })}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(order.amount)}
                      {order.couponCode && (
                        <Typography variant="caption" color="text.secondary" display="block">
                          Cupom: {order.couponCode}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={getOrderStatusLabel(order.status)}
                        color={getOrderStatusColor(order.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={getPaymentMethodLabel(order.paymentMethod)}
                        color={'info'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={getPaymentStatusLabel(order.paymentStatus)}
                        color={getPaymentStatusColor(order.paymentStatus)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Mais ações">
                        <IconButton
                          onClick={(e) => handleMenuClick(e, order)}
                          size="small"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={total}
            page={page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage="Itens por página"
          />
        </Paper>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleViewDetails}>
            <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />
            Ver Detalhes
          </MenuItem>
          <MenuItem onClick={handleViewStatus}>
            <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />
            Ver Status
          </MenuItem>
        </Menu>

        {selectedOrder && (
          <OrderDetailsDialog
            order={selectedOrder}
            open={detailsOpen}
            onClose={() => {
              setDetailsOpen(false);
              setSelectedOrder(null);
            }}
          />
        )}
      </Box>
    </Container>
  );
};
