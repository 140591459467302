import React from 'react';
import { Grid } from '@mui/material';
import CourseCard from '../CourseCard/CourseCard';
import EmptyContent from '../EmptyContent/EmptyContent';
import { MyCourse } from '../../types/course';

interface InProgressCoursesProps {
  courses: MyCourse[];
}

const InProgressCourses: React.FC<InProgressCoursesProps> = ({ courses }) => {
  if (courses.length === 0) {
    return <EmptyContent message="Você não tem cursos em andamento no momento." />;
  }

  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      {courses.map((course) => (
        <Grid item xs={12} sm={6} md={4} key={course.courseId}>
          <CourseCard 
            course={course.course} 
            showProgress 
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InProgressCourses;