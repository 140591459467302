import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Divider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DownloadIcon from '@mui/icons-material/GetApp';
import { useKeycloak } from '@react-keycloak/web';

interface CertificateInfo {
  courseName: string;
  instructor: string;
  issuer: string;
  completionDate: string;
  validationCode: string;
  issuerWebsite: string;
  courseImage: string;
}

interface CertificateModalProps {
  open: boolean;
  onClose: () => void;
  certificateInfo: CertificateInfo;
}

const CertificateModal: React.FC<CertificateModalProps> = ({ open, onClose, certificateInfo }) => {
  const { keycloak } = useKeycloak();
  const userName = keycloak.tokenParsed?.name || 'Aluno';

  const handleLinkedInAdd = () => {
    // Implementar lógica para adicionar ao LinkedIn
    console.log('Adicionando certificado ao LinkedIn');
  };

  const handleDownload = () => {
    // Implementar lógica para download do certificado
    console.log('Baixando certificado');
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 0 }}>Certificado de Conclusão</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img 
            src={certificateInfo.courseImage} 
            alt="Imagem do curso" 
            style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }} 
          />
        </Box>
        <Typography variant="h6" align="center" gutterBottom>
          {certificateInfo.courseName}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ py: 1 }}>
          <Typography variant="body1" paragraph>
            Este certificado é concedido a {userName} por concluir com sucesso o curso acima mencionado.
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ py: 1 }}>
          <Typography variant="body2">
            Instrutor: {certificateInfo.instructor}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ py: 1 }}>
          <Typography variant="body2">
            Emitido por: {certificateInfo.issuer}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ py: 1 }}>
          <Typography variant="body2">
            Data de Conclusão: {certificateInfo.completionDate}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ py: 1 }}>
          <Typography variant="body2">
            Código de Validação: {certificateInfo.validationCode}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ py: 1 }}>
          <Typography variant="body2">
            Para verificar a autenticidade deste certificado, visite: {certificateInfo.issuerWebsite}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
        <Box>
          <Button 
            onClick={handleLinkedInAdd} 
            color="primary" 
            variant="contained" 
            startIcon={<LinkedInIcon />}
            sx={{ mr: 1 }}
          >
            Adicionar ao LinkedIn
          </Button>
          <Button 
            onClick={handleDownload} 
            color="primary" 
            variant="contained" 
            startIcon={<DownloadIcon />}
          >
            Baixar Certificado
          </Button>
        </Box>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CertificateModal;