import React from 'react';
import { Typography, Box, Rating, Chip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UpdateIcon from '@mui/icons-material/Update';
import LanguageIcon from '@mui/icons-material/Language';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import { CourseDetails } from '../../types/course';


interface CoursePurchaseHeaderProps {
  courseDetails: CourseDetails;
}

const CoursePurchaseHeader: React.FC<CoursePurchaseHeaderProps> = ({ courseDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!courseDetails) {
    return null;
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom>
        {courseDetails.title || 'Título não disponível'}
      </Typography>
      <Typography variant="body1" paragraph>
        {courseDetails.description || 'Descrição não disponível'}
      </Typography>
      <Box display="flex" alignItems="center" mb={1} flexWrap="wrap">
        <Rating value={courseDetails.rating || 0} readOnly precision={0.5} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          ({courseDetails.ratingCount || 0} avaliações)
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Criado por <strong>{courseDetails.instructor || 'Instrutor não disponível'}</strong>
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
        <Chip size={isMobile ? "small" : "medium"} icon={<UpdateIcon />} label={`Última atualização: ${courseDetails.lastUpdated ? new Date(courseDetails.lastUpdated).toLocaleDateString() : 'Data não disponível'}`} />
        <Chip size={isMobile ? "small" : "medium"} icon={<LanguageIcon />} label={`Idiomas: ${courseDetails.languages ? courseDetails.languages.join(', ') : 'Não especificado'}`} />
        <Chip size={isMobile ? "small" : "medium"} icon={<ClosedCaptionIcon />} label={`Legendas: ${courseDetails.subtitles ? courseDetails.subtitles.join(', ') : 'Não especificado'}`} />
      </Box>
    </Box>
  );
};

export default CoursePurchaseHeader;