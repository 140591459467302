import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { keycloak } from '../config/keycloak';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getKeycloakToken = async (): Promise<string | null> => {
  if (keycloak && keycloak.authenticated) {
    try {
      await keycloak.updateToken(5);
      return keycloak.token;
    } catch (error) {
      console.error('Erro ao atualizar o token Keycloak:', error);
      keycloak.logout();
      throw error;
    }
  }
  return null;
};

const renewToken = async (): Promise<string> => {
  const keycloakToken = await getKeycloakToken();
  if (keycloakToken) {
    return keycloakToken;
  }

  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    try {
      const response = await axios.post<{ token: string }>('/api/refresh-token', { refreshToken });
      const { token } = response.data;
      localStorage.setItem('token', token);
      return token;
    } catch (error) {
      console.error('Erro ao renovar o token:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      throw error;
    }
  }
  throw new Error('Nenhum refresh token disponível');
};

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await getKeycloakToken() || localStorage.getItem('token');
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await renewToken();
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return api(originalRequest);
      } catch (renewError) {
        if (keycloak && keycloak.authenticated) {
          keycloak.logout();
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.href = '/login';
        }
        return Promise.reject(renewError);
      }
    }
    return Promise.reject(error);
  }
);

interface ApiMethods {
  get: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  delete: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  patch: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
}

const apiMethods: ApiMethods = {
  get: (url, config = {}) => api.get(url, config),
  post: (url, data, config = {}) => api.post(url, data, config),
  put: (url, data, config = {}) => api.put(url, data, config),
  delete: (url, config = {}) => api.delete(url, config),
  patch: (url, data, config = {}) => api.patch(url, data, config),
};

export default apiMethods;