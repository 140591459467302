import React from 'react';
import { 
  ListItemButton, 
  ListItemIcon, 
  Typography, 
  Box 
} from '@mui/material';
import { CheckCircle, RadioButtonUnchecked, PlayCircleOutline } from '@mui/icons-material';
import { CourseStatus } from '../../../types/course';

interface ModuleItemProps {
  title: string;
  duration: string;
  status: CourseStatus;
  onClick: () => void;
  isSelected: boolean;
}

const ModuleItem: React.FC<ModuleItemProps> = ({ title, duration, status, onClick, isSelected }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'Completed':
        return <CheckCircle color="success" />;
      case 'InProgress':
        return <PlayCircleOutline color="primary" />;
      default:
        return <RadioButtonUnchecked color="action" />;
    }
  };

  return (
    <ListItemButton 
      onClick={onClick}
      selected={isSelected}
      sx={{ 
        py: 3,
        '&:hover': { 
          backgroundColor: 'action.hover',
        },
        '&.Mui-selected': {
          backgroundColor: 'primary.light',
          '&:hover': {
            backgroundColor: 'primary.light',
          },
        },
      }}
    >
      <ListItemIcon>
        {React.cloneElement(getStatusIcon(), { fontSize: 'medium' })}
      </ListItemIcon>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        width: '100%'
      }}>
        <Typography 
          variant="subtitle1" 
          color={isSelected ? 'primary.main' : 'text.primary'}
          sx={{ fontWeight: isSelected ? 'bold' : 'normal' }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ({duration})
        </Typography>
      </Box>
    </ListItemButton>
  );
}

export default ModuleItem;