import React, { useCallback, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNotificationStore } from '../stores/notification/useNotificationStore';
import LoadScreen from '../components/LoadScreen/LoadScreen';

interface RealtimeProviderProps {
    children: React.ReactNode;
}

export const RealtimeProvider: React.FC<RealtimeProviderProps> = ({ children }) => {
    const { keycloak, initialized } = useKeycloak();
    const { 
        initialize, 
        disconnect, 
        updateToken, 
        isLoading,
    } = useNotificationStore();

    const handleTokenRefresh = useCallback(async () => {
        try {
            const refreshed = await keycloak.updateToken(70);
            if (refreshed && keycloak.token) {
                console.log('Token atualizado');
                await updateToken(keycloak.token);
            }
        } catch (error) {
            console.error('Falha ao atualizar token:', error);
            await keycloak.logout();
        }
    }, [keycloak, updateToken]);

    useEffect(() => {
        let mounted = true;

        const setupNotifications = async () => {
            if (!mounted || !initialized || !keycloak.authenticated || !keycloak.token) {
                return;
            }

            try {
                await initialize(keycloak.token);
                if (mounted) {
                    keycloak.onTokenExpired = () => {
                        handleTokenRefresh();
                    };
                }
            } catch (error) {
                console.error('Falha ao inicializar notificações:', error);
            }
        };

        setupNotifications();

        return () => {
            mounted = false;
            keycloak.onTokenExpired = undefined;
            disconnect();
        };
    }, [initialized, keycloak.authenticated, keycloak.token, disconnect, handleTokenRefresh, initialize, keycloak]);

    if (!initialized || isLoading) {
        return <LoadScreen />;
    }

    return <>{children}</>;
};