import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

interface CourseHeaderProps {
  title: string;
  instructor: string;
}

const CourseHeader: React.FC<CourseHeaderProps> = ({ title, instructor }) => (
  <AppBar position="static" color="primary">
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
      <Typography variant="subtitle1">
        Instrutor: {instructor}
      </Typography>
    </Toolbar>
  </AppBar>
);

export default CourseHeader;