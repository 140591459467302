import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardMedia, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CoursePrice from './CoursePrice';
import CouponInput from './CouponInput';
import ActionButton from '../Buttons/ActionButton';
import { useCheckoutStore } from '../../stores/checkout/checkoutStore';

/**
 * CoursePurchaseSidebar Component
 * 
 * Este componente exibe um sidebar com informações de compra do curso,
 * incluindo preço, desconto, entrada de cupom e botão de compra.
 * 
 * Funcionalidades:
 * - Exibição da imagem do curso
 * - Exibição do preço e desconto do curso
 * - Integração com o componente CouponInput para aplicação de cupons
 * - Botão de compra que redireciona para a página de checkout
 * - Funcionalidade de adicionar/remover da lista de desejos (não implementada completamente)
 * 
 * @returns {React.ReactElement} O componente CoursePurchaseSidebar renderizado
 */

const CoursePurchaseSidebar: React.FC = () => {
  const [isWishlisted, setIsWishlisted] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { course, appliedCoupon, calculateTotal } = useCheckoutStore();

  const handleAddToWishlist = () => {
    setIsWishlisted(!isWishlisted);
    console.log(isWishlisted ? 'Removendo da lista de desejos' : 'Adicionando à lista de desejos');
  };

  const handleCheckout = () => {
    navigate(`/checkout/${course?.id}`, {
      state: {
        appliedCoupon,
        courseDetails: course,
        finalPrice: calculateTotal()
      }
    });
  };

  if (!course) {
    return null;
  }

  return (
    <Card sx={{ position: isMobile ? 'static' : 'sticky', top: 20, bgcolor: theme.palette.background.paper }}>
      <CardMedia
        component="img"
        height={isMobile ? "150" : "200"}
        image={course.imageUrl}
        alt={course.title}
      />
      <Box sx={{ p: 2 }}>
        <CoursePrice
          originalPrice={course.price}
          finalPrice={calculateTotal()}
          discount={course.discount}
          isWishlisted={isWishlisted}
          onWishlistToggle={handleAddToWishlist}
        />
        <Divider sx={{ my: 2 }} />
        <CouponInput />
        <ActionButton
          text="Comprar agora"
          onClick={handleCheckout}
          sx={{ mb: 2, mt: 2 }}
        />
      </Box>
    </Card>
  );
};

export default CoursePurchaseSidebar;