import { useState } from 'react';

export interface Address {
  line1: string; // Rua e número
  complement?: string;
  city: string;
  state: string;
  postalCode: string;
}

export const useAddressSearch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const searchAddress = async (cep: string): Promise<Address | null> => {
    try {
      setLoading(true);
      setError(null);
      
      const cleanCep = cep.replace(/\D/g, '');
      
      if (cleanCep.length !== 8) {
        throw new Error('CEP inválido');
      }

      const response = await fetch(`https://viacep.com.br/ws/${cleanCep}/json/`);
      const data = await response.json();

      if (data.erro) {
        throw new Error('CEP não encontrado');
      }

      return {
        line1: data.logradouro,
        city: data.localidade,
        state: data.uf,
        postalCode: data.cep.replace(/\D/g, '')
      };
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erro ao buscar endereço');
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    searchAddress,
    loading,
    error
  };
};
