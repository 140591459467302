import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { ModuleDetails } from '../../types/course';



interface CourseContentProps {
  modules: ModuleDetails[];
}

/**
 * CourseContent Component
 * 
 * This component displays the content of a course, organized into modules and lessons.
 * 
 * @param {CourseContentProps} props - The props for the CourseContent component
 * @returns {React.ReactElement} The rendered CourseContent component
 */
const CourseContent: React.FC<CourseContentProps> = ({ modules }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>Conteúdo do curso</Typography>
      {(modules || []).map((module) => (
        <Accordion key={module.position} defaultExpanded={module.position === 0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${module.position}a-content`}
            id={`panel${module.position}a-header`}
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', color: 'white', fontSize: isMobile ? '0.9rem' : '1rem' }}>
              {module.title || `Módulo ${module.position + 1}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense={isMobile}>
              {(module.lessons || []).map((lesson) => (
                <ListItem key={lesson.position}>
                  <ListItemIcon><OndemandVideoIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
                  <ListItemText 
                    primary={lesson.title || `Aula ${lesson.position + 1}`} 
                    primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CourseContent;