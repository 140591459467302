import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import AnimatedCreditCard from './AnimatedCreditCard';
import StripeGateway from './gateways/StripeGateway';
import { useCreditCardStore } from '../../stores/creditCard/useCreditCardStore';
import { CardDetails, PaymentGateway } from '../../types/payment/index';

interface GatewayCardProps {
  isMobile: boolean;
  isTablet: boolean;
  gateway: PaymentGateway;
  pubkey: string;
  onFormComplete: (isComplete: boolean) => void;
  onTokenGenerated: (tokenGenerator: () => Promise<string | null>) => void;
}

const GatewayCard: React.FC<GatewayCardProps> = ({
  isMobile,
  isTablet,
  gateway,
  pubkey,
  onFormComplete,
  onTokenGenerated
}) => {
  const [focusedField, setFocusedField] = useState<keyof CardDetails | null>(null);
  const { cardDetails } = useCreditCardStore();

  const handleInputFocus = (field: keyof CardDetails) => {
    setFocusedField(field);
  };

  const handleInputBlur = () => {
    setFocusedField(null);
  };

  const renderGatewayForm = () => {
    switch (gateway) {
      case 'Stripe':
        return (
          <StripeGateway
            pubkey={pubkey}
            onFormComplete={onFormComplete}
            onTokenGenerated={onTokenGenerated}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        );
      // Adicione outros gateways aqui
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
        <Grid item xs={12} md={isMobile || isTablet ? 12 : 6}>
          <Box sx={{ mb: isMobile ? 2 : 0 }}>
            <AnimatedCreditCard 
              cardDetails={cardDetails} 
              focusedField={focusedField} 
              scale={isMobile ? 0.8 : isTablet ? 0.9 : 1}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={isMobile || isTablet ? 12 : 6}>
          {renderGatewayForm()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GatewayCard;
