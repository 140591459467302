import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import LoadScreen from './components/LoadScreen/LoadScreen';
import TechBackground from './components/TechBackground/TechBackground';
import theme from './theme';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak, initOptions } from './config/keycloak';
import GlobalStyle from './styles/GlobalStyle';
import Footer from './components/Footer/Footer';
import AppRoutes from './Routes/Routes';
import { RealtimeProvider } from './providers/RealtimeProvider';
import { FloatingStatus } from './components/ConnectionStatus/FloatingStatus';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Simular um tempo de carregamento
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
      <ThemeProvider theme={theme}>
        <RealtimeProvider>
          <CssBaseline />
          {isLoading ? (
            <LoadScreen />
          ) : (
            <BrowserRouter>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <TechBackground />
                <Navbar />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    pt: 12,
                    overflow: 'auto',
                  }}
                >
                  <AppRoutes />
                </Box>
                <Footer />
              </Box>
            </BrowserRouter>
          )}
        </RealtimeProvider>
      </ThemeProvider>
      <GlobalStyle />
      <FloatingStatus />
    </ReactKeycloakProvider>
  );
};

export default App;