import React from 'react';
import { Box } from '@mui/material';
import LoggedInMenu from '../LoggedInMenu';

const AuthenticatedNav: React.FC = () => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <LoggedInMenu />
  </Box>
);

export default AuthenticatedNav;