import api from '../api/api';
import { CarouselData, WelcomeData } from '../types/platform';

interface HomePageData {
  welcome: WelcomeData;
  items: CarouselData[];
}

export const fetchHomePageData = async (): Promise<HomePageData> => {
  try {
    const response = await api.get<HomePageData>('/api/v1/platform/marketing-banner');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados da página inicial:', error);
    throw error;
  }
};