import React from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  justifyContent: 'space-between',
  paddingRight: theme.spacing(2),
}));

interface MobileDrawerProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ mobileOpen, handleDrawerToggle }) => {
  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, color: 'primary.main' }}>
        Menu
      </Typography>
      <Divider />
      <List>
        <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledListItem onClick={handleDrawerToggle}>
            <ListItemText primary="Entrar" />
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
          </StyledListItem>
        </Link>
        <Divider variant="inset" component="li" />
        <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
          <StyledListItem onClick={handleDrawerToggle}>
            <ListItemText primary="Registrar" />
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <PersonAddIcon color="primary" />
            </ListItemIcon>
          </StyledListItem>
        </Link>
      </List>
    </Box>
  );

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { 
          boxSizing: 'border-box', 
          width: 240,
          backgroundColor: 'background.default',
          color: 'text.primary',
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default MobileDrawer;