import React from 'react';
import { Typography, Box } from '@mui/material';
import { WelcomeData } from '../../types/platform';

interface WelcomeSectionProps {
  userName?: string;
  welcomeData: WelcomeData;
}

const WelcomeSection: React.FC<WelcomeSectionProps> = ({ userName, welcomeData }) => {
  return (
    <Box sx={{ mb: 6 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        {userName
          ? `Bem-vindo(a) de volta, ${userName}`
          : welcomeData.title}
      </Typography>
    </Box>
  );
};

export default WelcomeSection;