import React, { useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Typography, Box } from '@mui/material';

interface NotificationSettingsState {
  platformMarketing: boolean;
  platformAnnouncements: boolean;
  platformAccount: boolean;
  emailMarketing: boolean;
  emailAnnouncements: boolean;
  emailAccount: boolean;
  whatsappMarketing: boolean;
  whatsappAnnouncements: boolean;
  whatsappAccount: boolean;
}

const NotificationSettings: React.FC = () => {
  const [settings, setSettings] = useState<NotificationSettingsState>({
    platformMarketing: true,
    platformAnnouncements: true,
    platformAccount: true,
    emailMarketing: false,
    emailAnnouncements: true,
    emailAccount: true,
    whatsappMarketing: false,
    whatsappAnnouncements: false,
    whatsappAccount: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>Notificações na Plataforma</Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={settings.platformMarketing} onChange={handleChange} name="platformMarketing" />}
          label="Marketing"
        />
        <FormControlLabel
          control={<Checkbox checked={settings.platformAnnouncements} onChange={handleChange} name="platformAnnouncements" />}
          label="Anúncios"
        />
        <FormControlLabel
          control={<Checkbox checked={settings.platformAccount} onChange={handleChange} name="platformAccount" />}
          label="Conta"
        />
      </FormGroup>

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Notificações por E-mail</Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={settings.emailMarketing} onChange={handleChange} name="emailMarketing" />}
          label="Marketing"
        />
        <FormControlLabel
          control={<Checkbox checked={settings.emailAnnouncements} onChange={handleChange} name="emailAnnouncements" />}
          label="Anúncios"
        />
        <FormControlLabel
          control={<Checkbox checked={settings.emailAccount} onChange={handleChange} name="emailAccount" />}
          label="Conta"
        />
      </FormGroup>

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Notificações por WhatsApp</Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={settings.whatsappMarketing} onChange={handleChange} name="whatsappMarketing" />}
          label="Marketing"
        />
        <FormControlLabel
          control={<Checkbox checked={settings.whatsappAnnouncements} onChange={handleChange} name="whatsappAnnouncements" />}
          label="Anúncios"
        />
        <FormControlLabel
          control={<Checkbox checked={settings.whatsappAccount} onChange={handleChange} name="whatsappAccount" />}
          label="Conta"
        />
      </FormGroup>
    </Box>
  );
};

export default NotificationSettings;