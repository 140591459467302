import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
}));

const Logo = styled('img')(({ theme }) => ({
    width: 150,
    height: 'auto',
    marginBottom: theme.spacing(3),
    animation: 'pulse 2s infinite ease-in-out'
  }));

const LoadingText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const LoadScreen: React.FC = () => {
  return (
    <LoadingContainer>
      <Logo src="/logo.svg" alt="MicroStack Logo" />
      <CircularProgress size={60} thickness={4} />
      <LoadingText variant="h6">
        Carregando MicroStack...
      </LoadingText>
    </LoadingContainer>
  );
}

export default LoadScreen;