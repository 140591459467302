import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import { getOrderDetails } from '../../services/orderService';
import OrderConfirmation from '../../components/Order/OrderConfirmation';
import OrderPending from '../../components/Order/OrderPending';
import OrderBoletoPending from '../../components/Order/OrderBoletoPending';
import OrderRejection from '../../components/Order/OrderRejection';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import { EPaymentMethod, Order, PaymentStatus } from '../../types/payment/index';
import { StripePaymentHandler } from '../../components/CreditCard/gateways/StripeGateway';

/**
 * Interface para o estado da localização
 */
interface LocationState {
    status?: string;
    orderId?: string;
}

/**
 * Página OrderStatus
 * 
 * Esta página exibe o status atual do pedido e renderiza o componente apropriado
 * com base no status (confirmado, pendente ou rejeitado).
 * 
 * Props:
 * - Nenhuma (usa useParams para obter o orderId da URL)
 * 
 * Estado:
 * - orderDetails: detalhes do pedido obtidos do servidor
 * - loading: indica se os detalhes do pedido estão sendo carregados
 * - error: armazena qualquer erro que ocorra durante o carregamento dos detalhes do pedido
 */
const OrderStatus: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState<Order | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!orderId) {
            setError('ID do pedido não encontrado');
            setLoading(false);
            return;
        }

        const fetchDetails = async () => {
            try {
                setLoading(true);
                const order = await getOrderDetails(orderId);
                setOrderDetails(order);
            } catch (err) {
                setError('Não foi possível carregar os detalhes do pedido. Por favor, tente novamente.');
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
    }, [orderId]);

    const renderOrderStatus = (): React.ReactNode => {
        if (loading) return <LoadingSpinner />;
        if (error) return <ErrorDisplay error={new Error(error)} />;
        if (!orderDetails) return <ErrorDisplay error={new Error('Detalhes do pedido não encontrados')} />;

        switch (orderDetails.paymentStatus) {
            case PaymentStatus.Completed:
                return <OrderConfirmation orderDetails={orderDetails} />;
            case PaymentStatus.Pending:
            case PaymentStatus.Processing:
                return <OrderPending orderDetails={orderDetails} />;
            case PaymentStatus.WaitingConfirmation:
                if (orderDetails.paymentMethod === EPaymentMethod.Boleto && orderDetails.boletoInfo) {
                    return <OrderBoletoPending orderDetails={orderDetails} />;
                }else if(orderDetails.paymentClientSecret)
                {
                    
                }
                return <OrderPending orderDetails={orderDetails} />;
            default:
                return <OrderRejection orderDetails={orderDetails} />;
        }
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ p: 4, mt: 4 }}>
                <Box mb={4}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Status do Pedido
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        Número do Pedido: {orderId}
                    </Typography>
                </Box>
                {renderOrderStatus()}
            </Box>
        </Container>
    );
};

export default OrderStatus;