import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DevicesIcon from '@mui/icons-material/Devices';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DescriptionIcon from '@mui/icons-material/Description';
import QuizIcon from '@mui/icons-material/Quiz';

interface CourseIncludesProps {
  lessonCount: number;
  totalDuration: string;
}

const CourseIncludes: React.FC<CourseIncludesProps> = ({ lessonCount, totalDuration }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>Este curso inclui:</Typography>
      <List dense={isMobile}>
        <ListItem>
          <ListItemIcon><OndemandVideoIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
          <ListItemText primary={`${lessonCount || 0} aulas gravadas`} primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
        </ListItem>
        <ListItem>
          <ListItemIcon><AccessTimeIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
          <ListItemText primary={`${totalDuration || 'Duração não especificada'} de conteúdo`} primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
        </ListItem>
        <ListItem>
          <ListItemIcon><DevicesIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
          <ListItemText primary="Acesso em computador, TV, celular e tablet" primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
        </ListItem>
        <ListItem>
          <ListItemIcon><EmojiEventsIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
          <ListItemText primary="Certificado de conclusão" primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
        </ListItem>
        <ListItem>
          <ListItemIcon><DescriptionIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
          <ListItemText primary="Anotações do instrutor com exemplos" primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
        </ListItem>
        <ListItem>
          <ListItemIcon><QuizIcon fontSize={isMobile ? "small" : "medium"} /></ListItemIcon>
          <ListItemText primary="Testes de conhecimento (não reprováveis)" primaryTypographyProps={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
        </ListItem>
      </List>
    </>
  );
};

export default CourseIncludes;